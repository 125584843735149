import { FC } from "react"

const Footer: FC = () => {
  return (
    <div className="w-full p-2 mt-16 bg-header dark:bg-dark-header text-sm">
      <span>© All rights reserved.</span>
    </div>
  )
}

export default Footer
