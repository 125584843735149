import { BaseSyntheticEvent, FC, useState } from 'react'
import { JournalEntry } from '../../types'
import { HiMiniNoSymbol, HiOutlineCheckCircle } from 'react-icons/hi2'
import TextareaAutosize from 'react-textarea-autosize'
import { setJournalInputFocus } from '../../store/appSlice'
import { useAppSelector } from '../../store/hooks'
import store from '../../store'

interface Props {
  entry: JournalEntry
  id: string
  onSave: (title:string, note:string, id:string) => void
}

const NewEntry: FC<Props> = ({ entry, id, onSave }) => {
  const [newEntryNote, setNewEntryNote] = useState('')
  const [editing, setEditing] = useState(false)
  const focus = useAppSelector(state => state.appStore.journalInputFocus)

  const onChangeNew = (e: BaseSyntheticEvent) => {
    // console.log('onChangeNew e: ', e)
    if(e.target.value === '\n') {
      // handle odd case where hitting enter key to save
      // causes a delayed "onChange" that sets this to "\n" after saving
      setNewEntryNote(prev => {
        if (prev === '') {
          return ''
        } else {
          return prev + e.target.value
        }
      })
    } else {
      setNewEntryNote(e.target.value)
    }
  }

  const setFocus = (id:string) => {
    store.dispatch(setJournalInputFocus(id))
    setEditing(true)
  }

  const onCancel = () => {
    setEditing(false)
    setNewEntryNote('')
    store.dispatch(setJournalInputFocus(''))
    onSave(entry.title, '', '')
  }

  const onKeyDownEvent = (e: BaseSyntheticEvent | any) => {
    // console.log('onKeyDownEvent e', e)
    if (e.shiftKey && e.key === 'Enter') {
      // shift+enter already creates newline
      // do something else?
      // setNewEntryNote(prev => prev)
    } else if (!e.shiftKey && e.key === 'Enter') {
      onSave(entry.title, newEntryNote, id)
      setNewEntryNote('')
    } else if (e.key === 'Escape') {
      onCancel()
    }
  }

  const onClickSave = (title:string, id:string) => {
    onSave(title, newEntryNote, id)
    setEditing(false)
  }

  return (
    <div className='ml-1 mr-1 flex flex-inline items-justify'>
      <div className='w-full flex flex-inline'>
        <TextareaAutosize
          id={id}
          title={entry.title}
          className='box-border journal-input-full-wide border dark:border-0 leading-normal'
          value={newEntryNote}
          onChange={(e:BaseSyntheticEvent) => onChangeNew(e)}
          onKeyDown={(e:BaseSyntheticEvent) => onKeyDownEvent(e)}
          // onBlur={() => onBlur(entry.title, newEntryNote, id)}
          onFocus={()=> setFocus(id)}
          autoFocus={id === focus}
          minRows={3}
        />

        {editing && (
          <div className='flex flex-col items-justify'>
            <HiOutlineCheckCircle
              onClick={() => onClickSave(entry.title, id)}
              className='action-icons check-circle-sz text-success mb-2'
            />
            <HiMiniNoSymbol
              onClick={() => onCancel()}
              className='action-icons text-warn mb-2'
            />
          </div>
        )}

      </div>
    </div>
  )
}

export default NewEntry