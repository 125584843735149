import { FC, useState } from 'react'
import { isAfter, set } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { handleError } from '../../utilities/error-handling'
import { getSpyVolAtTen } from '../../services/api/market-data-stocks'
import ReactTooltip from 'react-tooltip'
import { toLocalDecimals } from '../../utilities/general'

const VOL_AT_TEN = 10000000

const TenByTenRule: FC = () => {
  const [tenTextClass, setTenTextClass] = useState('text-grey')
  const [spyVolAtTen, setSpyVolAtTen] = useState(-1)

  const checkRule = async () => {
    if (isAfterTenInNy()) {
      try {
        const volAtTen = await getSpyVolAtTen()
        setSpyVolAtTen(volAtTen)
        if (volAtTen > VOL_AT_TEN) {
          // TODO set to redux
          setTenTextClass('text-success')
        } else {
          setTenTextClass('text-danger')
        }
      } catch (e) {
        handleError({ msg: 'Could not get SPY volume.', e })
      }
    }
  }

  const isAfterTenInNy = () => {
    const now = new Date()
    const nyTimeZone = 'America/New_York'
    const nowInNy = utcToZonedTime(now, nyTimeZone)
    const tenAMInNy = set(nowInNy, { hours: 9, minutes: 59, seconds: 59, milliseconds: 0 })
    return isAfter(nowInNy, tenAMInNy)
  }

  return (
    <div
      data-tip data-for='tooltip-spy-vol'
      className={`pl-4 font-light text-sm cursor-pointer ${tenTextClass}`}
      onClick={checkRule}
    >
      10x10
      <ReactTooltip
        id='tooltip-spy-vol'
        place='top'
        effect='solid'
        className='opacity-full'
      >
        <div className='text-lg text-center px-4 py-3'>
          <div>
            10AM SPY Volume
          </div>
          <div className={tenTextClass}>
            {spyVolAtTen > 0 ? toLocalDecimals(spyVolAtTen) : 'Click "10x10" after 10AM New York time.'}
          </div>
        </div>
      </ReactTooltip>

    </div>
  )
}

export default TenByTenRule
