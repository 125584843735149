import { FC, useState } from 'react'
import Journal from '../components/roostrat-journal/Journal'
import NavButton from '../components/pages-components/NavButton'
import OI from '../components/options-open-interest/Oi'
import Tasty from '../components/tasty/Index'
import Tracker from '../components/roostrat-tracker/Tracker'
// import Toi from '../components/options-open-interest/Index'

const Body: FC = () => {
  // const [view, setView] = useState('Tasty')
  const [view, setView] = useState('Tracker')

  return (
    <div className='mt-5'>

      <div className='flex flex-row justify-center space-x-10 pb-6'>
        <NavButton view={view} setView={setView} title={'Tasty'} />
        <NavButton view={view} setView={setView} title={'Tracker'} />
        <NavButton view={view} setView={setView} title={'Journal'} />
        <NavButton view={view} setView={setView} title={'OI'} />
        {/* <NavButton view={view} setView={setView} title={'T-OI'} /> */}
      </div>

      {view && view === 'Tasty'   && <Tasty /> }
      {view && view === 'Tracker' && <Tracker /> }
      {view && view === 'Journal' && <Journal /> }
      {view && view === 'OI'      && <OI /> }
      {/* {view && view === 'T-OI'    && <Toi /> } */}

      {!view &&
        <p>Sorry, we can't find this page.</p>
      }

    </div>
  )
}

export default Body
