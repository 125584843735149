import { getUnixTime, set } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { toast } from 'react-hot-toast'
import { handleError } from '../../utilities/error-handling'

const API_RT_BASE_URL = 'https://api.marketdata.app/v1/stocks/quotes'
const API_HX_BASE_URL = 'https://api.marketdata.app/v1/stocks/candles'
const TOKEN = process.env.REACT_APP_MARKETDATA_TOKEN

export const getStockQuote = async (ticker: string) => {

  if (!ticker) return null

  const toastId = toast.loading('Getting stock quote...')

  try {
    const controller = new AbortController()

    const response = await fetch(`${API_RT_BASE_URL}/${ticker}`, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        Authorization: `Token ${TOKEN}`
      },
      signal: controller.signal
    })

    if (!response.ok) {
      handleError({ msg: `Failed to get stock quote for ${ticker}. Error: !response.ok` })
    }

    const quote = await response.json()
    return quote

  } catch (e) {
    handleError({ msg: `Failed to get stock quote for ${ticker}.`, e })
    return null

  } finally {
    toast.dismiss(toastId)
  }
}

export const getSpyVolAtTen = async () => {
  const toastId = toast.loading('Getting stock quote...')

  try {
    const resolution = 30
    const controller = new AbortController()

    const now = new Date()
    const nyTimeZone = 'America/New_York'
    const nowInNy = utcToZonedTime(now, nyTimeZone)
    const sixThirtyAMInNyUnix = getUnixTime(set(nowInNy, { hours: 6, minutes: 30, seconds: 0, milliseconds: 0 }))
    const sevenAMInNyUnix = getUnixTime(set(nowInNy, { hours: 7, minutes: 0, seconds: 0, milliseconds: 0 }))

    const options = `from=${sixThirtyAMInNyUnix}&to=${sevenAMInNyUnix}`

    const response = await fetch(`${API_HX_BASE_URL}/${resolution}/SPY?${options}`, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        Authorization: `Token ${TOKEN}`
      },
      signal: controller.signal
    })

    if (!response.ok) {
      handleError({ msg: 'Failed to get stock volume for SPY. Error: !response.ok' })
    }

    const data = await response.json()
    const volAtTen = data.v[0]

    if (volAtTen > 0) {
      return volAtTen
    }
    return null

  } catch (e) {
    handleError({ msg: 'Failed to get stock volume for SPY.', e })
    return null

  } finally {
    toast.dismiss(toastId)
  }
}