import { forEach, isEmpty } from 'lodash'
import { compressSpreads } from './tasty'

export const spreadSummary = (spread, spreadQuotesGreeks) => {
  var spreadSum = {}
  if (!spread || !spreadQuotesGreeks || isEmpty(spread) || isEmpty(spreadQuotesGreeks)) {
    return spreadSum
  }

  const id = spread.fbSpreadQuotesGreeksId
  if (!id) {
    return spreadSum
  }

  const isHedge = spread.isHedge
  const spreadType = isHedge ? 'debit' : 'credit'
  const sLeg = spread.shortLeg
  const lLeg = spread.longLeg
  const posContracts = sLeg.contracts ? Math.abs(sLeg.contracts) : undefined
  const dte = sLeg.dte

  const sLprice = sLeg.price
  const lLprice = lLeg.price
  const sLstrike = sLeg.strike
  const lLstrike = lLeg.strike
  const sLmark = spreadQuotesGreeks[id].sLegMark
  const lLmark = spreadQuotesGreeks[id].lLegMark
  const sLdelta = spreadQuotesGreeks[id].sLegDelta
  const lLdelta = spreadQuotesGreeks[id].lLegDelta
  const sLtheta = spreadQuotesGreeks[id].sLegTheta
  const lLtheta = spreadQuotesGreeks[id].lLegTheta
  const sLdeltaCalculated = spreadQuotesGreeks[id].sLegDeltaCalculated
  const lLdeltaCalculated = spreadQuotesGreeks[id].lLegDeltaCalculated
  const sLthetaCalculated = spreadQuotesGreeks[id].sLegThetaCalculated
  const lLthetaCalculated = spreadQuotesGreeks[id].lLegThetaCalculated

  // BOTH
  //   description
  //   spread = Abs(sleg strike - lleg strike)
  //   premium = sleg price +lleg price
  //   mark = sleg mark - lleg mark
  if (spread.description) {
    spreadSum.description = spread.description
  }
  if (sLstrike && lLstrike) {
    spreadSum.spread = Math.abs(sLstrike - lLstrike)
  }
  if (sLprice && lLprice) {
    spreadSum.premium = sLprice + lLprice
  }
  if (sLmark && lLmark) {
    spreadSum.mark = sLmark + lLmark
  }

  // Credit Spreads
  //   posPremium = premium * contracts * 100
  //   bp = (spread - premium) * contracts * 100
  //   p/l = (premium - mark) * contracts * 100
  if (spreadType === 'credit') {
    if (spreadSum.premium && posContracts) {
      spreadSum.posPremium = spreadSum.premium * posContracts * 100
    }
    if (spreadSum.spread && spreadSum.premium && posContracts) {
      spreadSum.bp = (spreadSum.spread - spreadSum.premium) * posContracts * 100
    }
    if (spreadSum.premium && spreadSum.mark && posContracts) {
      spreadSum.pl = (spreadSum.premium - spreadSum.mark) * posContracts * 100
      if (spreadSum.premium === spreadSum.mark) {
        spreadSum.pl = 0
      }
    }
  }

  // Debit Spreads
  //   posPremium = (spread - premium) * contracts * 100
  //   bp = premium * contracts * 100
  //   p/l = (mark - premium) * contracts * 100
  else if (spreadType === 'debit') {
    if (spreadSum.spread && spreadSum.premium && posContracts) {
      spreadSum.posPremium = (spreadSum.spread - spreadSum.premium) * posContracts * 100
    }
    if (spreadSum.premium && posContracts) {
      spreadSum.bp = spreadSum.premium * posContracts * 100
    }
    if (spreadSum.premium && spreadSum.mark && posContracts) {
      spreadSum.pl = (spreadSum.mark - spreadSum.premium) * posContracts * 100
      if (spreadSum.premium === spreadSum.mark) {
        spreadSum.pl = 0
      }
    }
  }

  // BOTH - pl%
  //   +pl on Pmax = (pl / posPremium ) * 100
  //   -pl on BP   = (pl / bp ) * 100
  if (spreadSum.mark && spreadSum.premium) {
    spreadSum.plPercent = spreadSum.pl > 0 ?
      (spreadSum.pl / spreadSum.posPremium) * 100 :
      (spreadSum.pl / spreadSum.bp) * 100
    if (spreadSum.premium === spreadSum.mark) {
      spreadSum.plPercent = 0
    }
  }

  // Delta and Theta
  if (sLdelta && lLdelta && posContracts) {
    spreadSum.delta = Math.abs((sLdelta + lLdelta) * posContracts)
    if (!spread.isBullish) {
      spreadSum.delta = -1 * spreadSum.delta
    }
  }
  if (sLtheta && lLtheta && posContracts) {
    spreadSum.theta = Math.abs(sLtheta + lLtheta) * posContracts
    if (isHedge) {
      spreadSum.theta = -1 * spreadSum.theta
    }
  }
  if (sLdeltaCalculated && lLdeltaCalculated && posContracts) {
    spreadSum.deltaCalculated = Math.abs((sLdeltaCalculated + lLdeltaCalculated) * posContracts)
    if (!spread.isBullish) {
      spreadSum.deltaCalculated = -1 * spreadSum.deltaCalculated
    }
  }
  if (sLthetaCalculated && lLthetaCalculated && posContracts) {
    spreadSum.thetaCalculated = Math.abs(sLthetaCalculated + lLthetaCalculated) * posContracts
    if (isHedge) {
      spreadSum.thetaCalculated = -1 * spreadSum.thetaCalculated
    }
  }

  // shortLegDelta and DTE
  if (sLdelta) {
    spreadSum.shortLegDelta = sLdelta
  }
  if (sLdeltaCalculated) {
    spreadSum.shortLegDeltaCalculated = sLdeltaCalculated
  }
  if (dte) {
    spreadSum.dte = dte
  }

  return spreadSum
}

export const tickerGroupSummary = (group, spreadQuotesGreeks, extraProps) => {
  const spreads = group.spreads
  var totPl = -999999
  var totPremium = -999999
  var totDelta = -999999
  var totTheta = -999999
  var totDeltaCalculated = -999999
  var totThetaCalculated = -999999
  var totBp = -999999
  var tickerSum = {}

  forEach(spreads, pos => {
    let posToSummarize
    if (pos.length > 0) {
      posToSummarize = pos.length === 1 ? pos[0] : compressSpreads(pos)[0]
    }

    const id = posToSummarize.fbSpreadQuotesGreeksId
    const ignore = (extraProps && extraProps[id]) ? extraProps[id].ignore : false

    if (posToSummarize && !ignore) {
      const spreadSumm = spreadSummary(posToSummarize, spreadQuotesGreeks)

      if (spreadSumm.pl) {
        if (totPl === -999999) totPl = 0
        totPl += spreadSumm.pl
      }
      if (spreadSumm.premium) {
        if (totPremium === -999999) totPremium = 0
        totPremium += spreadSumm.posPremium
      }
      if (spreadSumm.delta) {
        if (totDelta === -999999) totDelta = 0
        totDelta += spreadSumm.delta
      }
      if (spreadSumm.theta) {
        if (totTheta === -999999) totTheta = 0
        totTheta += spreadSumm.theta
      }
      if (spreadSumm.deltaCalculated) {
        if (totDeltaCalculated === -999999) totDeltaCalculated = 0
        totDeltaCalculated += spreadSumm.deltaCalculated
      }
      if (spreadSumm.thetaCalculated) {
        if (totThetaCalculated === -999999) totThetaCalculated = 0
        totThetaCalculated += spreadSumm.thetaCalculated
      }
      if (spreadSumm.bp) {
        if (totBp === -999999) totBp = 0
        totBp += spreadSumm.bp
      }
    }
  })

  tickerSum.ticker = group.ticker
  if (totPremium > -999999) tickerSum.premium = totPremium
  if (totPl > -999999)      tickerSum.pl = totPl
  if (totDelta > -999999)   tickerSum.delta = totDelta
  if (totTheta > -999999)   tickerSum.theta = totTheta
  if (totDeltaCalculated > -999999)   tickerSum.deltaCalculated = totDeltaCalculated
  if (totThetaCalculated > -999999)   tickerSum.thetaCalculated = totThetaCalculated
  if (totBp > -999999)      tickerSum.bp = totBp
  if (totPl > -999999 && totPremium > -999999) tickerSum.plPercent = (totPl / totPremium) * 100
  if (totDelta > -999999 && totBp > -999999)   tickerSum.deltaBalPercent = (totDelta / totBp) * 100
  if (totTheta > -999999 && totBp > -999999)   tickerSum.thetaBalPercent = (totTheta / totBp) * 100
  if (totDeltaCalculated > -999999 && totBp > -999999) tickerSum.deltaCalculatedBalPercent = (totDeltaCalculated / totBp) * 100
  if (totThetaCalculated > -999999 && totBp > -999999) tickerSum.thetaCalculatedBalPercent = (totThetaCalculated / totBp) * 100

  return tickerSum
}

export const portfolioSummary = (tickerGroups, spreadQuotesGreeks, extraProps) => {
  var totPl = -999999
  var totPremium = -999999
  var totDelta = -999999
  var totTheta = -999999
  var totDeltaCalculated = -999999
  var totThetaCalculated = -999999
  var totBp = -999999
  var summary = {}

  forEach(tickerGroups, group => {
    const groupSumm = tickerGroupSummary(
      { spreads: group.spreads, butterflies: group.butterflies },
      spreadQuotesGreeks,
      extraProps
    )

    if (groupSumm.pl) {
      if (totPl === -999999) totPl = 0
      totPl += groupSumm.pl
    }
    if (groupSumm.premium) {
      if (totPremium === -999999) totPremium = 0
      totPremium += groupSumm.premium
    }
    if (groupSumm.delta) {
      if (totDelta === -999999) totDelta = 0
      totDelta += groupSumm.delta
    }
    if (groupSumm.theta) {
      if (totTheta === -999999) totTheta = 0
      totTheta += groupSumm.theta
    }
    if (groupSumm.deltaCalculated) {
      if (totDeltaCalculated === -999999) totDeltaCalculated = 0
      totDeltaCalculated += groupSumm.deltaCalculated
    }
    if (groupSumm.thetaCalculated) {
      if (totThetaCalculated === -999999) totThetaCalculated = 0
      totThetaCalculated += groupSumm.thetaCalculated
    }
    if (groupSumm.bp) {
      if (totBp === -999999) totBp = 0
      totBp += groupSumm.bp
    }
  })

  if (totPremium > -999999) summary.premium = totPremium
  if (totPl > -999999)      summary.pl = totPl
  if (totDelta > -999999)   summary.delta = totDelta
  if (totTheta > -999999)   summary.theta = totTheta
  if (totDeltaCalculated > -999999)   summary.deltaCalculated = totDeltaCalculated
  if (totThetaCalculated > -999999)   summary.thetaCalculated = totThetaCalculated
  if (totBp > -999999)      summary.bp = totBp
  if (totPl > -999999 && totPremium > -999999) summary.plPercent = (totPl / totPremium) * 100
  if (totDelta > -999999 && totBp > -999999)   summary.deltaBalPercent = (totDelta / totBp) * 100
  if (totTheta > -999999 && totBp > -999999)   summary.thetaBalPercent = (totTheta / totBp) * 100
  if (totDeltaCalculated > -999999 && totBp > -999999) summary.deltaCalculatedBalPercent = (totDeltaCalculated / totBp) * 100
  if (totThetaCalculated > -999999 && totBp > -999999) summary.thetaCalculatedBalPercent = (totThetaCalculated / totBp) * 100

  return summary
}
