import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { App, GlobalValues, TenYearRate } from '../types/index'

const initialState = {
  // view: 'tracker',
  globalValues: {
    tenYearRate: {
      updated: '',
      rateDate: '',
      value: 0,
    } as TenYearRate,
    tickerPrices: {} as {[key: string]: number},
  } as GlobalValues,
  journalInputFocus: '',
} as App

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {

    setJournalInputFocus: (state, action: PayloadAction<string>) => {
      state.journalInputFocus = action.payload
    },

    setGlobalValues: (state, action: PayloadAction<GlobalValues>) => {
      state.globalValues = action.payload
    },

    setTenYearRate: (state, action: PayloadAction<TenYearRate>) => {
      state.globalValues.tenYearRate = action.payload
    },

    setTickerPrices: (state, action: PayloadAction<{[key: string]: number}>) => {
      const newTickerPrices = { ...state.globalValues.tickerPrices, ...action.payload }
      state.globalValues.tickerPrices = newTickerPrices
    },

  },
})

export const {
  setJournalInputFocus,
  setGlobalValues,
  setTenYearRate,
  setTickerPrices,
} = appSlice.actions

export default appSlice.reducer
