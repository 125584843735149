import { toNumber, includes, capitalize } from 'lodash'
import { isValid, parseISO, format, differenceInDays } from 'date-fns'
import { toLocalDecimals } from './general'


export const validateOccInput = ({ ticker, input }) => {
  // input format options: (1) "yymmddxsss.ss" - x=c|p, sss=strike (2) "16 Feb 24 459 Call"
  // Returns: { isValid, occSymbol, occDisplay, err }

  var finalAnswer = {
    isValid: false,
    occSymbol: '',
    occDisplay: '',
    err: ''
  }

  if (!ticker || !input) {
    return finalAnswer
  }

  var year, yearFull, month, day, side, strike

  // Which type of input? and assign vars
  if (includes(input, ' ')) {

    const inputSplit = input.trim().split(' ')

    if (inputSplit.length < 5) {
      finalAnswer.err = 'Too short.'
      return finalAnswer
    } else
    if (inputSplit.length > 5) {
      finalAnswer.err = 'Too long.'
      return finalAnswer
    } else {

      const monthAbbr = {
        jan: '01', feb: '02', mar: '03', apr: '04', may: '05', jun: '06',
        jul: '07', aug: '08', sep: '09', oct: '10', nov: '11', dec: '12'
      }
      year = inputSplit[2]
      day = inputSplit[0].padStart(2, '0')
      month = monthAbbr[inputSplit[1].toLowerCase()]
      side = inputSplit[4][0]
      strike = inputSplit[3]

    }

  } else {

    if (input.length < 8) {
      finalAnswer.err = 'Too short.'
      return finalAnswer
    } else {
      year = input.substring(0, 2)
      month = input.substring(2, 4)
      day = input.substring(4, 6)
      side = input.substring(6, 7)
      strike = input.substring(7)
    }

  }

  // Eval (errs) & format answers
  // Date
  yearFull = `20${year}`
  const dateOcc = `${year}${month}${day}`
  const isDateValid = isValid(parseISO(`${yearFull}-${month}-${day}`))
  if (!isDateValid || dateOcc.length !== 6) {
    finalAnswer.err= 'Date not valid.'
    return finalAnswer
  }

  // Side
  side = side.toUpperCase()
  if (side !== 'C' && side !== 'P') {
    finalAnswer.err = 'Side not "C/Call" or "P/Put".'
    return finalAnswer
  }

  // Strike
  const partialStrike = parseFloat(strike).toFixed(3)
  const strikeFormatted = partialStrike.replace('.', '').padStart(8, '0')

  const strikeInt = strikeFormatted.substring(0, 5)
  const strikeDec = toNumber(strikeFormatted.substring(5))
  const strikeNum = parseFloat(strikeInt + '.' + strikeDec)
  const strikeDisplayFormatted = strikeDec ? toLocalDecimals(strikeNum, 2) : strikeNum

  if (strikeFormatted.length !== 8) {
    finalAnswer.err = 'Strike not valid.'
    return finalAnswer
  }

  // occSymbol
  const occPostfix = `${dateOcc}${side}${strikeFormatted}`
  if (occPostfix.length !== 15) {
    finalAnswer.err = 'Occ string not 15 characters long.'
    return finalAnswer
  }
  const occSymbol = `${ticker}${occPostfix}`

  // occDisplay
  const dateFormatted = format(parseISO(`${yearFull}-${month}-${day}`), 'dd MMM yy')
  const sideFormatted = side === 'P' ? 'Put' : 'Call'
  const occDisplay = `${dateFormatted} ${strikeDisplayFormatted} ${sideFormatted}`

  // Build finalAnswer
  finalAnswer.occSymbol = occSymbol
  finalAnswer.occDisplay = occDisplay
  finalAnswer.isValid = true

  return finalAnswer
}

export const parseTastyOcc = ({tastyOccSymbol}) => {
  // tastyOccSymbol format from Tasty - "SPY   240301C00496000"
  var finalAnswer = {
    side: '',
    strike: 0,
    dte: 0,
    occMarketData: '',
    occDisplay: '',
    occExpDate: ''
  }

  if (!tastyOccSymbol) {
    return finalAnswer
  }

  var ticker, year, yearFull, month, day, dateObj, dte, side, strike, occ

  // Assign vars
  ticker = tastyOccSymbol.substring(0, 6).trim()
  occ    = tastyOccSymbol.substring(tastyOccSymbol.length - 15)
  year   = occ.substring(0, 2)
  month  = occ.substring(2, 4)
  day    = occ.substring(4, 6)
  side   = occ.substring(6, 7)
  strike = occ.substring(7)

  // Format answers
  // Date
  yearFull = `20${year}`
  dateObj = parseISO(`${yearFull}-${month}-${day}`)
  dte = differenceInDays(dateObj, new Date())

  // Side
  const sideFull = side === 'P' ? 'put' : 'call'

  // Strike
  const strikeInt = strike.substring(0, 5)
  const strikeDec = strike.substring(5)
  const strikeNum = parseFloat(`${strikeInt}.${strikeDec}`)
  const strikeDisplayFormatted = strikeDec > 0 ? toNumber(toLocalDecimals(strikeNum, 2)) : strikeNum

  // occs
  const occMarketData = `${ticker}${occ}`

  // occDisplay
  const dateFormatted = format(dateObj, 'MMM d')
  const occDisplay = `${dateFormatted} (${dte}) ${strikeDisplayFormatted} ${capitalize(sideFull)}`

  // Build finalAnswer
  finalAnswer.side = sideFull
  finalAnswer.strike = strikeDisplayFormatted
  finalAnswer.dte = dte
  finalAnswer.occMarketData = occMarketData
  finalAnswer.occDisplay = occDisplay
  finalAnswer.occExpDate = dateFormatted

  return finalAnswer
}
