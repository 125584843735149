import { FC } from 'react'

interface Props {
  id?: string
  heightPx: number
}

const RowSpacer: FC<Props> = ({ id, heightPx }) => {
  return (
    <tr>
      <td className='row-spacer' id={id} height={heightPx}></td>
    </tr>
  )
}

export default RowSpacer
