import { User, UserSettings, IJournal } from './../types/index'
import { ExtraProps, Note as TastyNote, NoteGroup as TastyNoteGroup, SpreadQuotesGreeksId, Tasty, TastyLogin, TastySettings, TastyTickerGroups } from './../types/tasty'
import { TickerGroup, RooStratPortfolio, RooStratSettings } from './../types/rooStrat'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { JournalClass } from '../Classes/JournalClass'
import { format } from 'date-fns'
import { cloneDeep } from 'lodash'

const initialState = {
  user: {
    settings: {
      isDark: false,
    } as UserSettings,
    tasty: {
      settings: {
        accountEquity: 0,
        maxPositionSize: 0,
        deltaBalanceLow: 0,
        deltaBalanceHigh: 0,
        thetaBalanceLow: 0,
        thetaBalanceHigh: 0,
      } as TastySettings,
      login: {
        isLoggedIn: false,
        sessionToken: '',
        lastLoginTime: 0,
        accountNumbers: [],
        userExternalId: '',
      } as TastyLogin,
      positions: [],
      fbTickerGroupsNotes: {} as TastyTickerGroups,
      plTickerGroups: {},
      spreadQuotesGreeks: {},
    } as Tasty,
    rooStratPortfolio: {
      tickerGroups: [] as TickerGroup[],
      settings: {} as RooStratSettings,
    } as RooStratPortfolio,
    rooStratJournal: new JournalClass().defaultJournal(format(new Date(), 'MM-dd-yy')) as IJournal,
  } as User
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {

    dbSettingsToStore: (state, action: PayloadAction<UserSettings>) => {
      state.user.settings = action.payload
    },

    updateIsDark: (state, action: PayloadAction<UserSettings>) => {
      state.user.settings.isDark = action.payload.isDark
    },

    setTasty: (state, action: PayloadAction<Tasty>) => {
      state.user.tasty = action.payload
    },

    resetTasty: (state) => {
      state.user.tasty.login = {
        isLoggedIn: false,
        sessionToken: '',
        lastLoginTime: 0,
        accountNumbers: [],
      }
    },

    setTickerRooStratPortfolio: (state, action: PayloadAction<RooStratPortfolio>) => {
      state.user.rooStratPortfolio = action.payload
    },

    setJournal: (state, action: PayloadAction<IJournal>) => {
      state.user.journal = action.payload
    },

    updateTastyLogin: (state, action: PayloadAction<TastyLogin>) => {
      const update = { ...state.user.tasty.login, ...action.payload }
      state.user.tasty.login = update
    },

    updateTastyPositions: (state, action: PayloadAction<any>) => {
      const update = { ...state.user.tasty.positions, ...action.payload }
      state.user.tasty.positions = update
    },

    updateTastyOrders: (state, action: PayloadAction<any>) => {
      const update = { ...state.user.tasty.orders, ...action.payload }
      state.user.tasty.orders = update
    },

    updateTastyAccountBalances: (state, action: PayloadAction<any>) => {
      const update = { ...state.user.tasty.accountBalances, ...action.payload }
      state.user.tasty.accountBalances = update
    },

    updateTastyPlTickerGroups: (state, action: PayloadAction<any>) => {
      const update = { ...state.user.tasty.plTickerGroups, ...action.payload }
      state.user.tasty.plTickerGroups = update
    },

    setTastyFbTickerNotesGroups: (state, action: PayloadAction<{ ticker: string, noteGroups: TastyNoteGroup[] }>) => {
      const { ticker, noteGroups } = action.payload
      if (!state.user.tasty.fbTickerGroupsNotes || !state.user.tasty.fbTickerGroupsNotes[ticker]) {
        // state.user.tasty.fbTickerGroupsNotes = { [ticker]: { ticker: ticker, noteGroups: noteGroups } }
        const update = { [ticker]: { noteGroups: noteGroups } }
        state.user.tasty.fbTickerGroupsNotes = { ...state.user.tasty.fbTickerGroupsNotes, ...update }
        return
      }
      state.user.tasty.fbTickerGroupsNotes[ticker].noteGroups = noteGroups
    },

    setTastyFbTickerNotesGroup: (state, action: PayloadAction<{ ticker: string, noteGroupKey: number, noteGroup: TastyNoteGroup }>) => {
      const { ticker, noteGroupKey, noteGroup } = action.payload

      if (!state.user.tasty.fbTickerGroupsNotes || !state.user.tasty.fbTickerGroupsNotes[ticker]) {
        state.user.tasty.fbTickerGroupsNotes = { [ticker]: { ticker: ticker, noteGroups: [noteGroup] } }
      } else

      if (!state.user.tasty.fbTickerGroupsNotes[ticker].noteGroups) {
        state.user.tasty.fbTickerGroupsNotes[ticker].noteGroups = [noteGroup]
      } else {

        const stateTickerNoteGroups = cloneDeep(state.user.tasty.fbTickerGroupsNotes[ticker].noteGroups)
        if (stateTickerNoteGroups) {
          stateTickerNoteGroups[noteGroupKey] = noteGroup
          state.user.tasty.fbTickerGroupsNotes[ticker].noteGroups = stateTickerNoteGroups
        }
      }
    },

    setNoteFavorite: (state, action: PayloadAction<{ ticker: string, noteGroupKey: number, noteKey: number, newValue: boolean }>) => {
      const { ticker, noteGroupKey, noteKey, newValue } = action.payload
      // @ts-ignore
      state.user.tasty.fbTickerGroupsNotes[ticker].noteGroups[noteGroupKey].notes[noteKey].isFavorite = newValue
    },

    updateNote: (state, action: PayloadAction<{ ticker: string, noteGroupKey: number, noteKey: number, editedNote: TastyNote }>) => {
      const { ticker, noteGroupKey, noteKey, editedNote } = action.payload
      // @ts-ignore
      const update = { ...state.user.tasty.fbTickerGroupsNotes[ticker].noteGroups[noteGroupKey].notes[noteKey], ...editedNote }
      // @ts-ignore
      state.user.tasty.fbTickerGroupsNotes[ticker].noteGroups[noteGroupKey].notes[noteKey] = update
    },

    removeNote: (state, action: PayloadAction<{ ticker: string, noteGroupKey: number, noteKey: number }>) => {
      const { ticker, noteGroupKey, noteKey } = action.payload
      // @ts-ignore
      const update = state.user.tasty.fbTickerGroupsNotes[ticker].noteGroups[noteGroupKey].notes.filter((n, index) => index !== noteKey)
      // @ts-ignore
      state.user.tasty.fbTickerGroupsNotes[ticker].noteGroups[noteGroupKey].notes = update
    },

    removeEntireDaysNotes: (state, action: PayloadAction<{ ticker: string, noteGroupKey: number }>) => {
      const { ticker, noteGroupKey } = action.payload
      // @ts-ignore
      const update = state.user.tasty.fbTickerGroupsNotes[ticker].noteGroups.filter((ng, index) => index !== noteGroupKey)
      // @ts-ignore
      state.user.tasty.fbTickerGroupsNotes[ticker].noteGroups = update
    },

    updateTastySpreadQuotesGreeks: (state, action: PayloadAction<any>) => {
      const update = { ...state.user.tasty.spreadQuotesGreeks, ...action.payload }
      state.user.tasty.spreadQuotesGreeks = update
    },

    updateTastySpreadQuotesGreek: (state, action: PayloadAction<{ id: string, update: SpreadQuotesGreeksId }>) => {
      const { id, update } = action.payload
      // @ts-ignore
      const newUpdate = { ...state.user.tasty.spreadQuotesGreeks[id], ...update }
      // @ts-ignore
      state.user.tasty.spreadQuotesGreeks[id] = newUpdate
    },

    updateTastyExtraPropsProp: (state, action: PayloadAction<{ id: string, update: ExtraProps }>) => {
      const { id, update } = action.payload
      // console.log('update: ', update)
      // @ts-ignore
      const newUpdate = { ...state.user.tasty.extraProps[id], ...update }
      // console.log('newUpdate: ', newUpdate)
      // @ts-ignore
      state.user.tasty.extraProps[id] = newUpdate
    },

    setTastySettings: (state, action: PayloadAction<TastySettings>) => {
      console.log('action.payload: ', action.payload)
      state.user.tasty.settings = action.payload
    },

  },
})

export const {
  dbSettingsToStore,
  updateIsDark,

  setTasty,
  resetTasty,

  setTickerRooStratPortfolio,
  setJournal,

  updateTastyLogin,
  updateTastyPositions,
  updateTastyOrders,
  updateTastyAccountBalances,

  updateTastyPlTickerGroups,
  setTastyFbTickerNotesGroups,
  setTastyFbTickerNotesGroup,

  setNoteFavorite,
  updateNote,
  removeNote,
  removeEntireDaysNotes,
  updateTastySpreadQuotesGreeks,
  updateTastySpreadQuotesGreek,
  updateTastyExtraPropsProp,

  setTastySettings,

} = userSlice.actions

export default userSlice.reducer
