import { BaseSyntheticEvent, FC, useEffect, useState } from 'react'
import RowSpacer from '../tables/RowSpacer'
import { RooStratPortfolio, RooStratPortfolioSummary } from '../../types/rooStrat'
import { portfolioSummary } from '../../utilities/rooTracker'
import { balanceColor, isRealNumber, toLocalDecimals } from '../../utilities/general'
import { compact, toNumber } from 'lodash'
import { setToFirebase, updateToFirebase } from '../../services/api/firebase'
import toast from 'react-hot-toast'
import PlSummary from './PlSummary'
import { plColor } from '../../utilities/general'

interface Props {
  rooStratPortfolio: RooStratPortfolio
}

const PortfolioSummary: FC<Props> = ({ rooStratPortfolio }) => {
  const settings = rooStratPortfolio.settings
  const tickerGroups = compact(rooStratPortfolio.tickerGroups)

  const [showTosDeltaInput, setShowTosDeltaInput] = useState(false)
  const [showTosThetaInput, setShowTosThetaInput] = useState(false)
  const [showTosBpInput, setShowTosBpInput] = useState(false)
  const [tosTotalDelta, setTosTotalDelta] = useState<number>(0)
  const [tosTotalTheta, setTosTotalTheta] = useState<number>(0)
  const [tosTotalBp, setTosTotalBp] = useState<number>(0)

  const summ: RooStratPortfolioSummary = portfolioSummary(tickerGroups)

  const calcBalPercent = (deltaOrTheta: string, calcOrTos: string): number | string => {
    var value, bp
    if (calcOrTos === 'calc') {
      value = deltaOrTheta === 'delta' ? summ.delta : summ.theta
      bp = summ.bp
    } else
    if (calcOrTos === 'tos' && settings && settings.tosTotalBp && settings.tosTotalTheta && settings.tosTotalDelta ) {
      value = deltaOrTheta === 'delta' ? toNumber(settings.tosTotalDelta) : toNumber(settings.tosTotalTheta)
      bp = toNumber(settings.tosTotalBp)
    }

    if (!value || value === 0) { return 0 }
    if (!bp || bp === 0) { return '' }
    if (isRealNumber(value) && isRealNumber(bp)) {
      return value && bp ? toLocalDecimals((value / bp) * 100, 2) : ''
    }

    return ''
  }

  const toggleShowTosDeltaInput = async ({forceSave = false}) => {
    const newShow = showTosDeltaInput ? false : true
    setShowTosDeltaInput(newShow)
    if (forceSave && newShow === false && tosTotalDelta !== settings.tosTotalDelta) {
      try {
        await setToFirebase({ refPath: 'rooStratPortfolio/settings/tosTotalDelta', value: tosTotalDelta })
      } catch (e) {
        console.error(`PortfolioSummary.tsx - toggleShowTosDeltaInput() ${e}`)
        toast.error('NOT saved.')
      }
    }
  }

  const toggleShowTosThetaInput = async ({forceSave = false}) => {
    const newShow = showTosThetaInput ? false : true
    setShowTosThetaInput(newShow)
    if (forceSave && newShow === false && tosTotalTheta !== settings.tosTotalTheta) {
      try {
        await setToFirebase({ refPath: 'rooStratPortfolio/settings/tosTotalTheta', value: tosTotalTheta })
      } catch (e) {
        console.error(`PortfolioSummary.tsx - toggleShowTosThetaInput() ${e}`)
        toast.error('NOT saved.')
      }
    }
  }

  const toggleShowTosBpInput = async ({forceSave = false}) => {
    const newShow = showTosBpInput ? false : true
    setShowTosBpInput(newShow)
    if (forceSave && newShow === false && tosTotalBp !== settings.tosTotalBp) {
      try {
        await setToFirebase({ refPath: 'rooStratPortfolio/settings/tosTotalBp', value: tosTotalBp })
      } catch (e) {
        console.error(`PortfolioSummary.tsx - toggleShowTosBpInput() ${e}`)
        toast.error('NOT saved.')
      }
    }
  }

  const onChangeTosTotalDelta = (e: BaseSyntheticEvent) => { setTosTotalDelta(e.target.value) }
  const onChangeTosTotalTheta = (e: BaseSyntheticEvent) => { setTosTotalTheta(e.target.value) }
  const onChangeTosTotalBp = (e: BaseSyntheticEvent) => { setTosTotalBp(e.target.value) }

  const onKeyPressSaveToFb = async (e: BaseSyntheticEvent | any) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      const updates: {[key: number | string]: number | string} = {}
      updates['tosTotalBp'] = tosTotalBp
      updates['tosTotalDelta'] = tosTotalDelta
      updates['tosTotalTheta'] = tosTotalTheta
      try {
        await updateToFirebase({ refPath: 'rooStratPortfolio/settings', updates})
        if (showTosBpInput) setShowTosBpInput(false)
        if (showTosDeltaInput) setShowTosDeltaInput(false)
        if (showTosThetaInput) setShowTosThetaInput(false)
      } catch (e) {
        console.error(`PortfolioSummary.tsx - onKeyPressSaveToFb() ${e}`)
        toast.error('NOT saved.')
      }
    }
  }

  useEffect(() => {
    if(settings) {
      setTosTotalDelta(settings.tosTotalDelta)
      setTosTotalTheta(settings.tosTotalTheta)
      setTosTotalBp(settings.tosTotalBp)
    }
  }, [settings])

  return (
    <>
      <table className='ml-auto mr-auto cursor-default pos-font mt-3'>
        <thead></thead>
        <tbody>
          <RowSpacer heightPx={8} />

          <tr>
            <td className='text-right font-bold text-xl' title='Portfolio ∆ balance percent.'>
              ∆
            </td>
            <td
              className={`text-right font-bold text-xl pl-2 ${balanceColor(settings, 'delta', calcBalPercent('delta', 'calc'))}`}
              title='Portfolio ∆ balance percent.'
            >
              { calcBalPercent('delta', 'calc') + '%' }
            </td>
            <td
              className='text-right align-middle'
              title='Portfolio total ∆.'
            >
              Σ∆
            </td>
            <td
              className='pl-2 align-middle'
              title='Portfolio total ∆.'
            >
              {toLocalDecimals(summ.delta)}
            </td>
            <td></td>
            <td
              className='text-right font-bold text-lg border-l border-l-dark-grey'
              title='ToS ∆ balance percent.'
            >
              ToS ∆
            </td>
            <td
              className={`text-left align-middle font-bold pl-2 ${balanceColor(settings, 'delta', calcBalPercent('delta', 'tos'))}`}
              title='ToS ∆ balance percent.'
            >
              { calcBalPercent('delta', 'tos') + '%' }
            </td>
            <td
              className='text-right align-middle pl-10 cursor-pointer'
              title='ToS total ∆.'
              onClick={() => toggleShowTosDeltaInput({forceSave: true})}
            >
                Σ∆
            </td>
            <td
              className='pl-2 align-middle cursor-pointer' onClick={() => setShowTosDeltaInput(true)}
              title='ToS total ∆.'
            >
              {showTosDeltaInput ? (
                <input
                  id='tosTotalDelta'
                  type='number'
                  className='input-base'
                  value={tosTotalDelta}
                  onChange={onChangeTosTotalDelta}
                  onKeyDown={onKeyPressSaveToFb}
                  autoFocus
                />
                ) : ( tosTotalDelta )
              }
            </td>
          </tr>

          <tr>
            <td className='text-right font-bold text-xl' title='Portfolio θ balance percent.'>
              θ
            </td>
            <td
              className={`text-right font-bold text-xl pl-2 ${balanceColor(settings, 'theta', calcBalPercent('theta', 'calc'))}`}
              title='Portfolio θ balance percent.'
            >
              { calcBalPercent('theta', 'calc') + '%' }
            </td>
            <td
              className='text-right align-middle minw6'
              title='Portfolio total θ.'
            >
              Σθ
            </td>
            <td
              className='pl-2 align-middle'
              title='Portfolio total θ.'
            >
              {toLocalDecimals(summ.theta)}
            </td>
            <td className='minw4'></td>
            <td
              className='text-right font-bold align-middle text-lg minw7 border-l border-l-dark-grey'
              title='ToS θ balance percent.'
            >
              ToS θ
            </td>
            <td
              className={`text-right align-middle font-bold pl-2 ${balanceColor(settings, 'theta', calcBalPercent('theta', 'tos'))}`}
              title='ToS θ balance percent.'
            >
              { calcBalPercent('theta', 'tos') + '%' }
            </td>
            <td
              className='text-right pl-10 cursor-pointer align-middle'
              title='ToS total θ.'
              onClick={() => toggleShowTosThetaInput({forceSave: true})}
            >
                Σθ
            </td>
            <td
              className='pl-2 cursor-pointer align-middle' onClick={() => setShowTosThetaInput(true)}
              title='ToS total θ.'
            >
              {showTosThetaInput ? (
                <input
                  id='tosTotalTheta'
                  type='number'
                  className='input-base'
                  value={tosTotalTheta}
                  onChange={onChangeTosTotalTheta}
                  onKeyDown={onKeyPressSaveToFb}
                  autoFocus
                />
                ) : ( tosTotalTheta )
              }
            </td>
          </tr>

          <tr>
            <td colSpan={2}></td>
            <td
              className='text-right align-middle'
              title='Total buying power used.'
            >
              ΣBP
            </td>
            <td
              className='pl-2 align-middle'
              title='Total buying power used.'
            >
              {toLocalDecimals(summ.bp)}
            </td>
            <td></td>
            <td colSpan={2} className='border-l dark:border-l-dark-grey'></td>
            <td
              className='text-right pl-10 cursor-pointer'
              title='ToS total buying power used.'
              onClick={() => toggleShowTosBpInput({forceSave: true})}
            >
                ToS ΣBP
            </td>
            <td
              className='pl-2 cursor-pointer' onClick={() => setShowTosBpInput(true)}
              title='ToS total buying power used.'
            >
              {showTosBpInput ? (
                <input
                  id='tosTotalBp'
                  type='number'
                  className='input-wide'
                  value={tosTotalBp}
                  onChange={onChangeTosTotalBp}
                  onKeyDown={onKeyPressSaveToFb}
                  autoFocus
                />
                ) : ( toNumber(tosTotalBp).toLocaleString() )
              }
            </td>
          </tr>

          <RowSpacer heightPx={15} />
        </tbody>
      </table>

      <div className='flex mb-5'>
        <div className='text-right align-middle'>Portfolio</div>
        <div
            className={`text-left align-middle pl-4 ${plColor(summ.pl)}`}
            title='Portfolio max profit, actual profit/loss and profit/loss percent.'
        >
          <PlSummary summ={summ} premium={summ.premium} />
        </div>
      </div>
    </>

  )
}

export default PortfolioSummary