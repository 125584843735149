import { cloneDeep, compact, forEach, isEqual } from 'lodash'
import { BaseSyntheticEvent, FC, useRef } from 'react'
import toast from 'react-hot-toast'
import uuid from 'react-uuid'
import { setToFirebase } from '../../services/api/firebase'
import { HiMiniBars4 } from 'react-icons/hi2'
import ReactTooltip from 'react-tooltip'
import { Spread } from '../../types/rooStrat'

interface Props {
  spreads: Spread[]
  groupKey: string | number
}

const DndPositions: FC<Props> = ({ spreads, groupKey }) => {
  const dragItem = useRef()
  const dragOverItem = useRef()

  const tooltipId = `dnd-positions-${groupKey}`

  const dragStart = (e: BaseSyntheticEvent) => {
    dragItem.current = e.target.id
  }

  const dragEnter = (e: BaseSyntheticEvent, spreadKey: number | string) => {
    resetAllHeights()
    dragOverItem.current = e.currentTarget.id
    const spacerEl = document.getElementById(`position-dnd-div-spacer-${groupKey}-${spreadKey}`)
    if (spacerEl && dragOverItem.current !== dragItem.current) {
      spacerEl.classList.add('height-to-x')
    }
  }

  const resetAllHeights = () => {
    const spacerEls = document.getElementsByClassName('position-dnd-div-spacer')
    if (spacerEls) {
      forEach(spacerEls, el => { el.classList.remove('height-to-x') })
    }
  }

  const positionDrop = async () => {
    var dataCopy = cloneDeep(spreads)
    if (dragItem.current && dragOverItem.current) {
      const dragItemContent = dataCopy[dragItem.current]
      dataCopy.splice(dragItem.current, 1)
      dataCopy.splice(dragOverItem.current, 0, dragItemContent)
    }
    if (isEqual(dataCopy, spreads)) {
      resetAllHeights()
      return
    }
    try {
      dragItem.current = undefined
      dragOverItem.current = undefined
      const spreadsPath = `rooStratPortfolio/tickerGroups/${groupKey}/spreads`
      dataCopy = compact(dataCopy)
      await setToFirebase({ refPath: spreadsPath, value: dataCopy })
      toast.success('New positions order saved.')
    } catch (e) {
      console.error(`PortfolioDeltaBalance.tsx - onClickSaveEdit() ${e}`)
      toast.error('Reorder NOT saved.')
      resetAllHeights()
    }
  }

  return (
    <>

      <div data-tip data-for={tooltipId}>
        <HiMiniBars4
          className='action-icons'
          title='Sort positions.'
        />
      </div>

      <ReactTooltip
        id={tooltipId}
        place='bottom'
        effect='solid'
        clickable={true}
        delayShow={300}
        delayHide={350}
        className='opacity-full'
      >
        {Object.keys(spreads).map((spreadKey: any) => (
          <div key={uuid()}>
            <div
              id={`position-dnd-div-spacer-${groupKey}-${spreadKey}`}
              className='position-dnd-div-spacer h-1'
            ></div>
            <div
              id={spreadKey}
              onDragStart={e => dragStart(e)}
              onDragEnter={e => dragEnter(e, spreadKey)}
              onDragEnd={positionDrop}
              draggable
              className={`dnd-dd-text cursor-pointer ${spreads[spreadKey].isHedge ? 'text-cyan' : ''}`}
            >
              {spreads[spreadKey].shortLeg.occDisplay}
            </div>
          </div>
        ))}
      </ReactTooltip>

    </>
  )
}

export default DndPositions
