import { toNumber } from 'lodash'
import { FC } from 'react'
import { RooStratPortfolioSummary, SpreadSummary } from '../../types/rooStrat'
import { displayPercent, toLocalDecimals } from '../../utilities/general'

interface Props {
  summ: RooStratPortfolioSummary | SpreadSummary
  premium: number | undefined
}

const PlSummary: FC<Props> = ({ summ, premium }) => {
  return (
    <>
      <p className='inline-block w-11 text-left dark:text-white'>Pmax</p>
      <p className='inline-block w-10 text-right dark:text-white'>{toLocalDecimals(premium)}</p>
      <p className='inline-block w-10 text-left pl-4'>P/L</p>
      <p className='inline-block w-9 text-right'>{toLocalDecimals(summ.pl)}</p>
      <p className='inline-block w-12 text-right'>{displayPercent(toNumber(toLocalDecimals(summ.plPercent)))}</p>
    </>
)
}

export default PlSummary
