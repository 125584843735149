import { cloneDeep, compact, isEmpty, toNumber } from 'lodash'
import { BaseSyntheticEvent, FC, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { HiMiniPencilSquare, HiNoSymbol, HiOutlineCheckCircle, HiOutlineEye, HiOutlineEyeSlash, HiOutlineStar, HiStar } from 'react-icons/hi2'
import uuid from 'react-uuid'
import { getOptionQuote } from '../../services/api/market-data-option-quotes'
import { deleteFromFirebase, setToFirebase } from '../../services/api/firebase'
import { RooStratSettings, Spread, SpreadSummary, Tier } from '../../types/rooStrat'
import { validateOccInput } from '../../utilities/occSymbol'
import { spreadSummary, checkIsValidPosition } from '../../utilities/rooTracker'
import { deltaBgColor, deltaColor, posBpTooBigColor, plColor, toLocalDecimals, dteColor } from '../../utilities/general'
import RowSpacer from '../tables/RowSpacer'
import PlSummary from './PlSummary'
import TierRow from './TierRow'

interface Props {
  pos?: Spread
  spreads?: Spread[]
  ticker: string
  groupKey: number
  spreadKey?: number
  isNew?: boolean
  toggleShowNewPosForm?: any
  settings: RooStratSettings
}

const PositionRows: FC<Props> = (
  {
    pos,
    spreads,
    ticker,
    groupKey,
    spreadKey,
    isNew,
    toggleShowNewPosForm,
    settings,
  }
) => {
  const [editPos, setEditPos] = useState(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [isPosHedge, setIsPosHedge] = useState(pos?.isHedge || false)
  const [isPosIgnored, setIsPosIgnored] = useState(pos?.ignore || false)
  const [isPosFavorite, setIsPosFavorite] = useState(pos?.isFavorite || false)
  const [spreadName, setSpreadName] = useState('')
  const [sLegOccDisplay, setSLegOccDisplay] = useState(pos?.shortLeg.occDisplay || '')
  const [lLegOccDisplay, setLLegOccDisplay] = useState(pos?.longLeg.occDisplay || '')
  const [sLegContracts, setSLegContracts] = useState(pos?.shortLeg.contracts || -1)
  const [lLegContracts, setLLegContracts] = useState(pos?.longLeg.contracts || 1)
  const [sLegPrice, setSLegPrice] = useState(pos?.shortLeg.price || 0)
  const [lLegPrice, setLLegPrice] = useState(pos?.longLeg.price || 0)
  const [errSLegOccDisplay, setErrSLegOccDisplay] = useState('')
  const [errLLegOccDisplay, setErrLLegOccDisplay] = useState('')
  const [isValidPosition, setIsValidPosition] = useState({ isValid: true, errMsg: ''})
  const [posTiers, setPosTiers] = useState(pos?.tiers || [])
  const [updatedTiers, setUpdatedTiers] = useState<Tier[]>()
  const [preEditState, setPreEditState] = useState<{
    isPosHedge: boolean,
    sLegOccDisplay: string,
    lLegOccDisplay: string,
    sLegContracts: number,
    lLegContracts: number,
    sLegPrice: number,
    lLegPrice: number,
    posTiers: Tier[]
  }>({
    isPosHedge,
    sLegOccDisplay,
    lLegOccDisplay,
    sLegContracts,
    lLegContracts,
    sLegPrice,
    lLegPrice,
    posTiers
    })

  const summ: SpreadSummary = spreadSummary(pos)
  const spreadsPath = `rooStratPortfolio/tickerGroups/${groupKey}/spreads`
  const spreadPath = `${spreadsPath}/${spreadKey}`

  const genNewSpread = async () => {
    const sLegOccSymbol = validateOccInput({ ticker, input: sLegOccDisplay }).occSymbol
    const lLegOccSymbol = validateOccInput({ ticker, input: lLegOccDisplay }).occSymbol
    const oldShortLeg = pos?.shortLeg || {}
    const oldLongLeg = pos?.longLeg || {}
    try {
      const [sLegOptionQuote, lLegOptionQuote] = await Promise.all([
        getOptionQuote({marketDataOccSymbol: sLegOccSymbol}),
        getOptionQuote({marketDataOccSymbol: lLegOccSymbol}),
      ])
      return {
        isHedge: isPosHedge,
        shortLeg: {
          ...oldShortLeg,
          occDisplay: sLegOccDisplay,
          occSymbol: sLegOccSymbol,
          contracts: sLegContracts,
          price: sLegPrice,
          dte: sLegOptionQuote.dte[0],
          mark: sLegOptionQuote.mid[0],
          delta: sLegOptionQuote.delta[0],
          theta: sLegOptionQuote.theta[0],
          strike: sLegOptionQuote.strike[0],
          side: sLegOptionQuote.side[0],
        },
        longLeg: {
          ...oldLongLeg,
          occDisplay: lLegOccDisplay,
          occSymbol: lLegOccSymbol,
          contracts: lLegContracts,
          price: lLegPrice,
          dte: lLegOptionQuote.dte[0],
          mark: lLegOptionQuote.mid[0],
          delta: lLegOptionQuote.delta[0],
          theta: lLegOptionQuote.theta[0],
          strike: lLegOptionQuote.strike[0],
          side: lLegOptionQuote.side[0],
        },
        tiers: updatedTiers || posTiers,
      }
    } catch (e) {
      console.error(`PositionRows.ts - getNewSpread() - ${e}`)
      return {}
    }
  }

  const savePosition = async () => {
    var toastId, newSpread
    if (!sLegOccDisplay || !lLegOccDisplay) {
      toast.error('Option symbol data missing.')
      return
    }
    if (isNew || editPos) {
      toastId = toast.loading('Saving and updating option quotes ...')
      newSpread = await genNewSpread()
    }
    if (isNew) {
      const oldSpreads = compact(cloneDeep(spreads)) || []
      var newSpreads = [...oldSpreads, newSpread]
      if (newSpreads.length - oldSpreads.length === 1 ) {
        try {
          await setToFirebase({ refPath: spreadsPath, value: newSpreads })
          toast.success('Position saved and quote updated.')
        } catch (e) {
          console.error(`PortfolioDeltaBalance.tsx - savePosition() ${e}`)
          toast.error('NOT saved.')
        } finally {
          toast.dismiss(toastId)
        }
      }
    } else
    if (editPos) {
      try {
        await setToFirebase({ refPath: spreadPath, value: newSpread })
        toast.success('Position edited and quote updated.')
      } catch (e) {
        console.error(`PortfolioDeltaBalance.tsx - savePosition() ${e}`)
        toast.error('NOT saved.')
      } finally {
        toast.dismiss(toastId)
      }
  }
  }

  const onChangeLongLegOccDisplay = (e: BaseSyntheticEvent) => {
    setErrLLegOccDisplay('')
    const value = e.target.value
    setLLegOccDisplay(value)
    const isInputValid = validateOccInput( { ticker, input: value })
    setErrLLegOccDisplay(isInputValid.err)
  }

  const onChangeShortLegOccDisplay = (e: BaseSyntheticEvent) => {
    setErrSLegOccDisplay('')
    const value = e.target.value
    setSLegOccDisplay(value)
    const isInputValid = validateOccInput( { ticker, input: value })
    setErrSLegOccDisplay(isInputValid.err)
  }

  const onBlurShortLegOccDisplay = () => {
    const sLegOcc = validateOccInput({ ticker, input: sLegOccDisplay })

    if (sLegOcc.isValid) {
      setSLegOccDisplay(sLegOcc.occDisplay)

      if (!lLegOccDisplay) {
        const sLegOccSplit = sLegOcc.occDisplay.split(' ')
        let strike = toNumber(sLegOccSplit[3])
        const side = sLegOccSplit[4][0].toLowerCase()
        if (isPosHedge) {
          sLegOccSplit[3] = side === 'c' ? (strike - 5).toString() : (strike + 5).toString()
        } else {
          sLegOccSplit[3] = side === 'c' ? (strike + 5).toString() : (strike - 5).toString()
        }
        const lLegDisplay = sLegOccSplit.join(' ')
        const lLegOcc = validateOccInput({ ticker, input: lLegDisplay })
        setLLegOccDisplay(lLegDisplay)
        setErrLLegOccDisplay(lLegOcc.err)
      }
    }
  }

  const onBlurLongLegOccDisplay = () => {
    const lLegOcc = validateOccInput({ ticker, input: lLegOccDisplay })
    if (lLegOcc.isValid) {
      setLLegOccDisplay(lLegOcc.occDisplay)
    } else {
      setErrLLegOccDisplay(lLegOcc.err)
    }
  }

  const onBlurShortLegContracts = (e: BaseSyntheticEvent) => {
    setLLegContracts(Math.abs(e.target.value))
  }

  const onChangeShortLegContracts = (e: BaseSyntheticEvent) => setSLegContracts(-1 * Math.abs(e.target.value))
  const onChangeLongLegContracts = (e: BaseSyntheticEvent) => setLLegContracts(Math.abs(e.target.value))
  const onChangeShortLegPrice = (e: BaseSyntheticEvent) => setSLegPrice(e.target.value)
  const onChangeLongLegPrice = (e: BaseSyntheticEvent) => setLLegPrice(e.target.value)

  const onKeyDownSaveEdit = (e: BaseSyntheticEvent | any) => {
    if (e.key === 'Enter') savePosition()
  }

  const toggleIsPosIgnored = async () => {
    try {
      const newValue = !isPosIgnored
      setIsPosIgnored(newValue)
      const ignorePath = `${spreadPath}/ignore`
      await setToFirebase({ refPath: ignorePath, value: newValue })
      toast.success('Ignore saved.')
    } catch (e) {
      toast.error('Ignore NOT saved.')
    }
  }

  const toggleIsPosFavorite = async () => {
    try {
      const newValue = !isPosFavorite
      setIsPosFavorite(newValue)
      const favPath = `${spreadPath}/isFavorite`
      await setToFirebase({ refPath: favPath, value: newValue })
      toast.success('Favorite saved.')
    } catch (e) {
      toast.error('Favorite NOT saved.')
    }
  }

  const deletePos = async () => {
    if (isNew) {
      toggleShowNewPosForm()
      return
    }
    try {
      await deleteFromFirebase({ refPath: spreadPath })
      toast.success('Position deleted.')
    } catch (e) {
      console.error(`PositionRows.ts - deletePos() ${e}`)
      toast.error('Position NOT deleted.')
    }
  }

  const cancelEdit = () => {
    setEditPos(false)
    setIsPosHedge(preEditState.isPosHedge)
    setSLegOccDisplay(preEditState.sLegOccDisplay)
    setLLegOccDisplay(preEditState.lLegOccDisplay)
    setSLegContracts(preEditState.sLegContracts)
    setLLegContracts(preEditState.lLegContracts)
    setSLegPrice(preEditState.sLegPrice)
    setLLegPrice(preEditState.lLegPrice)
    setPosTiers(preEditState.posTiers)
  }

  const enterEditMode = () => {
    setEditPos(true)
    setPreEditState({
      isPosHedge,
      sLegOccDisplay,
      lLegOccDisplay,
      sLegContracts,
      lLegContracts,
      sLegPrice,
      lLegPrice,
      posTiers,
      })
  }

  const posSumRowClassName = () => {
    const borderColor = summ.pl && summ.pl > 0 ? 'border-success' : 'border-danger'
    return `dark:bg-dark-menu bg-highlighter border-t pt-2 ${borderColor}`
  }

  useEffect(() => {
    if (!isEmpty(posTiers) && posTiers && isEmpty(updatedTiers)) {
      const tiersClone = compact(cloneDeep(posTiers))
      setUpdatedTiers(tiersClone)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pos])

  useEffect(() => {
    if (isNew) enterEditMode()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew])

  useEffect(() => {
    setIsValidPosition(checkIsValidPosition({ sLeg: sLegOccDisplay, lLeg: lLegOccDisplay, isHedge: isPosHedge }))

    var sLegSide = pos?.shortLeg.side
    var lLegSide = pos?.longLeg.side
    var spreadName = 'Unknown'

    if ((!sLegSide || !lLegSide) && (sLegOccDisplay && lLegOccDisplay)) {
      const sLegSplit = sLegOccDisplay.split(' ')
      const lLegSplit = lLegOccDisplay.split(' ')
      if( !isEmpty(sLegSplit) && !isEmpty(lLegSplit)) {
        sLegSide = sLegSplit[4][0] === 'p' || 'P' ? 'put' : 'call'
        lLegSide = lLegSplit[4][0] === 'p' || 'P' ? 'put' : 'call'
      }
    }

    if (sLegSide && sLegSide.toLowerCase() === 'put') {
      spreadName = isPosHedge ? 'Hedge\nbear put' : 'Bull\nput'
    } else if (lLegSide && lLegSide.toLowerCase() === 'call') {
      spreadName = isPosHedge ? 'Hedge\nbull call' : 'Bear\ncall'
    }

    setSpreadName(spreadName)
  }, [isPosHedge, sLegOccDisplay, lLegOccDisplay, pos])

  return (
    <>
      {!editPos &&
        <RowSpacer heightPx={14} />
      }

      { (isNew || editPos) &&
        <tr>
          <td colSpan={5} className='pl-2'>
            <span className='font-bold pr-6'>{ isNew ? 'New' : 'Edit' } Spread</span>
            <input
              id='isHedgeCheckbox'
              type='checkbox'
              className='dark:bg-medium-grey cursor-pointer'
              checked={isPosHedge}
              onChange={() => setIsPosHedge(!isPosHedge)}
            />
            <span
              className='cursor-pointer pl-2 text-blue dark:text-cyan'
              onClick={() => setIsPosHedge(!isPosHedge)}
            >
              Hedge
            </span>
          </td>
        </tr>
      }

      <tr className={`${isPosIgnored ? 'text-light-grey dark:text-grey' : ''}`}>
        <td rowSpan={2}
            className={`col-1-w dark:bg-dark-menu bg-highlighter text-center whitespace-pre-line
                        ${isPosHedge ? 'text-blue dark:text-cyan' : ''}`}>
          {spreadName}
        </td>
        <td className='col-2-w dark:bg-dark-menu bg-highlighter'>
          {editPos ? (
            <>
              <input
                id='editShortLegOccDisplay'
                type='text'
                className='input-base w-full dark:bg-medium-grey'
                placeholder='Short leg ...'
                value={sLegOccDisplay}
                onChange={onChangeShortLegOccDisplay}
                onBlur={onBlurShortLegOccDisplay}
                onKeyDown={onKeyDownSaveEdit}
                autoFocus
              />
              {errSLegOccDisplay && <span className='text-danger'>{errSLegOccDisplay}</span>}
            </>
            ) : (
              <span className={isPosHedge ? 'text-blue dark:text-cyan' : ''}>{sLegOccDisplay}</span>
            )
          }
        </td>
        <td className='col-3-w dark:bg-dark-menu bg-highlighter text-right pr-2'>
          {editPos ? (
            <input
              id='editShortLegContracts'
              type='number'
              max={0}
              className='input-base w-full dark:bg-medium-grey text-center'
              value={sLegContracts}
              onChange={onChangeShortLegContracts}
              onBlur={onBlurShortLegContracts}
              onKeyDown={onKeyDownSaveEdit}
            />
            ) : ( sLegContracts )
          }
        </td>
        <td className={`col-4-w dark:bg-dark-menu bg-highlighter text-right pr-2 ${pos?.shortLeg.dte && summ.pl && dteColor(pos.shortLeg.dte, summ.pl)}`}>
          {pos?.shortLeg.dte}
        </td>
        <td className='col-5-w dark:bg-dark-menu bg-highlighter text-right pr-1'>
          {pos?.shortLeg.mark && toLocalDecimals(pos.shortLeg.mark, 2)}
        </td>
        <td className='col-6-w dark:bg-dark-menu bg-highlighter text-right pr-1'>
          {editPos ? (
            <input
              id='editShortLegPrice'
              type='number'
              className='input-base w-full dark:bg-medium-grey text-center'
              value={sLegPrice || ''}
              onChange={onChangeShortLegPrice}
              onKeyDown={onKeyDownSaveEdit}
            />
            ) : ( toLocalDecimals(sLegPrice, 2) )
          }
        </td>
        <td className='col-7-w text-right dark:bg-dark-menu bg-highlighter'>
          <div className={`${!isPosHedge ? deltaBgColor(pos?.shortLeg.delta) : 'dark:bg-dark-menu bg-highlighter'}
                           ${deltaColor(pos?.shortLeg.delta)} pr-2`}>
            {toLocalDecimals(pos?.shortLeg.delta, 2)}
          </div>
        </td>
        <td className='col-8-w dark:bg-dark-menu bg-highlighter text-right'>
          {toLocalDecimals(pos?.shortLeg.theta, 2)}
        </td>
        <td className='col-9-w dark:bg-dark-menu bg-highlighter text-right pr-1'>
          {pos?.shortLeg?.strike}
        </td>
        <td className='col-10-w dark:bg-dark-menu bg-highlighter'></td>
        <td
          rowSpan={editPos ? 2 : 3}
          className='col-11-w dark:bg-dark-menu bg-highlighter border-l border-grey'
        >
          { !isPosHedge &&
            Object.keys(posTiers).map((tierKey: any) => (
              <TierRow
                key={uuid()}
                isNew={false}
                editPos={editPos}
                posTiers={posTiers}
                tierKey={tierKey}
                savePosition={savePosition}
                updatedTiers={updatedTiers || []}
                setUpdatedTiers={setUpdatedTiers}
              />
            ))
          }
          { !isPosHedge &&
            <TierRow
              isNew={true}
              editPos={editPos}
              posTiers={posTiers}
              tierKey={posTiers.length + 1}
              savePosition={savePosition}
              updatedTiers={updatedTiers || []}
              setUpdatedTiers={setUpdatedTiers}
            />
          }
        </td>
        <td rowSpan={3} className='col-12-w pl-3'>
          <div className='flex justify-center'>
            {editPos ? (
              (!sLegOccDisplay || !lLegOccDisplay || !isValidPosition.isValid) ? (
                <HiOutlineCheckCircle
                  className='action-icons check-circle-sz text-orange'
                  title='Option symbol data missing.'
                />
              ) : (
                <HiOutlineCheckCircle
                  className='action-icons check-circle-sz text-success'
                  title='Save'
                  onClick={() => savePosition()}
                />
              )
            ) : (
              <HiMiniPencilSquare
                className='action-icons'
                title='Edit position'
                onClick={() => enterEditMode()}
              />
            )}
            <HiNoSymbol
              className='action-icons text-danger'
              title='Delete position'
              onClick={() => {
                if (isNew || !editPos) {
                  setShowConfirmDelete(s => !s)
                } else {
                  cancelEdit()
                }
              }}
            />
          </div>
          <div className='flex justify-center'>
            {showConfirmDelete &&
              <>
                <span className='cursor-pointer text-danger px-2' onClick={() => deletePos()} >Yes</span>
                <span className='cursor-pointer' onClick={() => setShowConfirmDelete(false)} >No</span>
              </>
            }
          </div>
          <div className='flex justify-center pt-3'>
            {isPosIgnored ? (
              <HiOutlineEyeSlash
                className='action-icons-md text-danger'
                onClick={toggleIsPosIgnored}
              />
            ):(
              <HiOutlineEye
                className='action-icons'
                onClick={toggleIsPosIgnored}
              />
            )}
            {isPosFavorite ? (
              <HiStar
                className='action-icons-md text-yellow'
                onClick={toggleIsPosFavorite}
              />
            ):(
              <HiOutlineStar
                className='action-icons'
                onClick={toggleIsPosFavorite}
              />
            )}
          </div>
        </td>
      </tr>




      <tr className={`${isPosIgnored ? 'text-light-grey dark:text-grey' : ''}`}>
        <td className='dark:bg-dark-menu bg-highlighter whitespace-pre-line'>
          {editPos ? (
            <>
              <input
                id='editLongLegOccDisplay'
                type='text'
                className='input-base w-full dark:bg-medium-grey'
                placeholder='Long leg ...'
                value={lLegOccDisplay}
                onBlur={onBlurLongLegOccDisplay}
                onChange={onChangeLongLegOccDisplay}
                onKeyDown={onKeyDownSaveEdit}
              />
              {errLLegOccDisplay && <span className='text-danger'>{errLLegOccDisplay}</span>}
              {isValidPosition.errMsg && <span className='text-danger'>{isValidPosition.errMsg}</span>}
            </>
            ) : (
              <>
                <span className={isPosHedge ? 'text-blue dark:text-cyan' : ''}>{lLegOccDisplay}</span>
                {isValidPosition.errMsg && <p className='text-danger'>{isValidPosition.errMsg}</p>}
              </>
            )
          }
        </td>
        <td className='dark:bg-dark-menu bg-highlighter text-right pr-2'>
          {editPos ? (
            <input
              id='editLongLegcontracts'
              type='number'
              className='input-base w-full dark:bg-medium-grey text-center'
              value={lLegContracts}
              onChange={onChangeLongLegContracts}
              onKeyDown={onKeyDownSaveEdit}
            />
            ) : ( lLegContracts )
          }
        </td>
        <td className='dark:bg-dark-menu bg-highlighter text-right pr-2'>
          {pos?.longLeg?.dte}
        </td>
        <td className='dark:bg-dark-menu bg-highlighter text-right pr-1'>
          {toLocalDecimals(pos?.longLeg.mark, 2)}
        </td>
        <td className='dark:bg-dark-menu bg-highlighter text-right pr-1'>
          {editPos ? (
            <input
              id='editLongLegPrice'
              type='number'
              className='input-base w-full dark:bg-medium-grey text-center'
              value={lLegPrice || ''}
              onChange={onChangeLongLegPrice}
              onKeyDown={onKeyDownSaveEdit}
            />
            ) : ( toLocalDecimals(lLegPrice, 2) )
          }
        </td>
        <td className='dark:bg-dark-menu bg-highlighter text-right pr-2'>
          {toLocalDecimals(pos?.longLeg.delta, 2)}
        </td>
        <td className='dark:bg-dark-menu bg-highlighter text-right'>
          {toLocalDecimals(pos?.longLeg.theta, 2)}
        </td>
        <td className='dark:bg-dark-menu bg-highlighter text-right pr-1'>
          {pos?.longLeg?.strike}
        </td>
        <td className='dark:bg-dark-menu bg-highlighter text-right'></td>
      </tr>



      {!editPos ? (
        <tr className={`cursor-default ${isPosIgnored ? 'text-light-grey dark:text-grey' : ''}`}>
          <td></td>
          <td colSpan={3}
              className={`${posSumRowClassName()} whitespace-nowrap text-left pl-1 ${plColor(summ.pl)}`}
              title='Position max profit, actual profit/loss and profit/loss percent.'
          >
            <PlSummary summ={ summ } premium={ summ.posPremium } />
          </td>
          <td className={`${posSumRowClassName()} text-right pr-1`} title='Position mark.'>
            {toLocalDecimals(summ.mark, 2)}
          </td>
          <td className={`${posSumRowClassName()} text-right pr-1`} title='Position premium.'>
            {toLocalDecimals(summ.premium, 2)}
          </td>
          <td className={`${posSumRowClassName()} text-right pr-4`} title='Position delta.'>
            { toLocalDecimals(summ.delta)}
          </td>
          <td className={`${posSumRowClassName()} text-right pr-2`} title='Position theta.'>
            { toLocalDecimals(summ.theta)}
          </td>
          <td className={`${posSumRowClassName()} text-right pr-1`} title='Position spread.'>
            {summ.spread}
          </td>
          <td className={`${posSumRowClassName()} text-right pr-3 ${posBpTooBigColor(summ.bp, settings) ? 'text-danger' : ''}`}
              title='Position buying power used.'
          >
            {toLocalDecimals(summ.bp)}
          </td>
        </tr>
      ) : (
        <RowSpacer heightPx={15} />
      )
      }
    </>
  )
}

export default PositionRows
