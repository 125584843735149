import { ceil, floor, isEmpty, toString } from 'lodash'
import { toast } from 'react-hot-toast'
import { handleError } from '../../utilities/error-handling'

const API_BASE_URL = toString(process.env.REACT_APP_MARKETDATA_API_BASE_URL)
const TOKEN = toString(process.env.REACT_APP_MARKETDATA_TOKEN)

export const getOiData = async (ticker: string, stockQuote: number, expDate: string) => {
  var data: {
    isValid: boolean,
    value: any,
    errMsg: string
  } = {
    isValid: false,
    value: {},
    errMsg: ''
  }

  if (!ticker) return data

  const toastId = toast.loading('Getting option chain data...')

  // Create options string
  const format = 'json'
  const dateformat = 'unix'
  const strikeRange = stockQuote < 50 ? 0.26 : 0.15
  // Filter for data columns
  const columns = [
    'delta',
    'dte',
    'expiration',
    'inTheMoney',
    'openInterest',
    'side',
    'strike',
    'underlyingPrice'
  ].join(',')
  // Filter for strike price strikeRange +/- ...
  const strikeLow = floor(stockQuote * (1 - strikeRange))
  const strikeHigh = ceil(stockQuote * (1 + strikeRange))

  if (strikeLow < 1 || strikeHigh < 1) {
    handleError({ msg: `Failed to fetch option data for ${ticker}. Error: strikeLow=${strikeLow} and strikeHigh=${strikeHigh}` })
  }

  var options = `format=${format}&dateformat=${dateformat}&expiration=${expDate}&columns=${columns}&strike=${strikeLow}-${strikeHigh}`

  try {
    const controller = new AbortController()
    const url = `${API_BASE_URL}/options/chain/${ticker}/?${options}`

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        Authorization: `Token ${TOKEN}`
      },
      signal: controller.signal
    })

    if (!response.ok) {
      handleError({ msg: `Failed to fetch option data for ${ticker}. Error: !response.ok` })
    }

    if (response.ok) {
      const oiData = await response.json()
      if (!isEmpty(oiData)) {
        data.isValid = true
        data.value = oiData
      }
    }

  } catch (e: any) {
    handleError({ msg: 'Failed to get option quote.', e })
    data.errMsg = e

  } finally {
    toast.dismiss(toastId)
    return data
  }
}

export const getExpDates = async (ticker: string) => {

  if (!ticker) return null

  const toastId = toast.loading('Getting expiration dates...')

  try {
    const options = 'format=json&dateformat=unix'
    const controller = new AbortController()
    const url = `${API_BASE_URL}/options/expirations/${ticker}/?${options}`

    const responseExpDates = await fetch(url, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        Authorization: `Token ${TOKEN}`
      },
      signal: controller.signal
    })

    if (!responseExpDates.ok) {
      handleError({ msg: `Failed to get expiration dates for ${ticker}. Error: !response.ok` })
    }

    const data = await responseExpDates.json()

    toast.dismiss(toastId)
    return data

  } catch (e) {
    handleError({ msg: `Failed to get expiration dates for ${ticker}`, e })
    return null
  }
}
