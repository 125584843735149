import { BaseSyntheticEvent, FC, useState } from 'react'
import toast from 'react-hot-toast'
import { HiAdjustmentsHorizontal } from 'react-icons/hi2'
import { setToFirebase } from '../../services/api/firebase'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setTastySettings } from '../../store/userSlice'
import { TastySettings } from '../../types/tasty'
import { handleError } from '../../utilities/error-handling'
import { toLocalDecimals } from '../../utilities/general'
import CollapseSection from '../wrappers/CollapseSection'

const SETTINGS_FB_ROOT = 'tasty/settings'

const PortfolioSettings: FC = () => {
  const settings = useAppSelector(state => state.userStore.user.tasty.settings)

  const dispatch = useAppDispatch()

  const [isExpanded, setIsExpanded] = useState(false)
  const [accEq, setAccEq] = useState<number>(settings.accountEquity)
  const [maxPosSz, setMaxPosSz] = useState<number>(settings.maxPositionSize)
  const [deltaBalHigh, setDeltaBalHigh] = useState<number>(settings.deltaBalanceHigh)
  const [deltaBalLow, setDeltaBalLow] = useState<number>(settings.deltaBalanceLow)
  const [thetaBalHigh, setThetaBalHigh] = useState<number>(settings.thetaBalanceHigh)
  const [thetaBalLow, setThetaBalLow] = useState<number>(settings.thetaBalanceLow)

  const [showAccEqInput, setShowAccEqInput] = useState(false)
  const [showMaxPosSzInput, setShowMaxPosSzInput] = useState(false)
  const [showDeltaBalLowInput, setShowDeltaBalLowInput] = useState(false)
  const [showDeltaBalHighInput, setShowDeltaBalHighInput] = useState(false)
  const [showThetaBalLowInput, setShowThetaBalLowInput] = useState(false)
  const [showThetaBalHighInput, setShowThetaBalHighInput] = useState(false)

  const onChangeAccEq = (e: BaseSyntheticEvent) => { setAccEq(e.target.value) }
  const onChangeMaxPosSz = (e: BaseSyntheticEvent) => { setMaxPosSz(e.target.value) }
  const onChangeDeltaBalLow = (e: BaseSyntheticEvent) => { setDeltaBalLow(e.target.value) }
  const onChangeDeltaBalHigh = (e: BaseSyntheticEvent) => { setDeltaBalHigh(e.target.value) }
  const onChangeThetaBalLow = (e: BaseSyntheticEvent) => { setThetaBalLow(e.target.value) }
  const onChangeThetaBalHigh = (e: BaseSyntheticEvent) => { setThetaBalHigh(e.target.value) }

  const toggleShowAndSave = (x: string) => {
    let newShow
    if (x === 'accEqInput') {
      newShow = !showAccEqInput
      setShowAccEqInput(newShow)
    }
    if (x === 'maxPosSzInput') {
      newShow = !showMaxPosSzInput
      setShowMaxPosSzInput(newShow)
    }
    if (x === 'deltaBalHighInput') {
      newShow = !showDeltaBalHighInput
      setShowDeltaBalHighInput(newShow)
    }
    if (x === 'deltaBalLowInput') {
      newShow = !showDeltaBalLowInput
      setShowDeltaBalLowInput(newShow)
    }
    if (x === 'thetaBalHighInput') {
      newShow = !showThetaBalHighInput
      setShowThetaBalHighInput(newShow)
    }
    if (x === 'thetaBalLowInput') {
      newShow = !showThetaBalLowInput
      setShowThetaBalLowInput(newShow)
    }
    if (newShow === false) {
      const updates: TastySettings = getAllSettings()
      try {
        setToFirebase({ refPath: SETTINGS_FB_ROOT, value: updates })
        dispatch( setTastySettings(updates) )
        toast.success('Setting saved.')
      } catch (e) {
        handleError({ msg: 'Setting NOT saved.', e })
      }
    }
  }

  const onKeyPressSaveToFb = (e: BaseSyntheticEvent | any) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      const updates: TastySettings = getAllSettings()
      try {
        setToFirebase({ refPath: SETTINGS_FB_ROOT, value: updates })
        if (showAccEqInput) setShowAccEqInput(false)
        if (showMaxPosSzInput) setShowMaxPosSzInput(false)
        if (showDeltaBalHighInput) setShowDeltaBalHighInput(false)
        if (showDeltaBalLowInput) setShowDeltaBalLowInput(false)
        if (showThetaBalHighInput) setShowThetaBalHighInput(false)
        if (showThetaBalLowInput) setShowThetaBalLowInput(false)
        dispatch( setTastySettings(updates) )
        toast.success('Setting saved.')
      } catch (e) {
        handleError({ msg: 'Setting NOT saved.', e })
      }
    }
  }

  const getAllSettings = (): TastySettings => {
    return {
      accountEquity: accEq,
      maxPositionSize: maxPosSz,
      deltaBalanceHigh: deltaBalHigh,
      deltaBalanceLow: deltaBalLow,
      thetaBalanceHigh: thetaBalHigh,
      thetaBalanceLow: thetaBalLow
    };
  };

  const collapseTitle = () => {
    return (
      <div className='flex flex-row items-center'>
        <HiAdjustmentsHorizontal
          className={'action-icons-md mr-3 text-orange'}
        />
        <div>
          Settings
        </div>
      </div>
    )
  }

  return (
    <CollapseSection
      title={ collapseTitle() }
      isExpanded={ isExpanded }
      setIsExpanded={ setIsExpanded }
    >
      <div className='flex flex-row justify-between pos-font px-20 mt-6 mb-8'>

        <div className='flex flex-col items-center'>
          <div
            className='font-bold cursor-pointer mb-1'
            onClick={() => toggleShowAndSave('accEqInput')}
          >
              Account Equity
          </div>
          <div
            className='cursor-pointer'
            onClick={() => setShowAccEqInput(true)}
          >
            {
              showAccEqInput ? (
              <input
                id='accEqInput'
                type='number'
                className='input-wide'
                value={accEq}
                onChange={(e: BaseSyntheticEvent) => onChangeAccEq(e)}
                onKeyDown={(e: BaseSyntheticEvent) => onKeyPressSaveToFb(e)}
                autoFocus
              />
              ) : ( toLocalDecimals(accEq, 0) )
            }
          </div>
        </div>

        <div className='flex flex-col items-center'>
          <div
            className='font-bold cursor-pointer mb-1'
            onClick={() => toggleShowAndSave('maxPosSzInput')}
          >
              Max pos size
          </div>
          <div
            className='pl-2 cursor-pointer'
            onClick={() => setShowMaxPosSzInput(true)}
          >
            {
              showMaxPosSzInput ? (
              <input
                id='maxPosSzInput'
                type='number'
                className='input-base'
                value={maxPosSz}
                onChange={(e: BaseSyntheticEvent) => onChangeMaxPosSz(e)}
                onKeyDown={(e: BaseSyntheticEvent) => onKeyPressSaveToFb(e)}
                autoFocus
              />
              ) : ( maxPosSz )
            }%
          </div>
        </div>

        <div className='flex flex-col items-center'>
          <div
            className='font-bold cursor-pointer mb-1'
            onClick={() => {
              toggleShowAndSave('deltaBalHighInput')
              toggleShowAndSave('deltaBalLowInput')}}
          >
            ∆ Balance Range
          </div>
          <div className='cursor-pointer whitespace-nowrap'>
            <span onClick={() => setShowDeltaBalLowInput(true)}>
              {
                showDeltaBalLowInput ? (
                <input
                  id='deltaBalLowInput'
                  type='number'
                  className='input-wide'
                  value={deltaBalLow}
                  onClick={() => setShowDeltaBalLowInput(true)}
                  onChange={(e: BaseSyntheticEvent) => onChangeDeltaBalLow(e)}
                  onKeyDown={(e: BaseSyntheticEvent) => onKeyPressSaveToFb(e)}
                />
                ) : `${deltaBalLow > 0 ? '+' : ''}${toLocalDecimals(deltaBalLow, 2)}`
              }%
            </span>
            <span className='cursor-default whitespace-nowrap px-2'> to </span>
            <span onClick={() => setShowDeltaBalHighInput(true)}>
              {
                showDeltaBalHighInput ? (
                <input
                  id='deltaBalHighInput'
                  type='number'
                  className='input-wide'
                  value={deltaBalHigh}
                  onChange={(e: BaseSyntheticEvent) => onChangeDeltaBalHigh(e)}
                  onKeyDown={(e: BaseSyntheticEvent) => onKeyPressSaveToFb(e)}
                  autoFocus
                />
                ) : `${deltaBalHigh > 0 ? '+' : ''}${toLocalDecimals(deltaBalHigh, 2)}`
              }%
            </span>
          </div>
        </div>

        <div className='flex flex-col items-center'>
          <div
            className='font-bold cursor-pointer mb-1'
            onClick={() => {
              toggleShowAndSave('thetaBalHighInput')
              toggleShowAndSave('thetaBalLowInput')}}
          >
            θ Balance Range
          </div>
          <div className='cursor-pointer whitespace-nowrap'>
            <span onClick={() => setShowThetaBalLowInput(true)}>
              {
                showThetaBalLowInput ? (
                <input
                  id='thetaBalLowInput'
                  type='number'
                  className='input-wide'
                  value={thetaBalLow}
                  onClick={() => setShowThetaBalLowInput(true)}
                  onChange={(e: BaseSyntheticEvent) => onChangeThetaBalLow(e)}
                  onKeyDown={(e: BaseSyntheticEvent) => onKeyPressSaveToFb(e)}
                />
                ) : `${thetaBalLow > 0 ? '+' : ''}${toLocalDecimals(thetaBalLow, 2)}`
              }%
            </span>
            <span className='cursor-default whitespace-nowrap px-1'> to </span>
            <span onClick={() => setShowThetaBalHighInput(true)}>
              {
                showThetaBalHighInput ? (
                <input
                  id='thetaBalHighInput'
                  type='number'
                  className='input-wide'
                  value={thetaBalHigh}
                  onChange={(e: BaseSyntheticEvent) => onChangeThetaBalHigh(e)}
                  onKeyDown={(e: BaseSyntheticEvent) => onKeyPressSaveToFb(e)}
                  autoFocus
                />
                ) : `${thetaBalHigh > 0 ? '+' : ''}${toLocalDecimals(thetaBalHigh, 2)}`
              }%
            </span>
          </div>
        </div>
      </div>
    </CollapseSection>
  )
}

export default PortfolioSettings
