import { FC, useEffect, useState } from 'react'
import { isEmpty, isInteger, map, some, sortBy, toString, toNumber } from 'lodash'
import { OiDataByDate, StrikeData } from '../../types/index'
import { isRealNumber, toLocalDecimals } from '../../utilities/general'
import uuid from 'react-uuid'
import { format, parseISO } from 'date-fns'
import ReactTooltip from 'react-tooltip'
import ToolTipOiInfo from './ToolTipOiInfo'

interface Props {
  dataByDate: OiDataByDate
  maxOiValue: number
  numExpDates: number
}

const OiGraph: FC<Props> = ({ dataByDate, maxOiValue, numExpDates }) => {
  const [oiDataSorted, setOiDataSorted] = useState({})

  const oiData = (dataByDate.data && !dataByDate.data.errmsg) ? dataByDate.data : undefined
  const expDate = toString(dataByDate.date)
  const expDateIso = parseISO(expDate)

  var svgWidth = 50
  if (numExpDates === 6) { svgWidth =  68 } else
  if (numExpDates === 5) { svgWidth =  86 } else
  if (numExpDates < 5)   { svgWidth = 100 }
  const svgHeight = 20
  const barHeight = 10
  const barOffsetY = 7
  const barRadiusY = 6

  const oiBarWidth = (oiValue: number) => {
    return (oiValue / maxOiValue) * svgWidth
  }

  const putOiBarPositionX = (oiValue: number) => {
    return svgWidth - oiBarWidth(oiValue)
  }

  const ttId = (str1: string, str2: string, str3: string) => {
    return `oi-info-${str1}-${str2}-${str3}`
  }

  useEffect(() => {
    if (oiData) {
      const needsDecimals = some(oiData.strike, isInteger)
      var container: StrikeData = {}

      map(oiData?.strike, (strike: number, i: number) => {
        const strikeString = needsDecimals ? toString(toLocalDecimals(strike, 2)) : toString(toLocalDecimals(strike, 0))

        if (!container || isEmpty(container[strikeString])) {
          container[strikeString] = {
            strike: strike,
            strikeString: strikeString
          }
        }
        if (oiData?.side[i] === 'call') {
          container[strikeString].callOi = oiData.openInterest[i]
          container[strikeString].callDelta = Math.abs(oiData.delta[i]) * 100
          container[strikeString].callItm = oiData.inTheMoney[i]
        }
        if (oiData.side[i] === 'put') {
          container[strikeString].putOi = oiData.openInterest[i]
          container[strikeString].putDelta = Math.abs(oiData.delta[i]) * 100
          container[strikeString].putItm = oiData.inTheMoney[i]
        }
      })

      const sortedData = sortBy(container, 'strike').reverse()
      setOiDataSorted(sortedData)
    }
  }, [oiData])


  return (
    <div className='flex flex-col items-center'>
      <div className='font-light text-lg'>
        <span title='Expiration date'>
          {format(new Date(expDateIso), 'MMM d')}
        </span>
        <span className='pl-2' title='DTE'>
          ({oiData && oiData.dte[0]})
        </span>
      </div>

      <table className='mt-6 mb-8'>
        <thead></thead>
        <tbody>
          { oiDataSorted && map(oiDataSorted, (strikeData: StrikeData) => (
            <tr key={uuid()}>

              <td data-tip data-for={ttId('put', toString(strikeData.strike), expDate)} className='w-20 text-right'>

                { isRealNumber(toNumber(strikeData.putOi)) &&
                  <>
                    <svg width={svgWidth} height={svgHeight}>
                      <rect
                        className={`oi-put-bar ${strikeData.putItm ? 'oi-bar-itm' : ''}`}
                        ry={barRadiusY}
                        x={putOiBarPositionX(toNumber(strikeData.putOi))}
                        y={barOffsetY}
                        width={oiBarWidth(toNumber(strikeData.putOi))}
                        height={barHeight}
                      />
                    </svg>

                    <ReactTooltip
                      id={ttId('put', toString(strikeData.strike), expDate)}
                      place='bottom'
                      effect='solid'
                      clickable={true}
                      className='opacity-full'
                    >
                      <ToolTipOiInfo putOrCall='put' strikeData={strikeData} />
                    </ReactTooltip>
                  </>
                }
              </td>


              <td className='text-center font-thin px-2'>
                {strikeData.strikeString}
              </td>


              <td data-tip data-for={ttId('call', toString(strikeData.strike), expDate)} className='w-20'>

              { isRealNumber(toNumber(strikeData.callOi)) &&
                <>
                  <svg width={svgWidth} height={svgHeight}>
                    <rect
                      className={`oi-call-bar ${strikeData.callItm ? 'oi-bar-itm' : ''}`}
                      ry={barRadiusY}
                      y={barOffsetY}
                      width={oiBarWidth(toNumber(strikeData.callOi))}
                      height={barHeight}
                    />
                  </svg>

                  <ReactTooltip
                    id={ttId('call', toString(strikeData.strike), expDate)}
                    place='bottom'
                    effect='solid'
                    clickable={true}
                    className='opacity-full'
                  >
                    <ToolTipOiInfo putOrCall='call' strikeData={strikeData} />
                  </ReactTooltip>
                </>
              }
              </td>

            </tr>
          ))}
        </tbody>
      </table>

    </div>
  )
}

export default OiGraph
