import { isEmpty, map } from 'lodash'
import { FC, useState } from 'react'
import { HiOutlineArrowsRightLeft } from 'react-icons/hi2'
import uuid from 'react-uuid'
import CollapseSection from '../wrappers/CollapseSection'

interface Props {
  tastyOrders: never[]
}

const Orders: FC<Props> = ({ tastyOrders }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const areOrders = isEmpty(tastyOrders) ? false : true

  const collapseTitle = () => {
    return (
      <div className='flex flex-row items-center'>
        <HiOutlineArrowsRightLeft
          className={'action-icons-md mr-3 text-orange'}
        />
        <div>
          Orders
        </div>
      </div>
    )
  }

  return (
    <CollapseSection
      title={ collapseTitle() }
      isExpanded={ isExpanded }
      setIsExpanded={ setIsExpanded }
    >
      {
        !areOrders &&
        <div className='mt-1 ml-2'>No Tasty orders.</div>
      }
      {
        areOrders &&
        <table>
          <thead>
            <tr>
              <th className='tasty-data-table-cell'>Status</th>
              <th className='tasty-data-table-cell'>Symbol</th>
              <th className='tasty-data-table-cell'>Type</th>
              <th className='tasty-data-table-cell'>Price</th>
              <th className='tasty-data-table-cell'>TIF</th>
              <th className='tasty-data-table-cell'>Effect</th>
            </tr>
          </thead>
          <tbody>
            {
              map(tastyOrders, order => (
                <tr key={uuid()}>
                  <td  className='tasty-data-table-cell'>{order['status']}</td>
                  <td  className='tasty-data-table-cell'>{order['underlying-symbol']}</td>
                  <td  className='tasty-data-table-cell'>{order['order-type']}</td>
                  <td  className='tasty-data-table-cell'>{order['price']}</td>
                  <td  className='tasty-data-table-cell'>{order['time-in-force']}</td>
                  <td  className='tasty-data-table-cell'>{order['price-effect']}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      }
    </CollapseSection>
  )
}

export default Orders
