import { FC, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { HiOutlinePlusCircle, HiOutlineTrash } from 'react-icons/hi2'
import ReactTooltip from 'react-tooltip'
import { deleteFromFirebase } from '../../services/api/firebase'
import { RooStratSettings, TickerGroup } from '../../types/rooStrat'
import { plColor } from '../../utilities/general'
import { tickerGroupSummary } from '../../utilities/rooTracker'
import { balanceColor, displayPercent, toLocalDecimals } from '../../utilities/general'
import DndPositions from './DndPositions'
import TenByTenRule from './TenByTenRule'

interface Props {
  toggleShowNewPosForm: any
  group: TickerGroup
  groupKey: number | string
  settings: RooStratSettings
}

const GroupTableHead: FC<Props> = ({ toggleShowNewPosForm, group, groupKey, settings }) => {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [summ, setSumm] = useState(tickerGroupSummary(group))

  const deleteGroup = async () => {
    const groupPath = `rooStratPortfolio/tickerGroups/${groupKey}`
    try {
      await deleteFromFirebase({ refPath: groupPath })
      toast.success('Group deleted.')
    } catch (e) {
      console.error(`GroupTableHead.tsx - deleteGroup() ${e}`)
      toast.error('Group NOT deleted.')
    }
  }

  useEffect(() => {
    setSumm(tickerGroupSummary(group))
  }, [group])

  return (
    <thead>
      <tr>
        <th rowSpan={2} className='col-1-w th-tc border-b'>
          <div className='flex-col items-center'>
            <div>
              {group.ticker}
            </div>
            <HiOutlinePlusCircle
              onClick={toggleShowNewPosForm}
              title='Add position'
              className='action-icons-xl inline-block text-orange mb-1'
            />
          </div>
        </th>
        <th className='col-2-w th-tl'>
          <div className='flex'>
            <div>
              {toLocalDecimals(group.underlyingPrice, 2)}
            </div>
            <div>
              { group.ticker === 'SPY' &&
                <TenByTenRule />
              }
            </div>
          </div>
        </th>
        <th className='col-3-w th-tr'>Qty</th>
        <th className='col-4-w th-tr'>DTE</th>
        <th className='col-5-w th-tr'>Mark</th>
        <th className='col-6-w th-tr'>Price</th>
        <th className='col-7-w th-tr pr-6'>∆</th>
        <th className='col-8-w th-tr pr-4'>θ</th>
        <th className='col-9-w th-tr'>Strike</th>
        <th className='col-10-w th-tr pr-2'>BP Eff</th>
        <th className='col-11-w th-tc'>Tiers</th>
        <th className='col-12-w'>
          <div className='flex justify-center pt-1'>
            <DndPositions spreads={group.spreads || []} groupKey={groupKey} />
            <HiOutlineTrash
              className='action-icons text-danger'
              title={`Delete ${group.ticker}`}
              onClick={() => setShowConfirmDelete(s => !s)}
            />
          </div>
        </th>
      </tr>


      <tr>
        <td
          colSpan={4}
          className='border-b'
          title='Position max profit, actual profit/loss and profit/loss percent.'
        >
          <div className={`whitespace-nowrap text-left pl-1 ${plColor(summ.pl)}`}>
            <p className='inline-block pr-4 dark:text-white'>Pmax {toLocalDecimals(summ.premium)}</p>
            <p className='inline-block pr-4'>P/L {toLocalDecimals(summ.pl)}</p>
            <p className='inline-block '>{displayPercent(summ.plPercent)}</p>
          </div>
        </td>
        <td className='border-b'></td>
        <td className='border-b text-right pr-4'>
          <div data-tip data-for={`group-header-delta-bal-percent-${groupKey}`}>
            {toLocalDecimals(summ.delta, 1)}
          </div>
          <ReactTooltip
            id={`group-header-delta-bal-percent-${groupKey}`}
            place='top'
            effect='solid'
            clickable={true}
            delayShow={300}
            delayHide={350}
            className='opacity-full'
          >
            <div className='whitespace-nowrap text-center text-lg px-4 py-3'>
              <div>
                {group.ticker} ∆ balance
              </div>
              <div className={`${balanceColor(settings, 'delta', summ.deltaBalPercent)}`}>
                {toLocalDecimals(summ.deltaBalPercent, 2)}%
              </div>
            </div>
          </ReactTooltip>
        </td>
        <td className='border-b text-right pr-2'>
          <div data-tip data-for={`group-header-theta-bal-percent-${groupKey}`}>
            {toLocalDecimals(summ.theta, 1)}
          </div>
          <ReactTooltip
            id={`group-header-theta-bal-percent-${groupKey}`}
            place='top'
            effect='solid'
            clickable={true}
            delayShow={300}
            delayHide={350}
            className='opacity-full'
          >
            <div className='whitespace-nowrap text-center text-lg px-4 py-3'>
              <div>
                {group.ticker} θ balance
              </div>
              <div className={`${balanceColor(settings, 'theta', summ.thetaBalPercent)}`}>
                {toLocalDecimals(summ.thetaBalPercent, 2)}%
              </div>
            </div>
          </ReactTooltip>
        </td>
        <td className='border-b'></td>
        <td
          className='border-b text-right pr-2'
          title='All positions total buying power used: max loss.'
        >
          {toLocalDecimals(summ.bp)}
        </td>
        <th className='border-b pb-1'>
          <div className='flex justify-center'>
            <div className='minw1-5'> T </div>
            <div className='minw1-5'> ∆ </div>
            <div className='minw1-5'> DTE </div>
          </div>
        </th>
        <th className='border-b'>
          {showConfirmDelete &&
            <div>
              <span
                className='cursor-pointer text-danger px-2 font-normal'
                title={`Delete ${group.ticker} perminantly`}
                onClick={() => deleteGroup()}
              >
                Yes
              </span>
              <span
                className='cursor-pointer font-normal'
                title='Cancel delete'
                onClick={() => setShowConfirmDelete(false)}
              >
                No
              </span>
            </div>
          }
        </th>
      </tr>
    </thead>
  )
}

export default GroupTableHead
