import { format } from 'date-fns'
import { cloneDeep } from 'lodash'
import { BaseSyntheticEvent, FC, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { HiChevronDoubleDown, HiChevronUp, HiMiniPencilSquare, HiNoSymbol, HiOutlineCheckCircle,
         HiOutlineStar, HiOutlineTrash, HiStar } from 'react-icons/hi2'
import { deleteFromFirebase, setToFirebase } from '../../services/api/firebase'
import { useAppDispatch } from '../../store/hooks'
import { removeEntireDaysNotes, removeNote, setNoteFavorite, setTastyFbTickerNotesGroup, updateNote } from '../../store/userSlice'
import { Note, NoteGroup } from '../../types/tasty'
import { handleError } from '../../utilities/error-handling'
import { isRealNumber } from '../../utilities/general'

interface Props {
  note: Note
  ticker: string
  noteGroup: NoteGroup
  noteGroupKey: number
  noteKey: number
}

const NoteRow: FC<Props> = ({ note, noteGroup, ticker, noteGroupKey, noteKey }) => {
  const [editNote, setEditNote] = useState(false)
  const [isNoteFavorite, setIsNoteFavorite] = useState(noteGroup.notes[noteKey].isFavorite || false)
  const [isCollapsed, setIsCollapsed] = useState(noteGroup.isCollapsed)
  const [showConfirmNoteDelete, setShowConfirmNoteDelete] = useState(false)
  const [showConfirmDayNotesDelete, setShowConfirmDayNotesDelete] = useState(false)
  const [displayRow, setDisplayRow] = useState(true)
  const [isFirstRow, setIsFirstRow] = useState(true)
  const [editNoteText, setEditNoteText] = useState(noteGroup.notes[noteKey].noteText || '')
  const [isMoreThanOneNote, setIsMoreThanOneNote] = useState(true)
  const [showButtons, setShowButtons] = useState(false)

  const dispatch = useAppDispatch()

  const noteDate = noteGroup.notes[noteKey].date
  const date = format(new Date(noteGroup.date), 'M/d')
  const noteTime = isRealNumber(noteDate) ? format(new Date(noteDate * 1000), 'HHmm') : ''
  const noteGroupPath = `tasty/fbTickerGroupsNotes/${ticker}/noteGroups/${noteGroupKey}`
  const notePath = `tasty/fbTickerGroupsNotes/${ticker}/noteGroups/${noteGroupKey}/notes/${noteKey}`

  const toggleEditNote = () => setEditNote(s => !s)

  const toggleCollapse = () => {
    const newVal = !isCollapsed
    let newNoteGroup = cloneDeep(noteGroup)
    newNoteGroup.isCollapsed = newVal
    setIsCollapsed(newVal)
    setToFirebase({ refPath: noteGroupPath, value: newNoteGroup })
    dispatch( setTastyFbTickerNotesGroup({ ticker, noteGroupKey, noteGroup: newNoteGroup }) )
  }

  const toggleIsNoteFavorite = () => {
    try {
      const newValue = !isNoteFavorite
      setIsNoteFavorite(newValue)
      const favoritePath = `${notePath}/isFavorite`
      setToFirebase({ refPath: favoritePath, value: newValue })
      dispatch ( setNoteFavorite( { ticker, noteGroupKey, noteKey, newValue } ) )
      toast.success('Favorite saved.')
    } catch (e) {
      handleError({ msg: 'Favorite NOT saved.', e })
    }
  }

  const cancelEdit = () => {
    setEditNoteText('')
    setEditNote(false)
  }

  const saveNote = () => {
    try {
      const editedNote: Note = {
        ...note,
        date: noteDate,
        noteText: editNoteText
      }
      setToFirebase({ refPath: notePath, value: editedNote })
      dispatch ( updateNote( { ticker, noteGroupKey, noteKey, editedNote } ) )
      setEditNote(false)
      toast.success('Note edited.')
    } catch (e) {
      handleError({ msg: 'Note NOT edited.', e })
    }
  }

  const deleteNote = () => {
    const isLastNote = noteGroup.notes.length === 1 ? true : false
    try {
      if (isLastNote) {
        deleteDayNotes()
      } else {
        deleteFromFirebase({ refPath: notePath })
        dispatch ( removeNote( { ticker, noteGroupKey, noteKey } ) )
        toast.success('Note deleted.')
      }
    } catch (e) {
      handleError({ msg: 'Note NOT deleted.', e })
    }
  }

  const deleteDayNotes = async () => {
    try {
      await deleteFromFirebase({ refPath: noteGroupPath })
      dispatch( removeEntireDaysNotes({ ticker, noteGroupKey }) )
      toast.success("Entire day's notes deleted.")
    } catch (e) {
      handleError({ msg: "Entire day's notes NOT deleted.", e })
    }
  }

  useEffect(() => {
    setDisplayRow(noteKey === 0 || (noteKey && !isCollapsed) ? true : false)
    setIsFirstRow(noteKey === 0)
  }, [isCollapsed, noteKey])

  useEffect(() => {
    setIsMoreThanOneNote(noteGroup.notes.length > 1)
  }, [noteGroup])

  return (
    <>
      {
        displayRow &&
        <div
          className='flex flex-row items-center w-full pl-7'
          onMouseEnter={() => setShowButtons(true)}
          onMouseLeave={() => setShowButtons(false)}
        >

          {
            isFirstRow ? (
              <div className='flex flex-row items-center justify-between w-16'>
                {
                  isMoreThanOneNote ?
                  (
                    isCollapsed ? (
                      <HiChevronDoubleDown
                        className='action-icons'
                        title="Expand day's notes"
                        onClick={() => toggleCollapse()}
                      />
                    ) : (
                      <HiChevronUp
                        className='action-icons'
                        title="Collapse day's notes"
                        onClick={() => toggleCollapse()}
                      />
                    )
                  ) : (<div></div>)
                }
                <div>
                  {date}
                </div>
              </div>
            ) : (
              <div className='w-16'></div>
            )
          }

          <div className='w-12 pl-2 text-right'>
            {noteTime}
          </div>

          <div className='flex flex-row items-center justify-between w-full'>
            <div className='w-full px-2'>
              {
                editNote ? (
                  <input
                    id='editNote'
                    type='text'
                    className='input-full-wide dark:bg-dark-grey'
                    value={editNoteText}
                    onChange={(e: BaseSyntheticEvent) => setEditNoteText(e.target.value)}
                    onKeyDown={(e: BaseSyntheticEvent | any) => { if (e.key === 'Enter') saveNote() }}
                    autoFocus
                  />
                ) : (
                  <div onDoubleClick={() => setEditNote(true)}>
                    {editNoteText}
                  </div>
                )
              }
            </div>

            <div className={`flex flex-row ${showButtons ? '' : 'opacity-0'}`}>
              {
                (!editNote ? (
                <>
                  <HiMiniPencilSquare
                    className='action-icons'
                    title='Edit note'
                    onClick={toggleEditNote}
                  />
                  <HiNoSymbol
                    className='action-icons text-danger'
                    title='Delete note'
                    onClick={() => setShowConfirmNoteDelete(s => !s)}
                  />
                </>
                ) : (
                <>
                  <HiOutlineCheckCircle
                    className='action-icons check-circle-sz text-success'
                    title='Save note'
                    onClick={saveNote}
                  />
                  <HiNoSymbol
                    className='action-icons text-danger'
                    title='Cancel edit'
                    onClick={() => cancelEdit() }
                  />
                </>
                ))
              }
              {
                isFirstRow &&
                <HiOutlineTrash
                  className='action-icons text-danger'
                  title="Delete entire day's notes."
                  onClick={() => setShowConfirmDayNotesDelete(s => !s)}
                />
              }
            </div>

            {
              showConfirmNoteDelete &&
              <div>
                <span
                  className='cursor-pointer text-danger px-2'
                  title='Delete note perminantly.'
                  onClick={() => deleteNote()}
                >
                  Yes
                </span>
                <span
                  className='cursor-pointer pr-1'
                  title='Cancel delete'
                  onClick={() => setShowConfirmNoteDelete(false)}
                >
                  No
                </span>
              </div>
            }
            {
              showConfirmDayNotesDelete &&
              <div>
                <span
                  className='cursor-pointer text-danger px-2'
                  title="Delete entire day's notes perminantly"
                  onClick={() => deleteDayNotes()}
                >
                  Yes
                </span>
                <span
                  className='cursor-pointer pr-1'
                  title='Cancel delete'
                  onClick={() => setShowConfirmDayNotesDelete(false)}
                >
                  No
                </span>
              </div>
            }
          </div>
          <div>
            {
              isNoteFavorite ? (
                <HiStar
                  className='action-icons text-yellow'
                  onClick={toggleIsNoteFavorite}
                />
              ):(
                <HiOutlineStar
                  className='action-icons'
                  onClick={toggleIsNoteFavorite}
                />
              )
            }
          </div>

        </div>
      }
    </>
  )
}

export default NoteRow
