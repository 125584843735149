import { FC } from 'react'
import Logo from '../components/pages-components/Logo'
import UserMenu from '../components/user-settings/UserMenu'

const Header: FC = () => {
  return (
    <div className='px-6 py-4 flex flex-row justify-between items-center bg-header dark:bg-dark-header'>
      <div className='w-24'>
        <Logo size='70' />
      </div>
      <div className='text-4xl tracking-widest whitespace-nowrap font-thin'>
        DRooTracker
      </div>
      <div className='w-24'>
        <UserMenu />
      </div>
    </div>
  )
}

export default Header
