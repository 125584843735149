import { FC, ReactNode, useEffect } from 'react'
import { get, onValue, ref } from 'firebase/database'
import { auth, db } from '../../firebaseAssets'
import { useAppDispatch } from '../../store/hooks'
import { dbSettingsToStore, setJournal, setTasty, setTickerRooStratPortfolio } from '../../store/userSlice'
import { GlobalValues, User } from '../../types/index'
import { format } from 'date-fns'
import { setGlobalValues } from '../../store/appSlice'

export interface Props {
  children: ReactNode
}

const DbToStore: FC<Props> = (props) => {
  const { children } = props
  const dispatch = useAppDispatch()

  useEffect(() => {
    const user = auth.currentUser

    if (user) {
      const globalValuesRef = ref(db, 'globalValues')
      const userRef = ref(db, `users/${user.uid}`)

      get(globalValuesRef)
        .then(snapshot => {
          const globalValues: GlobalValues | null = snapshot.val()
          if (globalValues) {
            if (globalValues) {
              dispatch( setGlobalValues(globalValues) )
            }
          }
        })
        .catch(e => {
          console.log('DbToStore.tsx - get(globalValues) - error: ', e)
        })

      get(userRef)
        .then(snapshot => {
          const userData: User | null = snapshot.val()
          if (userData) {
            if (userData.settings) {
              dispatch( dbSettingsToStore(userData.settings) )
            }
            if (userData.tasty) {
              dispatch( setTasty(userData.tasty) )
            }
            if (userData.journal) {
              const today = format(new Date(), 'MM-dd-yy')
              const todaysJournal = userData.journal[today]
              dispatch( setJournal(todaysJournal) )
            }
            if (userData.rooStratPortfolio) {
              dispatch( setTickerRooStratPortfolio(userData.rooStratPortfolio) )
            }
          }
        })
        .catch(e => {
          console.log('DbToStore.tsx - get(userData) - error: ', e)
        })

      onValue(userRef, snapshot => {
        const userData = snapshot.val()
        if (userData) {
          // console.log('  setting global state ...')
          if (userData.rooStratPortfolio) {
            dispatch( setTickerRooStratPortfolio(userData.rooStratPortfolio) )
          }
        }
      })

    }
  }, [])

  return <>{children}</>
}

export default DbToStore
