import { forEach, isEmpty, toNumber, isNumber, round } from 'lodash'

export const spreadSummary = (spread) => {
  var spreadSum = {}
  if (typeof(spread) === 'undefined' || isEmpty(spread)) {
    return spreadSum
  }

  const isHedge = spread.isHedge
  const spreadType = isHedge ? 'debit' : 'credit'
  const sLeg = spread.shortLeg
  const lLeg = spread.longLeg
  const posContracts = sLeg.contracts ? Math.abs(sLeg.contracts) : undefined
  const isBullish = (!isHedge && sLeg.side.toLowerCase() === 'put') ||
                    ( isHedge && sLeg.side.toLowerCase() === 'call') ? true : false

  // BOTH
  //   spread = Abs(sleg strike - lleg strike)
  if (sLeg.strike && lLeg.strike) {
    spreadSum.spread = Math.abs(sLeg.strike - lLeg.strike)
  }

  // Credit Spreads
  //   premium = sleg price - lleg price
  //   mark = sleg mark - lleg mark
  //   posPremium = premium * contracts * 100
  //   bp = (spread - premium) * contracts * 100
  //   p/l = (premium - mark) * contracts * 100
  if (spreadType === 'credit') {
    if (sLeg.price && lLeg.price) {
      spreadSum.premium = sLeg.price - lLeg.price
    }
    if (sLeg.mark && lLeg.mark) {
      spreadSum.mark = sLeg.mark - lLeg.mark
    }
    if (spreadSum.premium && posContracts) {
      spreadSum.posPremium = spreadSum.premium * posContracts * 100
    }
    if (spreadSum.spread && spreadSum.premium && posContracts) {
      spreadSum.bp = (spreadSum.spread - spreadSum.premium) * posContracts * 100
    }
    if (spreadSum.premium && spreadSum.mark && posContracts) {
      spreadSum.pl = (spreadSum.premium - spreadSum.mark) * posContracts * 100
      if (spreadSum.premium === spreadSum.mark) {
        spreadSum.pl = 0
      }
    }
  }

  // Debit Spreads
  //   premium = lleg price - sleg price
  //   mark = lleg mark - sleg mark
  //   posPremium = (spread - premium) * contracts * 100
  //   bp = premium * contracts * 100
  //   p/l = (mark - premium) * contracts * 100
  else if (spreadType === 'debit') {
    if (sLeg.price && lLeg.price) {
      spreadSum.premium = lLeg.price - sLeg.price
    }
    if (sLeg.mark && lLeg.mark) {
      spreadSum.mark = lLeg.mark - sLeg.mark
    }
    if (spreadSum.spread && spreadSum.premium && posContracts) {
      spreadSum.posPremium = (spreadSum.spread - spreadSum.premium) * posContracts * 100
    }
    if (spreadSum.premium && posContracts) {
      spreadSum.bp = spreadSum.premium * posContracts * 100
    }
    if (spreadSum.premium && spreadSum.mark && posContracts) {
      spreadSum.pl = (spreadSum.mark - spreadSum.premium) * posContracts * 100
      if (spreadSum.premium === spreadSum.mark) {
        spreadSum.pl = 0
      }
    }
  }

  // BOTH - pl%
  //   +pl on Pmax = (pl / posPremium ) * 100
  //   -pl on BP   = (pl / bp ) * 100
  if (spreadSum.mark && spreadSum.premium) {
    spreadSum.plPercent = spreadSum.pl > 0 ?
      (spreadSum.pl / spreadSum.posPremium) * 100 :
      (spreadSum.pl / spreadSum.bp) * 100
    if (spreadSum.premium === spreadSum.mark) {
      spreadSum.plPercent = 0
    }
  }

  // Delta and Theta
  if (sLeg.delta && lLeg.delta && posContracts) {
    spreadSum.delta = Math.abs(sLeg.delta - lLeg.delta) * posContracts * 100
    if (!isBullish) {
      spreadSum.delta = -1 * spreadSum.delta
    }
  }
  if (sLeg.theta && lLeg.theta && posContracts) {
    spreadSum.theta = Math.abs(sLeg.theta - lLeg.theta) * posContracts * 100
    if (isHedge) {
      spreadSum.theta = -1 * spreadSum.theta
    }
  }

  return spreadSum
}

export const tickerGroupSummary = (group) => {
  const spreads = group.spreads
  var totPl = -999999
  var totPremium = -999999
  var totDelta = -999999
  var totTheta = -999999
  var totBp = -999999
  var tickSum = {}

  forEach(spreads, pos => {
    if (pos && !pos.ignore) {
      const spreadSumm = spreadSummary(pos)

      if (spreadSumm.pl) {
        if (totPl === -999999) totPl = 0
        totPl += spreadSumm.pl
      }
      if (spreadSumm.premium) {
        if (totPremium === -999999) totPremium = 0
        totPremium += spreadSumm.posPremium
      }
      if (spreadSumm.delta) {
        if (totDelta === -999999) totDelta = 0
        totDelta += spreadSumm.delta
      }
      if (spreadSumm.theta) {
        if (totTheta === -999999) totTheta = 0
        totTheta += spreadSumm.theta
      }
      if (spreadSumm.bp) {
        if (totBp === -999999) totBp = 0
        totBp += spreadSumm.bp
      }
    }
  })

  if (totPremium > -999999) tickSum.premium = totPremium
  if (totPl > -999999)      tickSum.pl = totPl
  if (totDelta > -999999)   tickSum.delta = totDelta
  if (totTheta > -999999)   tickSum.theta = totTheta
  if (totBp > -999999)      tickSum.bp = totBp
  if (totPl > -999999 && totPremium > -999999) tickSum.plPercent = (totPl / totPremium) * 100
  if (totDelta > -999999 && totBp > -999999)   tickSum.deltaBalPercent = (totDelta / totBp) * 100
  if (totTheta > -999999 && totBp > -999999)   tickSum.thetaBalPercent = (totTheta / totBp) * 100

  return tickSum
}

export const portfolioSummary = (tickerGroups) => {
  var totPl = -999999
  var totPremium = -999999
  var totDelta = -999999
  var totTheta = -999999
  var totBp = -999999
  var summary = {}

  forEach(tickerGroups, group => {
    const groupSumm = tickerGroupSummary({ spreads: group.spreads, butterflies: group.butterflies})

    if (groupSumm.pl) {
      if (totPl === -999999) totPl = 0
      totPl += groupSumm.pl
    }
    if (groupSumm.premium) {
      if (totPremium === -999999) totPremium = 0
      totPremium += groupSumm.premium
    }
    if (groupSumm.delta) {
      if (totDelta === -999999) totDelta = 0
      totDelta += groupSumm.delta
    }
    if (groupSumm.theta) {
      if (totTheta === -999999) totTheta = 0
      totTheta += groupSumm.theta
    }
    if (groupSumm.bp) {
      if (totBp === -999999) totBp = 0
      totBp += groupSumm.bp
    }
  })

  if (totPremium > -999999) summary.premium = totPremium
  if (totPl > -999999)      summary.pl = totPl
  if (totDelta > -999999)   summary.delta = totDelta
  if (totTheta > -999999)   summary.theta = totTheta
  if (totBp > -999999)      summary.bp = totBp
  if (totPl > -999999 && totPremium > -999999) summary.plPercent = (totPl / totPremium) * 100

  return summary
}

export const checkIsValidPosition = ({ sLeg, lLeg, isHedge }) => {
  const finalAnswer = {
    isValid: true,
    errMsg: ''
  }
  if (!sLeg || !lLeg) {
    return finalAnswer
  }

  const sLegSplit = sLeg.split(' ')
  const lLegSplit = lLeg.split(' ')

  const sLegStrike = sLegSplit[3]
  const lLegStrike = lLegSplit[3]
  const side = sLegSplit[4][0].toUpperCase()
  const isBullish = (!isHedge && side === 'P') || (isHedge && side === 'C')

  const isLLegStrikeLessThanSLeg = toNumber(lLegStrike) < toNumber(sLegStrike) ? true : false

  // If isBullish && isLLegStrikeLessThanSLeg => valid
  if (isBullish && !isLLegStrikeLessThanSLeg) {
    finalAnswer.isValid = false
    finalAnswer.errMsg = 'Short strike\n< long strike'
  } else
  // If !isBullish && !isLLegStrikeLessThanSLeg => valid
  if (!isBullish && isLLegStrikeLessThanSLeg) {
    finalAnswer.isValid = false
    finalAnswer.errMsg = 'Short strike\n> long strike'
  }

  return finalAnswer
}
