import { toNumber } from 'lodash'
import { FC, useEffect, useState } from 'react'
import { useAppSelector } from '../../store/hooks'
import { ExtraProps, Spread, SpreadSummary as SpreadSumType } from '../../types/tasty'
import { spreadSummary } from '../../utilities/tastyTracker'
import { deltaBgColor, displayPercent, dteBgColor, plBgColor, posBpTooBigColor, toLocalDecimals } from '../../utilities/general'
import { compressSpreads } from '../../utilities/tasty'
import ReactTooltip from 'react-tooltip'

interface Props {
  spreads: Spread[]
}

const SpreadSummary: FC<Props> = ({ spreads }) => {
  const [summary, setSummary] = useState<SpreadSumType>({})

  const settings = useAppSelector(state => state.userStore.user.tasty.settings)
  const spreadQuotesGreeks = useAppSelector(state => state.userStore.user.tasty.spreadQuotesGreeks)
  const extraProps: ExtraProps = useAppSelector(state => state.userStore.user.tasty.extraProps[spreads[0].fbSpreadQuotesGreeksId])

  const isHedge = spreads[0].isHedge || false
  const isIgnored: boolean = (extraProps && extraProps.ignore) ? extraProps.ignore : false

  const col1Label = 'text-right'
  const col2Value = 'text-right pr-2'
  const col3Label = 'text-right pl-2'
  const col4Label = 'text-right pr-2'
  const col2ValueToolTip = 'text-right pl-3'
  const col3LabelToolTip = 'text-right pl-3'
  const col4LabelToolTip = 'text-right pl-3'

  useEffect(() => {
    if (spreads.length > 0 && spreadQuotesGreeks) {
      const spreadToSummarize = spreads.length === 1 ? spreads[0] : compressSpreads(spreads)[0]
      const sum = spreadSummary(spreadToSummarize, spreadQuotesGreeks)
      setSummary(sum)
    }
  }, [spreads, spreadQuotesGreeks])

  return (
    <div className={`bg-dark-menu w-full min-w-52
                    ${toNumber(summary.pl) > 0 ? 'border-r-2 border-r-success' : 'border-r-2 border-r-danger'}
                    ${isIgnored ? 'opacity-50' : ''}
                    ${isHedge ? 'border-l border-cyan' : ''}
                   `}
    >
      <table className='w-full leading-5'>
        <tbody>
          <tr>
            <td colSpan={4} className='text-center pt-1'>
              {summary.description}
            </td>
          </tr>

          <tr className={`${plBgColor(summary.pl)}`}>
            <td colSpan={4}>
              <div className='flex flex-row justify-center'>
                <div>
                  P/L
                </div>
                <div className='px-3'>
                  {toLocalDecimals(summary.pl)}
                </div>
                <div>
                  {displayPercent(toLocalDecimals(summary.plPercent))}
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td className={`${col1Label} ${deltaBgColor(summary.shortLegDelta)}`}>
              ∆<span className='text-2xs'>slg</span>
            </td>
            <td className={`${col2Value} ${deltaBgColor(summary.shortLegDelta)}`}>
              {summary.shortLegDelta && toLocalDecimals(Math.abs(summary.shortLegDelta))}
            </td>
            <td data-tip data-for={spreads[0].fbSpreadQuotesGreeksId} className={`${col3Label} ${summary.dte && summary.pl && dteBgColor(summary.dte, summary.pl)}`}>
              DTE
            </td>
            <td className={`${col4Label} ${summary.dte && summary.pl && dteBgColor(summary.dte, summary.pl)}`}>
              {toLocalDecimals(summary.dte)}
            </td>
          </tr>

          <tr>
            <td className={`${col1Label}`}>
              ∆<span className='text-2xs'>bal</span>
            </td>
            <td className={`${col2Value}`}>
              {toLocalDecimals(summary.delta)}
            </td>
            <td className={`${col3Label}`}>
              θ<span className='text-2xs'>bal</span>
            </td>
            <td className={`${col4Label}`}>
              {toLocalDecimals(summary.theta)}
            </td>
          </tr>

          <tr>
            <td className={`${col1Label}`}>
              P<span className='text-2xs'>max</span>
            </td>
            <td className={`${col2Value}`}>
              {toLocalDecimals(summary.posPremium)}
            </td>
            <td className={`${col3Label} ${posBpTooBigColor(summary.bp, settings)}`}>
              BP
            </td>
            <td className={`${col4Label} ${posBpTooBigColor(summary.bp, settings)}`}>
              {toLocalDecimals(summary.bp)}
            </td>
          </tr>
        </tbody>
      </table>

      <ReactTooltip
        id={spreads[0].fbSpreadQuotesGreeksId}
        place='top'
        effect='solid'
        clickable={true}
        backgroundColor='#223f4b'
        className='opacity-full'
      >
        <table className='w-full leading-5 text-base mb-2'>
          <tbody>
            <tr>
              <td colSpan={4} className='text-center text-lg py-2'>
                Calculated Values
              </td>
            </tr>

            <tr>
              <td className={`${col1Label} ${deltaBgColor(summary.shortLegDelta)} pl-2`}>
                C-∆<span className='text-2xs'>slg</span>
              </td>
              <td className={`${col2ValueToolTip} ${deltaBgColor(summary.shortLegDeltaCalculated)}`}>
                {summary.shortLegDeltaCalculated && toLocalDecimals(Math.abs(summary.shortLegDeltaCalculated))}
              </td>
              <td className={`${col3LabelToolTip} ${summary.dte && summary.pl && dteBgColor(summary.dte, summary.pl)}`}>
                DTE
              </td>
              <td className={`${col4LabelToolTip} ${summary.dte && summary.pl && dteBgColor(summary.dte, summary.pl)} pr-2`}>
                {toLocalDecimals(summary.dte)}
              </td>
            </tr>

            <tr>
              <td className={`${col1Label} pl-2`}>
                C-∆<span className='text-2xs'>bal</span>
              </td>
              <td className={`${col2ValueToolTip}`}>
                {toLocalDecimals(summary.deltaCalculated)}
              </td>
              <td className={`${col3LabelToolTip}`}>
                C-θ<span className='text-2xs'>bal</span>
              </td>
              <td className={`${col4LabelToolTip} pr-2`}>
                {toLocalDecimals(summary.thetaCalculated)}
              </td>
            </tr>

          </tbody>
        </table>
      </ReactTooltip>
    </div>
  )
}

export default SpreadSummary


  // const spacing = 'px-4'
  // const valueSpacing = 'pl-1'
  // const valueSpace = 'pr-2'
  // const labelSpace = 'pl-2'

    // <div className={`flex flex-col bg-medium-grey border-b-2 border-t ${toNumber(summary.pl) > 0 ? 'border-success' : 'border-danger'}`}>

    //   <div className={`${spacing}`}>{summary.description}</div>

    //   <div className={`flex flex-row ${spacing}`}>
    //     <div>
    //       P/L
    //     </div>
    //     <div className={`${valueSpacing} ${plColor(summary.pl)}`}>
    //       {toLocalDecimals(summary.pl)}
    //     </div>
    //     <div className={`${valueSpacing} ${plColor(summary.pl)}`}>
    //       {displayPercent(toLocalDecimals(summary.plPercent))}
    //     </div>
    //   </div>

    //   <div className={`flex flex-row ${spacing}`}>
    //     <div>
    //       ∆
    //     </div>
    //     <div className={`${valueSpacing}`}>
    //       {toLocalDecimals(summary.delta)}
    //     </div>
    //     <div className='pl-4'>
    //       θ
    //     </div>
    //     <div className={`${valueSpacing}`}>
    //       {toLocalDecimals(summary.theta)}
    //     </div>
    //   </div>

    //   <div className={`flex flex-row ${spacing}`}>
    //     <div>
    //       P<span className='text-2xs'>max</span>
    //     </div>
    //     <div className={`${valueSpacing}`}>
    //       {toLocalDecimals(summary.posPremium)}
    //     </div>
    //     <div className='pl-4'>
    //       BP
    //     </div>
    //     <div className={`${valueSpacing}`}>
    //       {toLocalDecimals(summary.bp)}
    //     </div>
    //   </div>

    // </div>


    // <div className={`flex flex-row bg-medium-grey border-b-2 border-t ${toNumber(summary.pl) > 0 ? 'border-success' : 'border-danger'}`}>

    //   <div className='text-right pl-2'>{summary.description}</div>

    //   <div className={`text-left ${spacing}`}>
    //     P/L
    //   </div>
    //   <div className={`text-right pl-1 ${plColor(summary.pl)}`}>
    //     {toLocalDecimals(summary.pl)}
    //   </div>
    //   <div className={`text-right pl-1 ${plColor(summary.pl)}`}>
    //     {displayPercent(toLocalDecimals(summary.plPercent))}
    //   </div>

    //   <div className={`text-left ${spacing}`}>
    //     ∆
    //   </div>
    //   <div className='text-right pl-1'>
    //     {toLocalDecimals(summary.delta)}
    //   </div>

    //   <div className={`text-left ${spacing}`}>
    //     θ
    //   </div>
    //   <div className='text-right pl-1'>
    //     {toLocalDecimals(summary.theta)}
    //   </div>

    //   <div className={`text-left ${spacing}`}>
    //     P<span className='text-2xs'>max</span>
    //   </div>
    //   <div className='text-right pl-1'>
    //     {toLocalDecimals(summary.posPremium)}
    //   </div>

    //   <div className={`text-left ${spacing}`}>
    //     BP
    //   </div>
    //   <div className='text-right pl-1'>
    //     {toLocalDecimals(summary.bp)}
    //   </div>

    // </div>