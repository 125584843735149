import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth'
import { auth } from '../../firebaseAssets'
import { useNavigate } from 'react-router-dom'

function LoginElipsis() {
  const navigate = useNavigate()

  const onGoogleClick = () => {
    const provider = new GoogleAuthProvider()
    signInWithPopup(auth, provider)
      .then(() => {
        navigate('/')
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return (
    <span className='' onClick={onGoogleClick}>...</span>
  )
}

export default LoginElipsis
