// https://github.com/joeythelantern/React-Firebase-9/blob/main/src/components/AuthRoute.tsx
import { useEffect, useState, FC, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import { auth } from '../../firebaseAssets'
import Spinner from '../pages-components/Spinner'
import { toast } from 'react-hot-toast'


export interface Props {
  children: ReactNode
}

const Authenticate: FC<Props> = (props) => {
  const { children } = props
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    // console.log('Authenticate.tsx - useEffect()')
    const unsubOnAuth = onAuthStateChanged(auth, async user => {
      if (user &&
          (user.uid.substring(0, 9) === 'YEksDHyHB' ||
           user.uid.substring(0, 9) === 'T5tNWrpDt')) {
        // console.log('  user:', user)
        setIsLoading(false)
      } else {
        // console.log('  no user so sending /home ...')
        try {
          await signOut(auth)
          navigate('/home')
        } catch (error) {
          console.log('Authenticate.tsx - logOut() - error:', error)
          toast.error('Logout failed.')
        }
        // signOut(auth)
        // .then(() => {
        //   navigate('/home')
        // })
        // .catch(e => {
        //   console.log('Authenticate.tsx - logOut() - error:', e)
        // })
      }
    })
    return () => unsubOnAuth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return (
      <div className='flex flex-row justify-center items-center pt-20'>
        <Spinner size='4rem' fill='#fb923c' />
      </div>
    )
  }

  return <>{children}</>
}

export default Authenticate
