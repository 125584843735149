import { toNumber } from 'lodash'
import { FC } from 'react'
import { StrikeData } from '../../types'
import { toLocalDecimals } from '../../utilities/general'

interface Props {
  putOrCall: string
  strikeData: StrikeData
}

const ToolTipOiInfo: FC<Props> = ({ putOrCall, strikeData }) => {
  const title = `${putOrCall === 'put' ? 'Put' : 'Call'} Data`
  const oiData = putOrCall === 'put' ? toNumber(strikeData.putOi) : toNumber(strikeData.callOi)
  const deltaData = putOrCall === 'put' ? toNumber(strikeData.putDelta) : toNumber(strikeData.callDelta)
  const isItm = putOrCall === 'put' ? strikeData.putItm : strikeData.callItm

  return (
    <table cellPadding={2} className='text-lg'>
      <thead>
        <tr>
          <th colSpan={2} className='w-24 text-center'>{title}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>OI</td>
          <td className='text-left pl-2'>
            {toLocalDecimals(oiData)}
          </td>
        </tr>
        <tr>
          <td>∆</td>
          <td className='text-left pl-2'>
            {toLocalDecimals(deltaData)}
          </td>
        </tr>
        <tr>
          <td colSpan={2} className='text-center text-cyan tracking-tighter'>
            { isItm ? 'I T M' : ''}
          </td>
        </tr>
      </tbody>
    </table>
)
}

export default ToolTipOiInfo
