import { FC } from 'react'

interface Props {
  bg?: string
  col1Css?: string
  col1Data?: any
  col2Css?: string
  col2Data?: any
  col3Css?: string
  col3Data?: any
  col4Css?: string
  col4Data?: any
  col5Css?: string
  col5Data?: any
  col6Css?: string
  col6Data?: any
  col7Css?: string
  col7Data?: any
  col8Css?: string
  col8Data?: any
  col9Css?: string
  col9Data?: any
}

const SpreadRowTemplate: FC<Props> = ({
  bg = 'dark:bg-dark-menu bg-highlighter',
  col1Css, col1Data,
  col2Css, col2Data,
  col3Css, col3Data,
  col4Css, col4Data,
  col5Css, col5Data,
  col6Css, col6Data,
  col7Css, col7Data,
  col8Css, col8Data,
  col9Css, col9Data,
 }) => {

  col1Css = `col-2a-w ${bg} text-left pl-2 ${col1Css}`
  col2Css = `col-3a-w ${bg} text-right ${col2Css}`
  col3Css = `col-4-w ${bg} text-right ${col3Css}`
  col4Css = `col-5-w ${bg} text-right ${col4Css}`
  col5Css = `col-6-w ${bg} text-right ${col5Css}`
  col6Css = `col-7-w ${bg} text-right ${col6Css}`
  col7Css = `col-7-w ${bg} text-right ${col7Css}`
  col8Css = `col-8-w ${bg} text-right pr-2 ${col8Css}`
  col9Css = `col-8-w ${bg} text-right pr-2 ${col9Css}`

  return (
    <div className='flex flex-row'>
      <div className={col1Css}> {col1Data} </div>
      <div className={col2Css}> {col2Data} </div>
      {/* <div className={col3Css}> {col3Data} </div> */}
      <div className={col4Css}> {col4Data} </div>
      <div className={col5Css}> {col5Data} </div>
      <div className={col6Css}> {col6Data} </div>
      <div className={col7Css}> {col7Data} </div>
      <div className={col8Css}> {col8Data} </div>
      <div className={col9Css}> {col9Data} </div>
    </div>
  )
}

export default SpreadRowTemplate
