import { FC } from 'react'
import toast from 'react-hot-toast'
import { HiFire, HiOutlineEye, HiOutlineEyeSlash, HiOutlineFire, HiOutlineStar, HiStar } from 'react-icons/hi2'
import { setToFirebase } from '../../services/api/firebase'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { updateTastyExtraPropsProp } from '../../store/userSlice'
import { Spread } from '../../types/tasty'
import { handleError } from '../../utilities/error-handling'

interface Props {
  spread: Spread
}

const SpreadButtons: FC<Props> = ({ spread }) => {
  const extraProps = useAppSelector(state => state.userStore.user.tasty.extraProps)
  const id = spread.fbSpreadQuotesGreeksId as string

  const dispatch = useAppDispatch()

  const spreadPath = `tasty/extraProps/${id}`
  const isHot: boolean = extraProps && extraProps[id] && extraProps[id].isHot
  const isFavorite: boolean = extraProps && extraProps[id] && extraProps[id].isFavorite
  const ignore: boolean = extraProps && extraProps[id] && extraProps[id].ignore

  const toggleIgnored = () => {
    try {
      const newValue = extraProps && extraProps[id] ? !extraProps[id].ignore : true
      const ignorePath = `${spreadPath}/ignore`
      setToFirebase({ refPath: ignorePath, value: newValue })
      dispatch( updateTastyExtraPropsProp({ id, update: { ignore: newValue } }) )
      toast.success('Ignore saved.')
    } catch (e) {
      handleError({ msg: 'Ignore NOT saved.', e })
    }
  }

  const toggleIsFavorite = () => {
    try {
      const newValue = extraProps && extraProps[id] ? !extraProps[id].isFavorite : true
      const favPath = `${spreadPath}/isFavorite`
      setToFirebase({ refPath: favPath, value: newValue })
      dispatch( updateTastyExtraPropsProp({ id, update: { isFavorite: newValue } }) )
      toast.success('Favorite saved.')
    } catch (e) {
      handleError({ msg: 'Favorite NOT saved.', e })
    }
  }

  const toggleIsHot = () => {
    try {
      const newValue = extraProps && extraProps[id] ? !extraProps[id].isHot : true
      const favPath = `${spreadPath}/isHot`
      setToFirebase({ refPath: favPath, value: newValue })
      dispatch( updateTastyExtraPropsProp({ id, update: { isHot: newValue } }) )
      toast.success('Hot saved.')
    } catch (e) {
      handleError({ msg: 'Hot NOT saved.', e })
    }
  }

  return (
    <div className='flex flex-col items-center min-w-10'>
      {
        isHot ? (
        <HiFire
          className='action-icons-md text-danger mt-2'
          onClick={toggleIsHot}
        />
      ):(
        <HiOutlineFire
          className='action-icons mt-1'
          onClick={toggleIsHot}
        />
      )}
      {
        isFavorite ? (
        <HiStar
          className='action-icons-md text-yellow mt-1'
          onClick={toggleIsFavorite}
        />
      ):(
        <HiOutlineStar
          className='action-icons mt-1'
          onClick={toggleIsFavorite}
        />
      )}
      {
        ignore ? (
        <HiOutlineEyeSlash
          className='action-icons-md text-danger mt-1'
          onClick={toggleIgnored}
        />
        ):(
        <HiOutlineEye
          className='action-icons mt-1'
          onClick={toggleIgnored}
        />
      )}
    </div>
  )
}

export default SpreadButtons
