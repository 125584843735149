import { ref, set, update, remove, get } from 'firebase/database'
import { auth, db } from '../../firebaseAssets'
import { handleError } from '../../utilities/error-handling'

export const getUserId = () => {
  return auth.currentUser ? auth.currentUser.uid : null
}

const getFbRef = (refPath: string, isRootPath: boolean) => {
  if (!isRootPath) {
    return ref(db, `users/${getUserId()}/${refPath}`)
  }
  return ref(db, refPath)
}

export const getFromFirebase = async (
  {
    refPath,
    isRootPath = false,
  }: {
    refPath: string,
    isRootPath?: boolean,
  }
  ): Promise<any> => {
  const curRef = getFbRef(refPath, isRootPath)
  if (curRef) {
    try {
      const snapshot = await get(curRef)
      if(snapshot.exists()) {
        return snapshot.val()
      } else {
        return null
      }
    } catch (e) {
      handleError({ msg: 'Failed to get data from Firebase.', e })
    }
  }
}

export const setToFirebase = async (
  {
    refPath,
    value,
    isRootPath = false,
  }: {
    refPath: string,
    value: any,
    isRootPath?: boolean,
  }
  ): Promise<void> => {

  const curRef = getFbRef(refPath, isRootPath)
  if (curRef) {
    try {
      await set(curRef, value)
    } catch (e) {
      handleError({ msg: 'Failed to set data to Firebase.', e })
    }
  }
}

export const updateToFirebase = async (
  {
    refPath,
    updates,
    isRootPath = false,
  }: {
    refPath: string,
    updates: {[key: number | string]: number | string},
    isRootPath?: boolean,
  }
  ): Promise<void> => {

  const curRef = getFbRef(refPath, isRootPath)
  if (curRef) {
    try {
      await update(curRef, updates)
    } catch (e) {
      handleError({ msg: 'Failed to update data to Firebase.', e })
    }
  }
}

export const deleteFromFirebase = async (
  {
    refPath,
    isRootPath = false,
  }: {
    refPath: string,
    isRootPath?: boolean,
  }
  ): Promise<void> => {

  const curRef = getFbRef(refPath, isRootPath)
  if (curRef) {
    try {
      await remove(curRef);
    } catch (e) {
      handleError({ msg: 'Failed to delete data from Firebase.', e })
    }
  }
}
