import { FC, useEffect, useState } from 'react'
import uuid from 'react-uuid'
import { Leg, Spread, TastyTickerGroup } from '../../types/tasty'
import { isEmpty, toLower, toNumber } from 'lodash'
import CollapseSection from '../wrappers/CollapseSection'
import TickerGroupSummary from './TickerGroupSummary'
import SpreadSummary from './SpreadSummary'
import SpreadLeg from './SpreadLeg'
import SpreadHeader from './SpreadHeader'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import SpreadButtons from './SpreadButtons'
import Notes from './Notes'
import { getImpliedVolatility } from '../../utilities/greeks/implied-volatility'
import { getDeltaGreek, getThetaGreek } from '../../utilities/greeks/greeks'
import { updateTastySpreadQuotesGreek } from '../../store/userSlice'
import store from '../../store'

interface Props {
  tickerGroup: TastyTickerGroup
}

const TickerGroup: FC<Props> = ({ tickerGroup }) => {
  const [isExpanded, setIsExpanded] = useState(true)

  const plTickerGroups = useAppSelector(state => state.userStore.user.tasty.plTickerGroups)
  const tenYrRate = useAppSelector(state => state.appStore.globalValues.tenYearRate.value)
  const spreadQuotesGreeks = useAppSelector(state => state.userStore.user.tasty.spreadQuotesGreeks)

  // @ts-ignore
  const spreadQuotesGreeksSnapshot = store.getState().userStore.user.tasty.spreadQuotesGreeks

  const dispatch = useAppDispatch()

  const borderColor = () => {
    if (plTickerGroups && plTickerGroups[tickerGroup.ticker]) {
      return plTickerGroups[tickerGroup.ticker] === 0 ? 'border-grey' : plTickerGroups[tickerGroup.ticker] > 0 ? 'border-success' : 'border-danger'
    }
    return 'border-grey'
  }

  const spreads = tickerGroup.spreads || []
  const isSpreads = !isEmpty(spreads)

  const collapseTickerGroupTitle = () => {
    return (
      <div className='flex flex-row items-center'>
        <div className='font-bold'>
          {tickerGroup.ticker}
        </div>
        <TickerGroupSummary tickerGroup={tickerGroup} />
      </div>
    )
  }

  const setCalculatedGreeks = ({ sLeg, lLeg, id }: { sLeg: Leg, lLeg: Leg, id: string }): void => {
    const dte = toNumber(sLeg.dte)
    const sLegContracts = toNumber(sLeg.contracts)
    const lLegContracts = toNumber(lLeg.contracts)
    const sLegStrike = toNumber(sLeg.strike)
    const lLegStrike = toNumber(lLeg.strike)
    const side = toLower(sLeg.side)
    // @ts-ignore
    const sQG = spreadQuotesGreeksSnapshot[id]

    const underlyingPrice = toNumber(sQG.underlyingPrice)
    const sLegMark = toNumber(sQG.sLegMark)
    const lLegMark = toNumber(sQG.lLegMark)
    const { delta: sLegDeltaCalc, theta: sLegThetaCalc } = calcGreeks({ dte, side, underlyingPrice, contracts: sLegContracts, strike: sLegStrike, mark: sLegMark })
    const { delta: lLegDeltaCalc, theta: lLegThetaCalc } = calcGreeks({ dte, side, underlyingPrice, contracts: lLegContracts, strike: lLegStrike, mark: lLegMark })
    const update = {
      sLegDeltaCalculated: sLegDeltaCalc,
      sLegThetaCalculated: sLegThetaCalc,
      lLegDeltaCalculated: lLegDeltaCalc,
      lLegThetaCalculated: lLegThetaCalc,
    }
    dispatch( updateTastySpreadQuotesGreek({ id, update }) )
  }

  const calcGreeks = (
    { dte, side, underlyingPrice, contracts, strike, mark }:
    {
      dte: number,
      side: string,
      underlyingPrice: number,
      contracts: number,
      strike: number,
      mark: number
    }
    ): { delta: number, theta: number } => {

    const finalAnswer = { delta: 0, theta: 0 }
    const s = underlyingPrice
    const k = strike
    const r = tenYrRate / 100
    if (!(r > 0) || !mark || !dte || !s || !k ) {
      return finalAnswer
    }

    const cost = Math.abs(toNumber(mark))
    const t = dte / 365
    const v = getImpliedVolatility(cost, s, k, t, r, side)
    let deltaCalc = Math.abs(getDeltaGreek(s, k, t, v, r, side) * 100)
    let thetaCalc = Math.abs(getThetaGreek(s, k, t, v, r, side) * 100)
    const isShort = contracts && contracts < 0 ? true : false
    deltaCalc = isShort ? -deltaCalc : deltaCalc
    thetaCalc = isShort ? thetaCalc : -thetaCalc

    finalAnswer.delta = deltaCalc
    finalAnswer.theta = thetaCalc

    return finalAnswer
  }

  useEffect(() => {
    Object.values(spreads).map((spreads: Spread[]) => {
      Object.values(spreads).map((spread: Spread) => {
        const id = spread.fbSpreadQuotesGreeksId as string
        if (!spreadQuotesGreeks ||
            !spreadQuotesGreeks[id].sLegDeltaCalculated ||
            !spreadQuotesGreeks[id].lLegDeltaCalculated ||
            !spreadQuotesGreeks[id].sLegThetaCalculated ||
            !spreadQuotesGreeks[id].lLegThetaCalculated
          ) {
          setCalculatedGreeks({ sLeg: spread.shortLeg, lLeg: spread.longLeg, id })
        }
        return null
      })
      return null
    })
  }, [tickerGroup, tenYrRate, spreadQuotesGreeks])

  return (
    <CollapseSection
      title={collapseTickerGroupTitle()}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      heirachyLevel={2}
      classNames={`border-t-4 ${borderColor()}`}
    >
      <SpreadHeader />

      {
        isSpreads &&
        Object.values(spreads).map((spreads: Spread[]) => (
          <div key={uuid()} className='flex flex-row mt-2 mb-4'>
            <SpreadSummary spreads={spreads} />
            <div className='flex flex-col font-thin mt-2'>
              {
                Object.values(spreads).map((spread: Spread, i: number) => (
                  <div key={uuid()} className='flex flex-row'>
                    <div className='mb-1'>
                      <SpreadLeg leg={spread.shortLeg} id={spread.fbSpreadQuotesGreeksId as string} />
                      <SpreadLeg leg={spread.longLeg} id={spread.fbSpreadQuotesGreeksId as string} />
                      {spread.isHedge && <div className='text-cyan mb-2 text-center'>Hedge</div>}
                    </div>
                  </div>
                ))
              }
            </div>
            <div>
              <SpreadButtons spread={spreads[0]} />
            </div>
          </div>
        ))
      }

      <Notes ticker={tickerGroup.ticker} />

    </CollapseSection>
  )
}

export default TickerGroup
