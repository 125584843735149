import { cloneDeep, compact, forEach, isEqual } from 'lodash'
import { BaseSyntheticEvent, FC, useRef } from 'react'
import toast from 'react-hot-toast'
import { HiMiniBars4 } from 'react-icons/hi2'
import ReactTooltip from 'react-tooltip'
import uuid from 'react-uuid'
import { setToFirebase } from '../../services/api/firebase'
import { TickerGroup } from '../../types/rooStrat'

interface Props {
  tickerGroups: TickerGroup[]
}

const DndGroups: FC<Props> = ({ tickerGroups }) => {
  const dragItem = useRef()
  const dragOverItem = useRef()

  const dragStart = (e: BaseSyntheticEvent) => {
    dragItem.current = e.target.id
  }

  const dragEnter = (e: BaseSyntheticEvent, groupKey: number | string) => {
    resetAllHeights()
    dragOverItem.current = e.currentTarget.id
    const spacerEl = document.getElementById(`group-dnd-div-spacer-${groupKey}`)
    if (spacerEl && dragOverItem.current !== dragItem.current) {
      spacerEl.classList.add('height-to-x')
    }
  }

  const resetAllHeights = () => {
    const spacerEls = document.getElementsByClassName('group-dnd-div-spacer')
    if (spacerEls) {
      forEach(spacerEls, el => { el.classList.remove('height-to-x') })
    }
  }

  const groupDrop = async () => {
    var dataCopy = cloneDeep(tickerGroups)
    if (dragItem.current && dragOverItem.current) {
      const dragItemContent = dataCopy[dragItem.current]
      dataCopy.splice(dragItem.current, 1)
      dataCopy.splice(dragOverItem.current, 0, dragItemContent)
    }
    if (isEqual(dataCopy, tickerGroups)) {
      resetAllHeights()
      return
    }
    try {
      dragItem.current = undefined
      dragOverItem.current = undefined
      const groupsPath = `rooStratPortfolio/tickerGroups`
      dataCopy = compact(dataCopy)
      await setToFirebase({ refPath: groupsPath, value: dataCopy })
      toast.success('New ticker group order saved.')
    } catch (e) {
      console.error(`PortfolioSummary.tsx - onClickSaveEdit() ${e}`)
      toast.error('Reorder NOT saved.')
      resetAllHeights()
    }
  }

  return (
    <div className='pl-4'>

      <div data-tip data-for='dnd-groups'>
        <HiMiniBars4
          className='action-icons'
          title='Sort ticker groups.'
          size={19}
        />
      </div>

      <ReactTooltip
        id='dnd-groups'
        place='bottom'
        effect='solid'
        clickable={true}
        delayShow={300}
        delayHide={350}
        className='opacity-full'
      >
        {Object.keys(tickerGroups).map((groupKey: any) => (
          <div key={uuid()}>

            <div
              id={`group-dnd-div-spacer-${groupKey}`}
              className='group-dnd-div-spacer h-1'
            ></div>

            <div
              id={groupKey}
              onDragStart={e => dragStart(e)}
              onDragEnter={e => dragEnter(e, groupKey)}
              onDragEnd={groupDrop}
              draggable
              className='dnd-dd-text cursor-pointer'
            >
              {tickerGroups[groupKey].ticker}
            </div>

          </div>
        ))}
      </ReactTooltip>

    </div>
  )
}

export default DndGroups
