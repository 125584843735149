//  Orders in the sandbox environment all remain within the sandbox system.
//  They never go to a real market.
//  Orders statuses in the sandbox system are all determined by the order type and order price.
//    The logic is as follows:
//    Market orders will be Live and never fill.
//    Day orders with a price <= $3 will fill immediately.
//    Day orders with a price between >= $3 will be Live and never fill.

//  Error responses:
//   {
//     "error": {
//       "code": "not_permitted",
//       "message": "User not permitted access"
//     }
//   }

import TastytradeClient, { MarketDataStreamer } from '@tastytrade/api'
import axios from 'axios'
import { toString } from 'lodash'
import { handleError } from '../../utilities/error-handling'

const API_BASE_URL = toString(process.env.REACT_APP_TASTY_API_BASE_URL)
const STREAMER_BASE_URL = toString(process.env.REACT_APP_TASTY_STREAMER_BASE_URL)

const USERNAME = toString(process.env.REACT_APP_TASTY_API_USERNAME)
const PW = toString(process.env.REACT_APP_TASTY_API_PASSWORD)

const tastyClient = new TastytradeClient(API_BASE_URL, STREAMER_BASE_URL)


export const loginTasty = async () => {
  try {
    return await tastyClient.sessionService.login(USERNAME, PW)
  } catch (e: any) {
    handleError({ msg: 'Tasty login failed.', e })
    console.error(e.response.data.error.message)
    console.log('API_BASE_URL: ', API_BASE_URL)
    console.log('USERNAME: ', USERNAME)
    console.log('PW: ', PW)
  }
}

export const logoutTasty = async () => {
  try {
    return tastyClient.sessionService.logout()
  } catch (e) {
    handleError({ msg: 'Tasty logout failed.', e })
  }
}

export const getAccounts = async () => {
  try {
    return await tastyClient.accountsAndCustomersService.getCustomerAccounts()
  } catch (e) {
    handleError({ msg: 'Could not get Tasty accounts.', e })
  }
}

export const getAccountStreamer = () => {
  return tastyClient.accountStreamer
}

// export const getTastyDataStreamer = () => {
//   return new MarketDataStreamer()
// }

export const connectTastyDataStreamer = async (streamer: MarketDataStreamer) => {
  try {
    const tokenResponse = await tastyClient.accountsAndCustomersService.getApiQuoteToken()
    streamer.connect(tokenResponse['dxlink-url'], tokenResponse.token)
  } catch (e) {
    handleError({ msg: 'Could not connect Tasty quotes streamer.', e })
  }
}

export const getOptionChain = async (ticker: string) => {
  // return await tastyClient.instrumentsService.getNestedOptionChain(ticker)
  return await tastyClient.instrumentsService.getOptionChain(ticker)
}

export const symbolSearch = async (symbol: string) => {
  try {
    return await tastyClient.symbolSearchService.getSymbolData(symbol)
  } catch (e) {
    handleError({ msg: 'Could not get Tasty symbol search.', e })
  }
}

export const getPositions = async (acctNum: string) => {
  try {
    return await tastyClient.balancesAndPositionsService.getPositionsList(acctNum)
  } catch (e) {
    handleError({ msg: 'Could not get Tasty positions.', e })
  }
}

export const getOrders = async (acctNum: string) => {
  try {
    return await tastyClient.orderService.getOrders(acctNum)
  } catch (e) {
    handleError({ msg: 'Could not get Tasty positions.', e })
  }
}

export const getAccountBalanceSnapshot = async (acctNum: string) => {
  try {
    return await tastyClient.balancesAndPositionsService.getBalanceSnapshots(acctNum)
  } catch (e) {
    handleError({ msg: 'Could not get Tasty account balance snapshot.', e })
  }
}

export const getAccountBalances = async (acctNum: string) => {
  try {
    return await tastyClient.balancesAndPositionsService.getAccountBalanceValues(acctNum)
  } catch (e) {
    handleError({ msg: 'Could not get Tasty account balances.', e })
  }
}

export const postOrder = async (
  {
    acctNum,
    orderData,
    sessionToken
  }: {
    acctNum: string,
    orderData: object,
    sessionToken: string
  }) => {
  const apiUrl = `${API_BASE_URL}/accounts/${acctNum}/orders`
  const headers = {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': sessionToken
    }
  }

  try {
    const response = await axios.post(apiUrl, orderData, headers)
    console.log('-->> response: ', response)
    console.log('-->> Order posted successfully:', response.data)
    return response

  } catch (error: any) {
    handleError({ msg: 'Could not post Tasty order.', e: error})
      // Handle errors
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('-->> Response error:', error.response.status, error.response.data)
    } else if (error.request) {
      // The request was made but no response was received
      console.error('-->> No response received:', error.request)
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('-->> Request error:', error.error.message)
    }
  }
}

// Might need loginAxios() if we end up needing a remember-me token.
// export const loginAxios = async () => {
//   console.log('-->> tasty.ts API - login()')
//   const apiUrl = `${API_BASE_URL}/sessions`
//   const info = {
//     'login': USERNAME,
//     'password': PW
//   }
//   const headers = {
//     headers: {
//       'Content-Type': 'application/json',
//       'Accept': 'application/json',
//     }
//   }
//   var response: any

//   try {
//     response = await axios.post(apiUrl, info, headers)
//     console.log('loginAxios() response: ', response)
//     return response
//   } catch (e: any) {
//     handleError({ msg: 'Tasty login failed.', e })
//     console.error(e.response.data.error.message)
//     console.log('API_BASE_URL: ', API_BASE_URL)
//     console.log('USERNAME: ', USERNAME)
//     console.log('PW: ', PW)
//   }
// }
