import { format } from 'date-fns'
import { cloneDeep } from 'lodash'
import { BaseSyntheticEvent, FC, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { HiChevronDoubleDown, HiChevronUp, HiMiniPencilSquare, HiNoSymbol, HiOutlineCheckCircle,
         HiOutlineStar, HiOutlineTrash, HiStar } from 'react-icons/hi2'
import { deleteFromFirebase, setToFirebase } from '../../services/api/firebase'
import { Note, NoteGroup } from '../../types/rooStrat'

interface Props {
  groupKey: number
  noteGroup: NoteGroup
  noteGroupKey: number
  noteKey: number
  noteIndex: number
}

const NoteRow: FC<Props> = ({ noteGroup, groupKey, noteGroupKey, noteKey, noteIndex }) => {
  const [editNote, setEditNote] = useState(false)
  const [isNoteFavorite, setIsNoteFavorite] = useState(noteGroup.notes[noteKey].isFavorite || false)
  const [isCollapsed, setIsCollapsed] = useState(noteGroup.isCollapsed)
  const [showConfirmNoteDelete, setShowConfirmNoteDelete] = useState(false)
  const [showConfirmDayNotesDelete, setShowConfirmDayNotesDelete] = useState(false)
  const [displayRow, setDisplayRow] = useState(true)
  const [isFirstRow, setIsFirstRow] = useState(true)
  const [editNoteText, setEditNoteText] = useState(noteGroup.notes[noteKey].noteText || '')
  const [isMoreThanOneNote, setIsMoreThanOneNote] = useState(true)

  const noteDate = noteGroup.notes[noteKey].date
  const date = format(new Date(noteGroup.date), 'M/d')
  const noteTime = noteDate ? format(new Date(noteDate * 1000), 'HHmm') : ''
  const noteGroupPath = `rooStratPortfolio/tickerGroups/${groupKey}/noteGroups/${noteGroupKey}`
  const notePath = `rooStratPortfolio/tickerGroups/${groupKey}/noteGroups/${noteGroupKey}/notes/${noteKey}`

  const toggleEditNote = () => setEditNote(s => !s)

  const toggleCollapse = () => {
    const newVal = !isCollapsed
    let newNoteGroup = cloneDeep(noteGroup)
    newNoteGroup.isCollapsed = newVal
    setIsCollapsed(newVal)
    setToFirebase({ refPath: noteGroupPath, value: newNoteGroup })
  }

  const toggleIsNoteFavorite = async () => {
    try {
      const newValue = !isNoteFavorite
      setIsNoteFavorite(newValue)
      const favoritePath = `${notePath}/isFavorite`
      await setToFirebase({ refPath: favoritePath, value: newValue })
      toast.success('Favorite saved.')
    } catch (e) {
      toast.error('Favorite NOT saved.')
    }
  }

  const cancelEdit = () => {
    setEditNoteText('')
    setEditNote(false)
  }

  const saveNote = async () => {
    try {
      const newNote: Note = {
        date: noteDate,
        noteText: editNoteText
      }
      await setToFirebase({ refPath: notePath, value: newNote })
      setEditNote(false)
      toast.success('Note saved.')
    } catch (e) {
      console.error(`NoteRow.ts - saveNote() ${e}`)
      toast.error('Note NOT saved.')
    }
  }

  const deleteNote = async () => {
    const isLastNote = noteGroup.notes.length === 1 ? true : false
    try {
      if (isLastNote) {
        await deleteDayNotes()
      } else {
        await deleteFromFirebase({ refPath: notePath })
        toast.success('Note deleted.')
      }
    } catch (e) {
      console.error(`NoteRow.tsx - deleteNote() ${e}`)
      toast.error('Note NOT deleted.')
    }
  }

  const deleteDayNotes = async () => {
    try {
      await deleteFromFirebase({ refPath: noteGroupPath })
      toast.success("Entire day's notes deleted.")
    } catch (e) {
      console.error(`NoteRow.tsx - deleteNote() ${e}`)
      toast.error("Entire day's notes NOT deleted.")
    }
  }

  useEffect(() => {
    setDisplayRow(noteIndex === 0 || (noteIndex && !isCollapsed) ? true : false)
    setIsFirstRow(noteIndex === 0)
  }, [isCollapsed, noteIndex])

  useEffect(() => {
    setIsMoreThanOneNote(noteGroup.notes.length > 1)
  }, [noteGroup])

  return (
    <>
      {displayRow ?
        (<tr>
          {isFirstRow ? (
            <td className='text-right whitespace-nowrap align-top'>
              {date}
              <div className='inline-block minw1-5'>
                {(isFirstRow && isMoreThanOneNote) &&
                  ((isCollapsed ? (
                    <HiChevronDoubleDown
                      className='action-icons inline-block ml-1'
                      title="Expand day's notes"
                      onClick={() => toggleCollapse()}
                      />
                      ) : (
                      <HiChevronUp
                        className='action-icons inline-block ml-1'
                        title="Collapse day's notes"
                      onClick={() => toggleCollapse()}
                      />
                    )
                  ))
                }
              </div>
            </td>
          ) : ( <td></td> )
          }
          <td colSpan={10}>
            <div className='flex'>
              <div className='text-right w-12 minw3 pr-1'>
                {noteTime}
              </div>
              <div className='w-full pl-2'>
                {editNote ? (
                  <input
                    id='editNote'
                    type='text'
                    className='input-full-wide dark:bg-dark-grey'
                    value={editNoteText}
                    onChange={(e: BaseSyntheticEvent) => setEditNoteText(e.target.value)}
                    onKeyDown={(e: BaseSyntheticEvent | any) => { if (e.key === 'Enter') saveNote() }}
                    autoFocus
                  />
                  ) : (
                    <div onDoubleClick={() => setEditNote(true)}>
                      {editNoteText}
                    </div>
                  )
                }
              </div>
            </div>
          </td>

          <td>
            <div className='flex justify-left pl-1'>
              {(!editNote ? (
                <>
                  <HiMiniPencilSquare
                    className='action-icons'
                    title='Edit note'
                    onClick={toggleEditNote}
                    />
                  <HiNoSymbol
                    className='action-icons text-danger'
                    title='Delete note'
                    onClick={() => setShowConfirmNoteDelete(s => !s)}
                    />
                </>
                ) : (
                <>
                  <HiOutlineCheckCircle
                    className='action-icons check-circle-sz text-success'
                    title='Save note'
                    onClick={saveNote}
                    />
                  <HiNoSymbol
                    className='action-icons text-danger'
                    title='Cancel edit'
                    onClick={() => cancelEdit() }
                    />
                </>
                )
              )}
              {isNoteFavorite ? (
                <HiStar
                  className='action-icons text-yellow'
                  onClick={toggleIsNoteFavorite}
                />
              ):(
                <HiOutlineStar
                  className='action-icons'
                  onClick={toggleIsNoteFavorite}
                />
              )}
              {isFirstRow &&
                <HiOutlineTrash
                  className='action-icons text-danger'
                  title="Delete entire day's notes."
                  onClick={() => setShowConfirmDayNotesDelete(s => !s)}
                />
              }
            </div>
            {showConfirmNoteDelete &&
              <div>
                <span
                  className='cursor-pointer text-danger px-2'
                  title='Delete note perminantly.'
                  onClick={() => deleteNote()}
                  >
                  Yes
                </span>
                <span
                  className='cursor-pointer'
                  title='Cancel delete'
                  onClick={() => setShowConfirmNoteDelete(false)}
                  >
                  No
                </span>
              </div>
            }
            {showConfirmDayNotesDelete &&
              <div>
                <span
                  className='cursor-pointer text-danger px-2'
                  title="Delete entire day's notes perminantly"
                  onClick={() => deleteDayNotes()}
                  >
                  Yes
                </span>
                <span
                  className='cursor-pointer'
                  title='Cancel delete'
                  onClick={() => setShowConfirmDayNotesDelete(false)}
                  >
                  No
                </span>
              </div>
            }
          </td>
        </tr>
        ) : ( null )
      }
    </>
  )
}

export default NoteRow
