import { FC, ReactNode } from 'react'
import { HiChevronRight, HiChevronDown } from 'react-icons/hi2'

interface Props {
  title: any
  titleExtra?: any
  isExpanded: boolean
  setIsExpanded: any
  children: ReactNode
  heirachyLevel?: number
  classNames?: string
}

const CollapseSection: FC<Props> = ({
  title,
  titleExtra = '',
  children,
  isExpanded,
  setIsExpanded,
  heirachyLevel = 1,
  classNames,
}) => {

  var wrapperClasses = 'pt-2 mt-4 border-t border-medium-grey'
  var textClasses = 'text-xl font-bold'

  if (heirachyLevel === 2) {
    wrapperClasses = 'mt-8'
    textClasses = 'text-xl'
  }

  return (
    <div className={`${wrapperClasses}`}>

      <div className={`flex flex-row cursor-pointer items-center mb-2 ${textClasses} ${classNames}`} >

        <div className={`flex flex-row items-center`}
          onClick={() => setIsExpanded(!isExpanded)}
        >

          { isExpanded ? ( <HiChevronDown /> ) : ( <HiChevronRight /> ) }
          <div className='pl-2 whitespace-nowrap'> { title } </div>

        </div>

        <div className='w-full'> { titleExtra } </div>

      </div>

      { isExpanded &&
        <div> { children } </div>
      }

    </div>
  )
}

export default CollapseSection
