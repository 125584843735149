import { FC } from 'react'
import { FaSpinner } from 'react-icons/fa'

const SpinnerTastyLeg: FC = () => {
  return (
    <div className='flex justify-end pr-1 pt-2'>
      <div className='animate-spin'>
        <FaSpinner size='0.7rem' fill='grey' />
      </div>
    </div>
  )
}

export default SpinnerTastyLeg
