import { User } from './types/index'

export const newUserFbData: User = {
  settings: {
    isDark: false,
  },
  tasty: {
    settings: {
      accountEquity: 10000,
      maxPositionSize: 0.05,
      deltaBalanceLow: -0.0025,
      deltaBalanceHigh: 0.0025,
      thetaBalanceLow: 0.0025,
      thetaBalanceHigh: 0.0075,
    },
    login: {},
    positions: [],
    tickerGroups: {},
    plTickerGroups: {},
  },
  rooStratPortfolio: {
    settings: {
      accountEquity: 10000,
      maxPositionSize: 0.05,
      deltaBalanceLow: -0.0025,
      deltaBalanceHigh: 0.0025,
      tosTotalDelta: 0,
      thetaBalanceLow: 0.0025,
      thetaBalanceHigh: 0.0075,
      tosTotalTheta: 0,
      tosTotalBp: 0
    }
  },
}
