import { FC, useState } from 'react'
import { JournalEntry } from '../../types'
import uuid from 'react-uuid'
import { HiChevronDoubleDown, HiChevronUp } from 'react-icons/hi2'
import NewEntry from './NewEntry'
import EditEntry from './EditEntry'

interface Props {
  preOrPost: string
  isCollapsed: boolean
  setCollapsed: () => void
  entries: JournalEntry[]
  onSave: (type:string, title:string, note:string, id:string) => void
  onSaveEdit: (id:string, note: string, idx:number) => void
  onDelete: (id:string, idx:number) => void
}

const PreAndPostMarket: FC<Props> = ({ preOrPost, entries, isCollapsed, setCollapsed, onSave, onSaveEdit, onDelete }) => {
  const [editingNote, setEditingNote] = useState(false)
  const [editingId, setEditingId] = useState('')
  const [editingNoteIdx, setEditingNoteIdx] = useState<number|null>()

  const onSaveEntry= (title:string, note:string, id:string) => {
    if (note.match(/^\s*$/)) {
      return
    }
    onSave(preOrPost, title, note, id)
  }

  const clickToEdit = (id:string, note:string, idx:number) => {
    setEditingNote(true)
    setEditingId(id)
    setEditingNoteIdx(idx)
  }

  const onSaveEditEntry = (id: string, note:string, idx:number) => {
    if (note.match(/^\s*$/)) {
      return
    }
    onSaveEdit(id, note, idx)
    setEditingNote(false)
    setEditingId('')
    setEditingNoteIdx(null)
  }

  const cancelEdit = () => {
    setEditingNote(false)
    setEditingId('')
    setEditingNoteIdx(null)
  }

  const onDeleteEntry = (id:string, idx:number) => {
    onDelete(id, idx)
    setEditingNote(false)
    setEditingId('')
    setEditingNoteIdx(null)
  }

  const colorVariants:any = {
    premarket: 'dark:text-premarket text-premarket-muted',
    postmarket: 'dark:text-postmarket text-postmarket-muted'
  }

  return (
    <>
      <div className='pr-4 pl-4 pb-2 mt-2 mb-4 flex-grow border dark:border-[#ffffff35] border-black dark:bg-gradient-to-b dark:from-[#ffffff10]'>
        <div className='flex flex-inline justify-between'>

          <div className="w-20 items-center justify-start"></div>

          <div onClick={() => setCollapsed()} className='flex flex-inline items-center'>
            <h2 className={`journal-title pt-2 pb-1 ${colorVariants[preOrPost]}`}>
              {preOrPost === 'premarket' ? 'Pre-Market' : 'Post-Market'}
            </h2>
            {isCollapsed ? (
              <HiChevronDoubleDown
                className={`action-icons pt-1 pl-1 ${colorVariants[preOrPost]}`}
                title="Expand day's notes"
                />
            ) : (
              <HiChevronUp
                title="Collapse day's notes"
                className={`action-icons pt-1 pl-1 ${colorVariants[preOrPost]}`}
              />
            )}
          </div>

          <div className='w-20 flex items-center justify-end'>
            {/* <HiMiniPencilSquare
              title='Edit Pre-Market Notes'
              className={`action-icons mr-2 ${colorVariants[preOrPost]}`}
              onClick={() => openEditor()}
             /> */}
            {/* <HiOutlineDocumentDuplicate
              title='Copy Previous Days Notes'
              className={`action-icons mr-2 ${colorVariants[preOrPost]}`}
              onClick={() => copyPrev()}
             /> */}
          </div>

        </div>

        <div className={`
          transition-all duration-300 ${isCollapsed
            ? 'max-h-0 overflow-hidden opacity-0'
            : 'max-h-screen overflow-auto'}
          `}>

          {entries && entries.map(entry => {
            return (
              <div key={uuid()} className='pb-1 mt-2 mb-2'>
                <div className='flex flex-inline items-center mb-1 mt-4 ml-1'>
                  <h4 className={`text-lg ${colorVariants[preOrPost]}`}>{entry.title}</h4>
                </div>

                <ul className='mt-1 mb-4 ml-1 mr-1'>
                    {entry && entry?.notes && entry.notes.length > 0 ? (
                      entry.notes.map((note:string, idx:number) => {
                        return (
                          <div key={uuid()}>
                            {editingNote && editingId === entry.id && idx === editingNoteIdx ? (
                              <EditEntry
                                note={note}
                                id={entry.id || uuid()}
                                idx={idx}
                                onSaveEdit={(id:string, note:string, idx:number) => onSaveEditEntry(id, note, idx)}
                                onCancelEdit={() => cancelEdit()}
                                onDelete={(id:string, idx:number) => onDeleteEntry(id, idx)}
                              />
                            ) : (
                              <li
                                key={uuid()}
                                className='text-base font-extralight mb-2 pl-1 text-wrap whitespace-pre-wrap'
                                onDoubleClick={() => clickToEdit(entry.id, note, idx)}
                              >
                                {note}
                              </li>
                            )}
                          </div>
                        )
                      })
                    ) : (
                      <NewEntry
                        entry={entry}
                        id={entry.id !== '' ? entry.id : uuid()}
                        onSave={(title:string, note:string, id) => onSaveEntry(title, note, id)}
                      />
                    )
                  }
                </ul>
              </div>
            )
          })}

        </div>
      </div>
    </>
  )
}

export default PreAndPostMarket
