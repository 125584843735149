import { FC, useEffect, useState } from 'react'
import { PortfolioSummary as PortSum, TastyTickerGroups } from '../../types/tasty'
import { balanceColor, displayPercent, toLocalDecimals } from '../../utilities/general'
import { plColor } from '../../utilities/general'
import { useAppSelector } from '../../store/hooks'
import { portfolioSummary } from '../../utilities/tastyTracker'

interface Props {
  tickerGroups: TastyTickerGroups
}

const PortfolioSummary: FC<Props> = ({ tickerGroups }) => {
  const [summary, setSummary] = useState<PortSum>({})

  const settings = useAppSelector(state => state.userStore.user.tasty.settings)
  const spreadQuotesGreeks = useAppSelector(state => state.userStore.user.tasty.spreadQuotesGreeks)
  const extraProps = useAppSelector(state => state.userStore.user.tasty.extraProps)

  useEffect(() => {
    if (tickerGroups && spreadQuotesGreeks) {
      const sum = portfolioSummary(tickerGroups, spreadQuotesGreeks, extraProps)
      setSummary(sum)
    }
  }, [tickerGroups, spreadQuotesGreeks, extraProps])

  return (
    <>
      <table className='ml-auto mr-auto cursor-default pos-font mt-3'>
        <thead></thead>
        <tbody>
          <tr>
            <td className='text-right font-bold text-xl' title='Portfolio ∆ balance percent.'>
              ∆
            </td>
            <td
              className={`text-right font-bold text-xl pl-2 ${balanceColor(settings, 'delta', summary.deltaBalPercent)}`}
              title='Portfolio ∆ balance percent.'
            >
              { displayPercent(toLocalDecimals(summary.deltaBalPercent, 2), 2) }
            </td>
            <td
              className='text-right align-middle pl-10'
              title='Portfolio total ∆.'
            >
              Σ∆
            </td>
            <td
              className='text-right pl-2 align-middle'
              title='Portfolio total ∆.'
            >
              {toLocalDecimals(summary.delta)}
            </td>

            <td></td>

            <td
              className='text-right font-bold text-lg border-l border-l-dark-grey'
              title='Calculated ∆ balance percent.'
            >
              ∆c
            </td>
            <td
              className={`text-right font-bold text-lg align-middle pl-2 ${balanceColor(settings, 'delta', summary.deltaCalculatedBalPercent)}`}
              title='Calculated ∆ balance percent.'
            >
              { displayPercent(toLocalDecimals(summary.deltaCalculatedBalPercent, 2), 2) }
            </td>
            <td
              className='text-right pl-10 align-middle'
              title='Calculated total ∆.'
            >
                Σ∆
            </td>
            <td
              className='text-right pl-2 align-middle'
              title='Calculated total ∆.'
            >
              {toLocalDecimals(summary.deltaCalculated)}
            </td>
          </tr>


          <tr>
            <td className='text-right font-bold text-xl' title='Portfolio θ balance percent.'>
              θ
            </td>
            <td
              className={`text-right font-bold text-xl pl-2 ${balanceColor(settings, 'theta', summary.thetaBalPercent)}`}
              title='Portfolio θ balance percent.'
            >
              { displayPercent(toLocalDecimals(summary.thetaBalPercent, 2), 2) }
            </td>
            <td
              className='text-right align-middle'
              title='Portfolio total θ.'
            >
              Σθ
            </td>
            <td
              className='text-right pl-2 align-middle'
              title='Portfolio total θ.'
            >
              {toLocalDecimals(summary.theta)}
            </td>

            <td className='min-w-10'></td>

            <td
              className='text-right font-bold text-lg min-w-16 border-l border-l-dark-grey'
              title='Calculated θ balance percent.'
            >
              θc
            </td>
            <td
              className={`text-right font-bold text-lg align-middle pl-2 ${balanceColor(settings, 'theta', summary.thetaCalculated)}`}
              title='Calculated θ balance percent.'
            >
              { displayPercent(toLocalDecimals(summary.thetaCalculatedBalPercent, 2), 2) }
            </td>
            <td
              className='text-right align-middle'
              title='Calculated total θ.'
            >
              Σθ
            </td>
            <td
              className='text-right pl-2 align-middle'
              title='Calculated total θ.'
            >
              {toLocalDecimals(summary.thetaCalculated)}
            </td>
          </tr>

        </tbody>
      </table>

      <div className='flex flex-row mt-6 text-lg'>
        <div className={`${plColor(summary.pl)}`}>
          <p className='inline-block pl-3'>P/L</p>
          <p className='inline-block pl-2'>{toLocalDecimals(summary.pl)}</p>
          <p className='inline-block pl-2'>{displayPercent(toLocalDecimals(summary.plPercent))}</p>
        </div>
        <p className='inline-block pl-6'>P<span className='text-xs'>max</span></p>
        <p className='inline-block pl-2'>{toLocalDecimals(summary.premium)}</p>
        <p className='inline-block pl-6'>ΣBP</p>
        <p className='inline-block pl-2'>{toLocalDecimals(summary.bp)}</p>
      </div>
    </>

  )
}

export default PortfolioSummary
