import { BaseSyntheticEvent, FC, useState } from 'react'
import { HiOutlineCheckCircle } from 'react-icons/hi2'
import { format } from 'date-fns'
import toast from 'react-hot-toast'
import { setToFirebase } from '../../services/api/firebase'
import { Note, NoteGroup } from '../../types/rooStrat'
import { cloneDeep, compact, isEmpty, round } from 'lodash'

interface Props {
  noteGroups: NoteGroup[] | undefined
  groupKey: number
}

const NoteNew: FC<Props> = ({ noteGroups, groupKey }) => {
  const [newNoteText, setNewNoteText] = useState('')

  const today = format(new Date(), 'MM/dd/yy')
  const epochTime = round(Date.now()/1000)

  const genNewNoteGroup = (): NoteGroup => {
    return {
      date: today,
      notes: [genNewNote()]
    }
  }

  const genNewNote = (): Note => {
    return {
      date: epochTime,
      noteText: newNoteText
    }
  }

  const saveNewNote = async () => {
    if (newNoteText) {
      try {
        let newNoteGroups = compact(cloneDeep(noteGroups)) || []
        const existingNoteGroup = newNoteGroups.find(noteGroup => noteGroup.date === today)

        if (existingNoteGroup) {
          if (isEmpty(existingNoteGroup.notes)) {
            existingNoteGroup.notes = []
          }
          existingNoteGroup.notes = compact(existingNoteGroup.notes)
          existingNoteGroup.notes.unshift(genNewNote())
        } else {
          newNoteGroups.unshift(genNewNoteGroup())
        }

        const path = `rooStratPortfolio/tickerGroups/${groupKey}/noteGroups`
        newNoteGroups.forEach(noteObj => {
          if (!isEmpty(noteObj.notes)) noteObj.notes = compact(noteObj.notes)
        })

        await setToFirebase({ refPath: path, value: newNoteGroups })
        toast.success('Note saved.')

      } catch (e) {
        console.error(`NoteNew.ts - saveNewNote() ${e}`)
        toast.error('Note NOT saved.')
      }
    }
  }

  return (
    <tr>

      <td className='font-bold pl-2 whitespace-nowrap'>Notes</td>

      <td colSpan={10} className='whitespace-nowrap'>
        <input
          id='newNote'
          type='text'
          className='input-full-wide border dark:border-0'
          value={newNoteText}
          onChange={(e: BaseSyntheticEvent) => setNewNoteText(e.target.value)}
          onKeyDown={(e: BaseSyntheticEvent | any) => { if (e.key === 'Enter') saveNewNote() }}
        />

        { newNoteText &&
          <HiOutlineCheckCircle
            onClick={saveNewNote}
            className='action-icons check-circle-sz inline-block text-success ml-2'
          />
        }
      </td>

    </tr>
  )
}

export default NoteNew
