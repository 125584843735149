import { FC, useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { updateTastyPlTickerGroups } from '../../store/userSlice'
import { TastyTickerGroup, TickerGroupSummary as TickerGroupSumType } from '../../types/tasty'
import { balanceColor, displayPercent, isRealNumber, plColor, toLocalDecimals } from '../../utilities/general'
import { tickerGroupSummary } from '../../utilities/tastyTracker'

interface Props {
  tickerGroup: TastyTickerGroup
}

const TickerGroupSummary: FC<Props> = ({ tickerGroup }) => {
  const [summary, setSummary] = useState<TickerGroupSumType>({})
  const [tickerPrice, setTickerPrice] = useState<number>()

  const dispatch = useAppDispatch()

  const settings = useAppSelector(state => state.userStore.user.tasty.settings)
  const tickerPrices = useAppSelector(state => state.appStore.globalValues.tickerPrices)
  const spreadQuotesGreeks = useAppSelector(state => state.userStore.user.tasty.spreadQuotesGreeks)
  const extraProps = useAppSelector(state => state.userStore.user.tasty.extraProps)

  const ticker = tickerGroup.ticker

  const pairSpacing = 'pl-5'
  const valueSpacing = 'pl-2'

  useEffect(() => {
    const val = summary.pl
    if (ticker && val) {
      dispatch( updateTastyPlTickerGroups({ [ticker]: val }) )
    }
  }, [summary])

  useEffect(() => {
    if (tickerGroup && spreadQuotesGreeks) {
      const sum = tickerGroupSummary(tickerGroup, spreadQuotesGreeks, extraProps)
      setSummary(sum)
    }
  }, [tickerGroup, spreadQuotesGreeks, extraProps])

  useEffect(() => {
    if (tickerPrices && tickerPrices[ticker]) {
      setTickerPrice( tickerPrices[ticker] )
    }
  }, [tickerPrices])

  return (
    <>
      <div className='flex flex-row text-lg'>
        <div className='pl-6 text-xl w-24'>
          {isRealNumber(tickerPrice) ? toLocalDecimals(tickerPrice, 2) : ''}
        </div>

        <div className={`text-left ${pairSpacing}`}>
          P/L
        </div>
        <div className={`text-right ${valueSpacing} ${plColor(summary.pl)}`}>
          {toLocalDecimals(summary.pl)}
        </div>
        <div className={`text-right ${valueSpacing} ${plColor(summary.pl)}`}>
          {displayPercent(toLocalDecimals(summary.plPercent))}
        </div>

        <div className={`text-left ${pairSpacing}`}>
          ∆
        </div>
        <div className={`text-right ${valueSpacing}`}>
          {toLocalDecimals(summary.delta, 1)}
        </div>
        <div className={`text-right ${valueSpacing} ${balanceColor(settings, 'delta', summary.deltaBalPercent)}`}>
          {displayPercent(summary.deltaBalPercent, 2)}
        </div>

        <div data-tip data-for={tickerGroup.ticker} className={`text-left ${pairSpacing}`}>
          θ
        </div>
        <div className={`text-right ${valueSpacing}`}>
          {toLocalDecimals(summary.theta, 1)}
        </div>
        <div className={`text-right ${valueSpacing} ${balanceColor(settings, 'theta', summary.thetaBalPercent)}`}>
          {displayPercent(summary.thetaBalPercent, 2)}
        </div>

        <div className={`text-left ${pairSpacing}`}>
          P<span className='text-2xs'>max</span>
        </div>
        <div className={`text-right ${valueSpacing}`}>
          {toLocalDecimals(summary.premium)}
        </div>

        <div className={`text-left ${pairSpacing}`}>
          BP
        </div>
        <div className={`text-right ${valueSpacing}`}>
          {toLocalDecimals(summary.bp)}
        </div>
      </div>

      <ReactTooltip
        id={tickerGroup.ticker}
        place='top'
        effect='solid'
        clickable={true}
        backgroundColor='#223f4b'
        className='opacity-full'
      >
        <div className='flex flex-row justify-center text-lg'>
          <div> Calculated Values </div>
        </div>
        <div className='flex flex-row text-lg'>
          <div className='text-left'>
            C-∆
          </div>
          <div className={`text-right ${valueSpacing}`}>
            {toLocalDecimals(summary.deltaCalculated, 1)}
          </div>
          <div className={`text-right ${valueSpacing} ${balanceColor(settings, 'delta', summary.deltaCalculatedBalPercent)}`}>
            {displayPercent(summary.deltaCalculatedBalPercent, 2)}
          </div>

          <div data-tip data-for={tickerGroup.ticker} className={`text-left ${pairSpacing}`}>
            C-θ
          </div>
          <div className={`text-right ${valueSpacing}`}>
            {toLocalDecimals(summary.thetaCalculated, 1)}
          </div>
          <div className={`text-right ${valueSpacing} ${balanceColor(settings, 'theta', summary.thetaCalculatedBalPercent)}`}>
            {displayPercent(summary.thetaCalculatedBalPercent, 2)}
          </div>
        </div>
      </ReactTooltip>
    </>
  )
}

export default TickerGroupSummary
