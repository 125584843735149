import { FC } from 'react'
import { useAppSelector } from '../../store/hooks'
import { ExtraProps, Leg } from '../../types/tasty'
import { toLocalDecimals } from '../../utilities/general'
import SpinnerTastyLeg from '../pages-components/SpinnerTastyLeg'
import SpreadRowTemplate from './SpreadRowTemplate'

interface Props {
  leg: Leg,
  id: string
}

const SpreadLeg: FC<Props> = ({ leg, id }) => {
  // @ts-ignore
  const spreadQuotesGreeks = useAppSelector(state => state.userStore.user.tasty.spreadQuotesGreeks[id])
  const extraProps: ExtraProps = useAppSelector(state => state.userStore.user.tasty.extraProps[id])

  const isIgnored: boolean = (extraProps && extraProps.ignore) ? extraProps.ignore : false
  const isShort = leg.contracts && leg.contracts < 0

  const deltaCalculated = isShort ? spreadQuotesGreeks.sLegDeltaCalculated : spreadQuotesGreeks.lLegDeltaCalculated
  const thetaCalculated = isShort ? spreadQuotesGreeks.sLegThetaCalculated : spreadQuotesGreeks.lLegThetaCalculated
  const delta = isShort ? spreadQuotesGreeks.sLegDelta : spreadQuotesGreeks.lLegDelta
  const theta = isShort ? spreadQuotesGreeks.sLegTheta : spreadQuotesGreeks.lLegTheta
  const mark = isShort ? spreadQuotesGreeks.sLegMark : spreadQuotesGreeks.lLegMark

  return (
    <div className={`${isIgnored ? 'opacity-50' : ''}`}>
      <SpreadRowTemplate
        col1Css=''
        col1Data={leg.occDisplay}

        col2Css='pr-1'
        col2Data={leg.contracts}

        // col3Css='pr-1'
        // col3Data={dte}

        col4Css='pr-1'
        col4Data={toLocalDecimals(mark, 2) || <SpinnerTastyLeg />}

        col5Css='pr-1'
        col5Data={toLocalDecimals(leg.price, 2) || <SpinnerTastyLeg />}

        col6Css='pr-1'
        col6Data={toLocalDecimals(delta, 1) || <SpinnerTastyLeg />}

        col7Css='pr-1'
        col7Data={toLocalDecimals(deltaCalculated, 1) || <SpinnerTastyLeg />}

        col8Css='pr-1'
        col8Data={toLocalDecimals(theta, 1) || <SpinnerTastyLeg />}

        col9Css='pr-1'
        col9Data={toLocalDecimals(thetaCalculated, 1) || <SpinnerTastyLeg />}
      />
    </div>
  )
}

export default SpreadLeg
