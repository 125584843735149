import { initializeApp } from "firebase/app"

// const firebaseConfig = {
//   apiKey: "AIzaSyB6rYI1RGRYzV8GTIlXSqXXsD7J_EjGnBE",
//   authDomain: "drootracker.firebaseapp.com",
//   projectId: "drootracker",
//   storageBucket: "drootracker.appspot.com",
//   messagingSenderId: "56853778097",
//   appId: "1:56853778097:web:8aa83ae85cecbbc0cf9c90"
// }

const firebaseConfig = {
  apiKey: "AIzaSyB6rYI1RGRYzV8GTIlXSqXXsD7J_EjGnBE",
  authDomain: "drootracker.firebaseapp.com",
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  projectId: "drootracker",
  storageBucket: "drootracker.appspot.com",
  messagingSenderId: "56853778097",
  appId: "1:56853778097:web:8aa83ae85cecbbc0cf9c90"
}

// const firebaseConfigDev = {
//   databaseURL: "https://drootracker-dev.firebaseio.com",
// }

initializeApp(firebaseConfig)