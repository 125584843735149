import { BaseSyntheticEvent, FC, useState } from 'react'
import { HiOutlineCheckCircle } from 'react-icons/hi2'
import { format } from 'date-fns'
import toast from 'react-hot-toast'
import { setToFirebase } from '../../services/api/firebase'
import { Note, NoteGroup } from '../../types/tasty'
import { cloneDeep, compact, isEmpty, round } from 'lodash'
import { useAppDispatch } from '../../store/hooks'
import { setTastyFbTickerNotesGroups } from '../../store/userSlice'
import { handleError } from '../../utilities/error-handling'

interface Props {
  noteGroups: NoteGroup[] | undefined
  ticker: string
}

const NoteNew: FC<Props> = ({ noteGroups, ticker }) => {
  const [newNoteText, setNewNoteText] = useState('')

  const dispatch = useAppDispatch()

  const today = format(new Date(), 'MM/dd/yy')
  const epochTime = round(Date.now()/1000)

  const genNewNoteGroup = (): NoteGroup => {
    return {
      date: today,
      notes: [genNewNote()]
    }
  }

  const genNewNote = (): Note => {
    return {
      date: epochTime,
      noteText: newNoteText
    }
  }

  const saveNewNote = () => {
    if (newNoteText) {
      try {
        let newNoteGroups = compact(cloneDeep(noteGroups)) || []
        const existingNoteGroup = newNoteGroups.find(noteGroup => noteGroup.date === today)

        if (existingNoteGroup) {
          if (isEmpty(existingNoteGroup.notes)) {
            existingNoteGroup.notes = []
          }
          existingNoteGroup.notes = compact(existingNoteGroup.notes)
          existingNoteGroup.notes.unshift(genNewNote())
        } else {
          newNoteGroups.unshift(genNewNoteGroup())
        }

        const path = `tasty/fbTickerGroupsNotes/${ticker}/noteGroups`
        newNoteGroups.forEach(noteObj => {
          if (!isEmpty(noteObj.notes)) noteObj.notes = compact(noteObj.notes)
        })

        setToFirebase({ refPath: path, value: newNoteGroups })
        dispatch( setTastyFbTickerNotesGroups({ ticker, noteGroups: newNoteGroups }) )
        setNewNoteText('')
        toast.success('Note saved.')

      } catch (e) {
        handleError({ msg: 'Note NOT saved.', e })
      }
    }
  }

  return (
    <div className='flex flex-row items-center pl-4 w-full'>

      <input
        id='newNote'
        type='text'
        className='input-full-wide border dark:border-0 h-7'
        placeholder='new note...'
        value={newNoteText}
        onChange={(e: BaseSyntheticEvent) => setNewNoteText(e.target.value)}
        onKeyDown={(e: BaseSyntheticEvent | any) => { if (e.key === 'Enter') saveNewNote() }}
      />

      {
        newNoteText &&
        <HiOutlineCheckCircle
          onClick={saveNewNote}
          className='action-icons check-circle-sz inline-block text-success ml-2'
        />
      }

    </div>
  )
}

export default NoteNew
