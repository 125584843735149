import { FC } from 'react'
import { FaSpinner } from 'react-icons/fa'
interface Props {
  size?: string
  fill?: string
  classNames?: string
}

const Spinner: FC<Props> = ({
  size = '1rem',
  fill = 'black',
  // classNames = 'pt-20'
}) => {
  return (
    <div className='animate-spin'>
      <FaSpinner size={size} fill={fill} />
    </div>
  )
}

export default Spinner
