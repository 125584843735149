import { FC } from 'react'
import LogInOut from '../tasty/LogInOut'

interface Props {
  view: string
  setView: React.Dispatch<React.SetStateAction<string>>
  title: string
}

const NavButton: FC<Props> = ({ view, setView, title }) => {

  const test = () => {
    console.log('testing ...')
  }

  return (
    <button
      onClick={ () => setView(title) }
      className={`
        body-menu-button relative
        ${view === title && 'border-t border-[#0000ffb0] dark:bg-[#25303ab0] bg-light-grey'}
      `}
    >

      <div className={`view-title ${view === title ? 'font-light' : 'font-thin'}`}>
        { title }
      </div>

      {
        view === title && title === 'Tasty' &&
        <div className='absolute top-1 right-4' onClick={() => test()}>
          <LogInOut />
        </div>
      }

    </button>
  )
}

export default NavButton
