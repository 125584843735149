export const mockOrders: any = [
  {
      "id": 128512,
      "account-number": "5WT24506",
      "time-in-force": "Day",
      "order-type": "Limit",
      "size": 1,
      "underlying-symbol": "AAPL",
      "underlying-instrument-type": "Equity",
      "price": "999.99",
      "price-effect": "Debit",
      "status": "Live",
      "cancellable": true,
      "editable": true,
      "edited": false,
      "ext-exchange-order-number": "4797478598144",
      "ext-client-order-id": "000000045d0001f600",
      "ext-global-order-number": 1117,
      "received-at": "2024-01-22T16:02:03.477+00:00",
      "updated-at": 1705939323574,
      "global-request-id": "b7c52f4237f24f610aa1c700c4342aa7",
      "legs": [
          {
              "instrument-type": "Equity",
              "symbol": "AAPL",
              "quantity": 1,
              "remaining-quantity": 1,
              "action": "Buy to Open",
              "fills": []
          }
      ]
  },
  {
      "id": 128511,
      "account-number": "5WT24506",
      "time-in-force": "Day",
      "order-type": "Market",
      "size": 1,
      "underlying-symbol": "MSFT",
      "underlying-instrument-type": "Equity",
      "price-effect": "Credit",
      "status": "Rejected",
      "cancellable": false,
      "editable": false,
      "edited": false,
      "received-at": "2024-01-22T15:48:34.613+00:00",
      "updated-at": 1705938514613,
      "reject-reason": "Trading of MSFT  240301C00425000 is not supported",
      "terminal-at": "2024-01-22T15:48:34.612+00:00",
      "global-request-id": "a4dfb4e05472a2abdd97ca09a496ebae",
      "legs": [
          {
              "instrument-type": "Equity Option",
              "symbol": "MSFT  240301C00425000",
              "quantity": 1,
              "remaining-quantity": 1,
              "action": "Sell to Open",
              "fills": []
          },
          {
              "instrument-type": "Equity Option",
              "symbol": "MSFT  240301C00420000",
              "quantity": 1,
              "remaining-quantity": 1,
              "action": "Buy to Open",
              "fills": []
          }
      ]
  },
  {
      "id": 128510,
      "account-number": "5WT24506",
      "time-in-force": "Day",
      "order-type": "Market",
      "size": 1,
      "underlying-symbol": "MSFT",
      "underlying-instrument-type": "Equity",
      "price-effect": "Credit",
      "status": "Rejected",
      "cancellable": false,
      "editable": false,
      "edited": false,
      "received-at": "2024-01-21T23:13:12.597+00:00",
      "updated-at": 1705878792597,
      "reject-reason": "Trading of MSFT  240301C00425000 is not supported",
      "terminal-at": "2024-01-21T23:13:12.596+00:00",
      "global-request-id": "caca87e0a220a6df5bf1bff9b3c4e1c1",
      "legs": [
          {
              "instrument-type": "Equity Option",
              "symbol": "MSFT  240301C00425000",
              "quantity": 1,
              "remaining-quantity": 1,
              "action": "Sell to Open",
              "fills": []
          },
          {
              "instrument-type": "Equity Option",
              "symbol": "MSFT  240301C00420000",
              "quantity": 1,
              "remaining-quantity": 1,
              "action": "Buy to Open",
              "fills": []
          }
      ]
  },
  {
      "id": 128509,
      "account-number": "5WT24506",
      "time-in-force": "Day",
      "order-type": "Market",
      "size": 1,
      "underlying-symbol": "AAPL",
      "underlying-instrument-type": "Equity",
      "status": "Rejected",
      "cancellable": false,
      "editable": false,
      "edited": false,
      "received-at": "2024-01-21T23:10:24.141+00:00",
      "updated-at": 1705878624141,
      "reject-reason": "Opening market orders not allowed when market closed.",
      "terminal-at": "2024-01-21T23:10:24.140+00:00",
      "global-request-id": "76d890ee54914df986f4afa85f054e85",
      "legs": [
          {
              "instrument-type": "Equity",
              "symbol": "AAPL",
              "quantity": 1,
              "remaining-quantity": 1,
              "action": "Buy to Open",
              "fills": []
          }
      ]
  },
  {
      "id": 128508,
      "account-number": "5WT24506",
      "time-in-force": "Day",
      "order-type": "Market",
      "size": 1,
      "underlying-symbol": "MSFT",
      "underlying-instrument-type": "Equity",
      "price-effect": "Credit",
      "status": "Rejected",
      "cancellable": false,
      "editable": false,
      "edited": false,
      "received-at": "2024-01-21T23:10:24.109+00:00",
      "updated-at": 1705878624109,
      "reject-reason": "Trading of MSFT  240301C00425000 is not supported",
      "terminal-at": "2024-01-21T23:10:24.108+00:00",
      "global-request-id": "ed4fc7aff4179f32bf49cd06a1f94223",
      "legs": [
          {
              "instrument-type": "Equity Option",
              "symbol": "MSFT  240301C00425000",
              "quantity": 1,
              "remaining-quantity": 1,
              "action": "Sell to Open",
              "fills": []
          },
          {
              "instrument-type": "Equity Option",
              "symbol": "MSFT  240301C00420000",
              "quantity": 1,
              "remaining-quantity": 1,
              "action": "Buy to Open",
              "fills": []
          }
      ]
  },
  {
      "id": 128507,
      "account-number": "5WT24506",
      "time-in-force": "GTC",
      "order-type": "Market",
      "size": 1,
      "underlying-symbol": "AAPL",
      "underlying-instrument-type": "Equity",
      "status": "Rejected",
      "cancellable": false,
      "editable": false,
      "edited": false,
      "received-at": "2024-01-21T23:09:19.354+00:00",
      "updated-at": 1705878559354,
      "reject-reason": "GTC/GTD Market orders are not supported.",
      "terminal-at": "2024-01-21T23:09:19.353+00:00",
      "global-request-id": "63d1aa77b0191ee75930ad95eff072e7",
      "legs": [
          {
              "instrument-type": "Equity",
              "symbol": "AAPL",
              "quantity": 1,
              "remaining-quantity": 1,
              "action": "Buy to Open",
              "fills": []
          }
      ]
  },
  {
      "id": 128506,
      "account-number": "5WT24506",
      "time-in-force": "GTC",
      "order-type": "Market",
      "size": 1,
      "underlying-symbol": "MSFT",
      "underlying-instrument-type": "Equity",
      "price-effect": "Credit",
      "status": "Rejected",
      "cancellable": false,
      "editable": false,
      "edited": false,
      "received-at": "2024-01-21T23:09:19.343+00:00",
      "updated-at": 1705878559343,
      "reject-reason": "Trading of MSFT  240301C00425000 is not supported",
      "terminal-at": "2024-01-21T23:09:19.342+00:00",
      "global-request-id": "6cd68f8111aa4a147c5c1553332e33d8",
      "legs": [
          {
              "instrument-type": "Equity Option",
              "symbol": "MSFT  240301C00425000",
              "quantity": 1,
              "remaining-quantity": 1,
              "action": "Sell to Open",
              "fills": []
          },
          {
              "instrument-type": "Equity Option",
              "symbol": "MSFT  240301C00420000",
              "quantity": 1,
              "remaining-quantity": 1,
              "action": "Buy to Open",
              "fills": []
          }
      ]
  }
]