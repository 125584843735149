import { FC } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import PortfolioSettings from './PortfolioSettings'
import PortfolioBalances from './PortfolioBalances'
import Orders from './Orders'
import Positions from './Positions'
import LogInOut from './LogInOut'
// import { aaplLimitOrder, msftBearCallSpread } from './mock-data/postOrders'

const MOCKING = (process.env.REACT_APP_MOCKING && process.env.REACT_APP_MOCKING === 'true') ? true : false

const Index: FC = () => {
  const tastyLogin = useAppSelector(state => state.userStore.user.tasty.login)
  const accountBalances = useAppSelector(state => state.userStore.user.tasty.accountBalances)
  const positions = useAppSelector(state => state.userStore.user.tasty.positions)
  const orders = useAppSelector(state => state.userStore.user.tasty.orders)

  // const dispatch = useAppDispatch()

  // const placeOrders = async () => {
  //   if (MOCKING) return
  //   if (tastyLogin && tastyLogin.accountNumbers && tastyLogin.sessionToken) {
  //     postOrder({
  //       acctNum: tastyLogin?.accountNumbers[0],
  //       orderData: msftBearCallSpread,
  //       sessionToken: tastyLogin?.sessionToken
  //     })
  //     // postOrder({
  //     //   acctNum: tastyLogin?.accountNumbers[0],
  //     //   orderData: aaplLimitOrder,
  //     //   sessionToken: tastyLogin?.sessionToken
  //     // })
  //   }
  // }

  return (
    <div className='flex flex-col items-center'>
      {/* {
        MOCKING &&
        <div className='text-warn text-2xl'>
          MOCKING
        </div>
      } */}
      {
        tastyLogin && tastyLogin.isLoggedIn ? (
        <div className='max-content-width-tasty w-full'>

          <PortfolioSettings />

          <Positions tastyPositions={positions} />

          <PortfolioBalances balances={accountBalances} />

          <Orders tastyOrders={orders} />

          {/* <button className='m-6 px-4 py-2 border rounded-md' onClick={() => placeOrders()}>
            Place Test Orders
          </button> */}

        </div>

      ) : (
        <div className='mt-8'>
          You are NOT logged into TastyTrade.
        </div>
      )}
    </div>
  )
}

export default Index
