import { find, isEmpty } from 'lodash'
import { BaseSyntheticEvent, FC, useState } from 'react'
import toast from 'react-hot-toast'
import { HiOutlineCheckCircle, HiOutlineDocumentPlus } from 'react-icons/hi2'
import { updateToFirebase } from '../../services/api/firebase'
import { TickerGroup } from '../../types/rooStrat'

interface Props {
  noTickerGroups?: boolean
  tickerGroups: TickerGroup[]
}

const AddNewTickerGroup: FC<Props> = ({ noTickerGroups, tickerGroups }) => {
  const [showForm, setShowForm] = useState(noTickerGroups ? true : false)
  const [newTicker, setNewTicker] = useState('')
  const [tickerGroupExists, setTickerGroupExists] = useState(false)

  const saveNewTicker = async () => {
    const path = 'rooStratPortfolio/tickerGroups'
    const newTickerGroup = { ticker: newTicker }
    const newKey = tickerGroups.length

    if (!doesTickerGroupExist(newTicker)) {
      try {
        const updates: any = {}
        updates['' + newKey] = newTickerGroup
        await updateToFirebase({ refPath: path, updates: updates })
        toggleShowForm()
        setNewTicker('')
        setTickerGroupExists(false)
        toast.success('New ticker group created.')
      } catch (e) {
        console.error(`AddNewTickerGroup.tsx - saveNewTickerGroup() ${e}`)
        toast.error('New ticker group NOT created.')
      }
    }
  }

  const doesTickerGroupExist = (ticker: string) => {
    return !isEmpty(find(tickerGroups, ['ticker', ticker]))
  }

  const toggleShowForm = () => {
    const newVal = !showForm
    if (!newVal) setNewTicker('')
    setShowForm(newVal)
  }

  const onChangeTicker = (e: BaseSyntheticEvent) => {
    setTickerGroupExists(false)
    const newTicker = e.target.value.trim().toUpperCase()
    setNewTicker(newTicker)
    if (doesTickerGroupExist(newTicker)) setTickerGroupExists(true)
  }

  const onKeyPressTicker = (e: BaseSyntheticEvent | any) => {
    if (e.key === 'Enter') saveNewTicker()
  }

  return (
    <div className='pl-4'>

      { noTickerGroups &&
        <p className='pb-4 text-lg'>Add a ticker group ...</p>
      }

      <div className='flex items-center'>
        <HiOutlineDocumentPlus
          onClick={toggleShowForm}
          title='Add ticker group'
          className='action-icons-md text-orange'
        />

        { showForm &&
          <input
            id='editTicker'
            type='text'
            className='input-base ml-1'
            placeholder='Ticker'
            value={newTicker}
            onChange={onChangeTicker}
            onKeyDown={onKeyPressTicker}
            autoFocus
          />
        }

        { newTicker.length > 0 &&
          <HiOutlineCheckCircle
            className={`action-icons check-circle-sz ml-1 mr-0 ${ tickerGroupExists ? 'text-warn' : 'text-success'}`}
            onClick={saveNewTicker}
          />
        }

        { tickerGroupExists &&
          <span className='text-danger pl-1'>Group {newTicker} exists.</span>
        }
      </div>

    </div>
  )
}

export default AddNewTickerGroup
