import { FC } from 'react'
import { getUnixTime } from 'date-fns'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { loginTasty, logoutTasty } from '../../services/api/tasty'
import { resetTasty, updateTastyLogin } from '../../store/userSlice'
import { handleError } from '../../utilities/error-handling'
import toast from 'react-hot-toast'
import { HiOutlineCheckCircle, HiOutlineXCircle } from 'react-icons/hi2'
import { mockLogin } from './mock-data/login'
// import ReactTooltip from 'react-tooltip'

interface Props {}

const LogInOut: FC<Props> = (props) => {
  // const [userName, setUserName] = useState()
  // const [password, setPassword] = useState()

  const dispatch = useAppDispatch()
  const tastyLogin = useAppSelector(state => state.userStore.user.tasty.login)

  const loginNow = async () => {
    const nowUnix = getUnixTime(new Date())
    if (!tastyLogin || !tastyLogin.lastLoginTime || !tastyLogin.sessionToken || lastLoginMoreThan23HrsAgo(nowUnix)) {
      console.log('... trying to loginNow')
      try {
        const loginResponse: any = await loginTasty()
        const token = loginResponse['session-token'] || loginResponse.data.data['session-token']
        // const extId = loginResponse.user['external-id'] || loginResponse.data.data['external-id']
        if (loginResponse && token) {
          dispatch( updateTastyLogin(
            {
              isLoggedIn: true,
              lastLoginTime: nowUnix,
              sessionToken: token,
              // userExternalId: extId,
            }
          ))
          toast.success('Logged into TastyTrade.')
        }
      } catch (e) {
        dispatch( resetTasty() )
        handleError({ msg: 'Could not log into TastyTrade.', e })
      }
    }
    else {
      console.log('-->> ... already logged in')
    }
  }

  const loginNowMocking = async () => {
    const nowUnix = getUnixTime(new Date())
    if (!tastyLogin || !tastyLogin.lastLoginTime || !tastyLogin.sessionToken || lastLoginMoreThan23HrsAgo(nowUnix)) {
      console.log('... MOCK loginNow')
      dispatch( updateTastyLogin(mockLogin) )
      toast.success('MOCK Logged into TastyTrade.')
      return
    }
    else {
      console.log('-->> ... already logged in')
    }
  }

  const logoutNow = async () => {
    try {
      await logoutTasty()
      dispatch( resetTasty() )
      toast.success('Log out successful.')
    } catch (e) {
      handleError({ msg: 'Could not log out of TastyTrade.', e})
    }
  }

  const lastLoginMoreThan23HrsAgo = (nowUnix: number) => {
    const timeOut = 23 * 60 * 60 * 1000
    if (!tastyLogin || !tastyLogin.lastLoginTime) {
      return true
    }
    return nowUnix > tastyLogin.lastLoginTime + timeOut
  }

  const iconClasses = 'cursor-pointer text-3xl'

  return (
    <div className='flex flex-row'>
      {
        (tastyLogin && tastyLogin.isLoggedIn) ? (
          <HiOutlineCheckCircle
            className={`text-success ${iconClasses}`}
            onClick={() => logoutNow()}
          />
        ) : (
        <>
          <HiOutlineXCircle
            className={`text-danger ${iconClasses}`}
            onClick={() => loginNow()}
            // data-tip data-for='tasty-login'
          />
          {/* {
            MOCKING &&
            <div className='flex flex-col pl-6'>
              <HiOutlineXCircle
                className={`text-danger ${iconClasses}`}
                onClick={() => loginNowMocking()}
              />
              Mock
            </div>
          } */}
        </>
        )
      }
    </div>
  )
}

export default LogInOut


// return (
//   <div className='flex flex-row'>
//     {
//       tastyLogin.isLoggedIn &&

//     }
//     {
//       !tastyLogin.isLoggedIn &&
//       <>
//         <HiOutlineXCircle
//           className='text-danger text-4xl cursor-pointer'
//           onClick={() => loginNow()}
//           // data-tip data-for='tasty-login'
//         />
//         <ReactTooltip
//           id='tasty-login'
//           place='bottom'
//           effect='solid'
//           clickable={true}
//           delayShow={300}
//           delayHide={350}
//           className='opacity-full'
//         >
//           <input
//             id='userName'
//             type='text'
//             className='input-wide'
//             value={userName}
//             onChange={(e) => setNewUserName(e.target.value))}
//             autoFocus
//           />
//         </ReactTooltip>
//         ( MOCKING &&
//           <div className='flex flex-col pl-6'>
//             <HiOutlineXCircle
//               className='text-danger text-4xl cursor-pointer'
//               onClick={() => loginNowMocking()}
//             />
//             Mock
//           </div>
//         )
//       </>
//     }
//   </div>
// )
