import { FC, useEffect } from 'react'
import { handleError } from '../../utilities/error-handling'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { toNumber } from 'lodash'
import { toast } from 'react-hot-toast'
import { setTenYearRate } from '../../store/appSlice'
import { format } from 'date-fns'

const TEN_YR_YIELD_URL = process.env.REACT_APP_TEN_YR_YIELD_URL

const TenYearRateManager: FC = () => {
  const tenYearRate = useAppSelector(state => state.appStore.globalValues.tenYearRate)

  const dispatch = useAppDispatch()

  const getAndSetTenYrRate = async () => {
    console.log('-->> getRate()')
    const todayFormatted = format(new Date(), 'yyyy-MM-dd')
    if ( !tenYearRate.value || tenYearRate.updated !== todayFormatted ) {
      try {
        console.log('   Getting new rate data.')
        // const url = 'http://127.0.0.1:5001/drootracker/us-central1/getTenYrTreasuryYield?source=fred'
        // const url = 'http://127.0.0.1:5001/drootracker/us-central1/getTenYrTreasuryYield?source=treasury'
        const url = `${TEN_YR_YIELD_URL}?source=treasury`
        const response = await fetch(url)
        const data = await response.json()
        console.log('   data: ', data)
        if (data) {
          dispatch( setTenYearRate(
            {
              updated: data.updated,
              rateDate: data.rateDate,
              value: toNumber(data.tenYearRate)
            }
          ))
          // Cloud fn sets data to fb.
          toast.success(`Updated ten year rate: ${data.rateDate}: ${data.tenYearRate}`)
        }
      } catch (e) {
        handleError({ msg: 'Could not update ten year rate.', e })
      }
    }
    else {
      console.log("   Today's rate already in state.")
    }
  }

  useEffect(() => {
    console.log('\n<-- Setup and Teardown useEffect[] -->')
    getAndSetTenYrRate()
  }, [])

  return null
}

export default TenYearRateManager
