import { FC } from 'react'
import SpreadRowTemplate from './SpreadRowTemplate'

const SpreadHeader: FC = () => {
  return (
    <div className='flex flex-row justify-end'>
      <SpreadRowTemplate
        bg='dark:bg-dark-bg'

        col1Css='font-bold'
        col1Data='Option'

        col2Css='font-bold'
        col2Data='Qty'

        col3Css='font-bold'
        col3Data='DTE'

        col4Css='font-bold'
        col4Data='Mark'

        col5Css='font-bold'
        col5Data='Price'

        col6Css='font-bold pr-4'
        col6Data='∆'
        col7Css='font-bold pr-3'
        col7Data='∆c'

        col8Css='font-bold pr-4'
        col8Data='θ'
        col9Css='font-bold pr-4'
        col9Data='θc'
      />
      <div className='min-w-10'></div>
    </div>
  )
}

export default SpreadHeader
