import { FC } from 'react'
import { HiArrowPath } from 'react-icons/hi2'
import { TastyTickerGroups } from '../../types/tasty'
import { format, fromUnixTime } from 'date-fns'

interface Props {
  tickerGroups: TastyTickerGroups
  quotesLastUpdated: number
  getSetMarketData: (tickerGroupsBuild: TastyTickerGroups) => void
}

const UpdateOptionsQuotes: FC<Props> = ({ tickerGroups, getSetMarketData, quotesLastUpdated }) => {
  const updateTime = quotesLastUpdated ? format(fromUnixTime(quotesLastUpdated), 'MM/dd kk:mm') : ''

  const updateQuotes = () => {
    try {
      getSetMarketData(tickerGroups)
    } catch (e) {
      console.error(`Tracker.tsx - updateQuotes() - ${e}`)
    }
}

  return (
    <div className='flex items-center cursor-pointer' onClick={updateQuotes}>

      <HiArrowPath
        title='Update options data.'
        className='action-icons-md text-orange'
      />

      <div className='pl-2 cursor-pointer font-thin text-lg'>
        { updateTime }
      </div>

    </div>
  )
}

export default UpdateOptionsQuotes
