import { isNumber, round, toNumber } from 'lodash'

export const plColor = (val: number | undefined) => {
  if (!val) return
  val = round(val, 1) // To compensate for very small JS rounding errors when val is close to zero.
  return val === 0 ? 'dark:text-white text-black' : val > 0 ? 'text-success': 'text-danger'
}

// export const plBorderColor = (val: number | undefined) => {
//   if (!val) return
//   val = round(val, 1) // To compensate for very small JS rounding errors when val is close to zero.
//   return val === 0 ? '' : val > 0 ? 'border-success': 'border-danger'
// }

export const plBgColor = (val: number | undefined) => {
  if (!val) return
  val = round(val, 1) // To compensate for very small JS rounding errors when val is close to zero.
  return val === 0 ? '' : val > 0 ? 'bg-[#005a00]': 'bg-[#7f1100]'
}

export const deltaColor = (delta: number | undefined) => {
  if (!delta) return
  delta = Math.abs(delta)
  delta = delta < 1 ? delta * 100 : delta
  if (delta >= 18 && delta <= 26) return 'text-success'
  if (delta >= 32 && delta <= 36) return 'text-orange'
  return ''
}

// export const deltaTextColor = (delta: number | undefined) => {
//   if (!delta) return ''
//   delta = Math.abs(delta)
//   delta = delta < 1 ? delta * 100 : delta
//   if (delta <= 26) return 'text-[#008000] dark:text-[#005a00]'
//   if (delta <= 28) return 'text-[#1b6900] dark:text-[#196600]'
//   if (delta <= 30) return 'text-[#375000] dark:text-[#334c00]'
//   if (delta <= 32) return 'text-[#533700] dark:text-[#4c3300]'
//   if (delta <= 34) return 'text-[#8c5200] dark:text-[#7f4200]'
//   if (delta <= 36) return 'text-[#8c3d00] dark:text-[#7f3100]'
//   if (delta <= 38) return 'text-[#8c2b00] dark:text-[#7f2100]'
//   if (delta <= 39) return 'text-[#8c1900] dark:text-[#7f1100]'
//   return 'text-[#7f0000]'
// }

export const deltaBgColor = (delta: number | undefined) => {
  if (!delta) return 'bg-highlighter dark:bg-dark-menu'
  delta = Math.abs(delta)
  delta = delta < 1 ? delta * 100 : delta
  if (delta <= 26) return 'bg-[#008000] dark:bg-[#005a00]'
  if (delta <= 28) return 'bg-[#1b6900] dark:bg-[#196600]'
  if (delta <= 30) return 'bg-[#375000] dark:bg-[#334c00]'
  if (delta <= 32) return 'bg-[#533700] dark:bg-[#4c3300]'
  if (delta <= 34) return 'bg-[#8c5200] dark:bg-[#7f4200]'
  if (delta <= 36) return 'bg-[#8c3d00] dark:bg-[#7f3100]'
  if (delta <= 38) return 'bg-[#8c2b00] dark:bg-[#7f2100]'
  if (delta <= 39) return 'bg-[#8c1900] dark:bg-[#7f1100]'
  return 'bg-[#7f0000]'
}

// export const deltaBorderColor = (delta: number | undefined) => {
//   if (!delta) return 'border-highlighter dark:border-dark-menu'
//   delta = Math.abs(delta)
//   delta = delta < 1 ? delta * 100 : delta
//   if (delta <= 26) return 'border-[#008000] dark:border-[#005a00]'
//   if (delta <= 28) return 'border-[#1b6900] dark:border-[#196600]'
//   if (delta <= 30) return 'border-[#375000] dark:border-[#334c00]'
//   if (delta <= 32) return 'border-[#533700] dark:border-[#4c3300]'
//   if (delta <= 34) return 'border-[#8c5200] dark:border-[#7f4200]'
//   if (delta <= 36) return 'border-[#8c3d00] dark:border-[#7f3100]'
//   if (delta <= 38) return 'border-[#8c2b00] dark:border-[#7f2100]'
//   if (delta <= 39) return 'border-[#8c1900] dark:border-[#7f1100]'
//   return 'border-[#7f0000]'
// }

export const dteColor = (dte: number, pl: number): string => {
  if (pl && pl <= 0) {
    return dte > 30 ? 'text-success' : 'text-danger'
  }
  else {
    if (dte > 30) { return 'text-success' } else
    if (dte > 20) { return 'text-warn' } else
    return 'text-danger'
  }
}

export const dteBgColor = (dte: number, pl: number): string => {
  if (pl && pl <= 0) {
    return dte > 30 ? 'bg-[#005a00]' : 'bg-[#7f1100]'
  }
  else {
    if (dte > 30) { return 'bg-[#005a00]' } else
    if (dte > 20) { return 'bg-warn' } else
    return 'bg-[#7f1100]'
  }
}

// export const dteBorderColor = (dte: number, pl: number): string => {
//   if (pl && pl <= 0) {
//     return dte > 30 ? 'text-success' : 'text-danger'
//   }
//   else {
//     if (dte > 30) { return 'text-success' } else
//     if (dte > 20) { return 'text-warn' } else
//     return 'text-danger'
//   }
// }

export const posBpTooBigColor = (bp: number | undefined, settings: any) => {
  if (
    !bp ||
    !settings ||
    !settings.accountEquity ||
    !settings.maxPositionSize)
  {
    return ''
  }
  return bp > (settings.accountEquity * settings.maxPositionSize / 100) ? 'text-danger' : ''
}

export const isRealNumber = (val: number | string | undefined) => {
  if (!val) return false
  val = toNumber(val)
  return isNumber(val) && !isNaN(val) && isFinite(val) && val !== Infinity && val !== -Infinity
}

export const toLocalDecimals = (num: number | string | undefined, decimals = 0) => {
  if (!num) return ''
  num = toNumber(num)
  if (!isRealNumber(num)) return ''
  num = num * 1 // If not, whole nums get no decimals: 3 -> 3 but 3*1 -> 3.00
  return num.toLocaleString(undefined, { minimumFractionDigits: decimals, maximumFractionDigits: decimals })
}

export const displayPercent = (val: number | string | undefined, dec = 0) => {
  // if (!val) return ''
  val = toNumber(val)
  return isRealNumber(val) ? `${toLocalDecimals(val, dec)}%` : ''
}

export const balanceColor = (settings: any, deltaOrTheta: string, percent: number | string | undefined) => {
  percent = toNumber(percent)
  if (
    !isRealNumber(percent) ||
    !settings ||
    !settings.deltaBalanceHigh ||
    !settings.thetaBalanceHigh ||
    !settings.deltaBalanceLow ||
    !settings.thetaBalanceLow )
  {
    return ''
  }

  const high = deltaOrTheta === 'delta' ? settings.deltaBalanceHigh : settings.thetaBalanceHigh
  const low = deltaOrTheta === 'delta' ? settings.deltaBalanceLow : settings.thetaBalanceLow

  return (percent > high || percent < low) ? 'text-danger' : 'text-success'
}
