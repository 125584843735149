import { FC } from 'react'
import PortfolioSummary from './PortfolioSummary'
import PortfolioGroup from './PortfolioGroup'
import { useAppSelector } from '../../store/hooks'
import AddNewTickerGroup from './AddNewTickerGroup'
import UpdateOptionsData from './UpdateOptionsData'
import { isEmpty } from 'lodash'
import PortfolioSettings from './PortfolioSettings'
import DndGroups from './DndGroups'
import uuid from 'react-uuid'

const Tracker: FC = () => {
  const rooStratPortfolio = useAppSelector(state => state.userStore.user.rooStratPortfolio)
  const tickerGroups = rooStratPortfolio?.tickerGroups || []

  const isTickerGroups = !isEmpty(tickerGroups)

  if (!rooStratPortfolio) {
    return (
      <div className='ml-auto mr-auto'>
        <div className='text-center text-xlg'>Loading data ...</div>
        <div className='text-center pt-8'>If you still see this message ...</div>
        <div className='text-center pt-8'>and you just signed up,</div>
        <div className='text-center pt-2'>try logging out and back in again.</div>
        <div className='text-center pt-8'>but you are an existing member,</div>
        <div className='text-center pt-2'>close this tab and contact support.</div>
      </div>
    )
  }

  return (
    <div className='ml-auto mr-auto max-w-fit'>

      {
        !isTickerGroups &&
        <AddNewTickerGroup
          noTickerGroups={true}
          tickerGroups={tickerGroups}
        />
      }
      {
        isTickerGroups &&
        <>
          <div className='flex items-center'>
            <UpdateOptionsData
              lastUpdated={rooStratPortfolio.dataLastUpdated}
              tickerGroups={tickerGroups}
            />
            <AddNewTickerGroup tickerGroups={tickerGroups} />
            <DndGroups tickerGroups={tickerGroups} />
            <PortfolioSettings settings={rooStratPortfolio.settings} />
          </div>

          <PortfolioSummary rooStratPortfolio={rooStratPortfolio} />
          {
            Object.keys(tickerGroups).map((groupKey: any) => (
              <PortfolioGroup
                key={uuid()}
                group={tickerGroups[groupKey]}
                groupKey={groupKey}
                settings={rooStratPortfolio.settings}
              />
            ))
          }
        </>
      }

    </div>
  )
}

export default Tracker
