import { FC, useState } from 'react'
import uuid from 'react-uuid'
import { useAppSelector } from '../../store/hooks'
import { Note, NoteGroup, TastyTickerGroups } from '../../types/tasty'
import CollapseSection from '../wrappers/CollapseSection'
import NoteNew from './NoteNew'
import NoteRow from './NoteRow'

interface Props {
  ticker: string
}

const Notes: FC<Props> = ({ ticker }) => {
  const [isExpanded, setIsExpanded] = useState(true)

  const fbTickerGroups = useAppSelector(state => state.userStore.user.tasty.fbTickerGroupsNotes) as TastyTickerGroups
  const noteGroups = fbTickerGroups && fbTickerGroups[ticker] && fbTickerGroups[ticker].noteGroups ? fbTickerGroups[ticker].noteGroups : []

  return (
    <CollapseSection
      title='Notes'
      titleExtra={<NoteNew noteGroups={noteGroups} ticker={ticker} />}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      heirachyLevel={2}
    >

      {
        noteGroups &&
        noteGroups.map((noteGroup: NoteGroup, noteGroupKey: number) => (
          noteGroup &&
          noteGroup.notes.map((note: Note, noteKey: number) => (
            <NoteRow
              key={uuid()}
              note={note}
              ticker={ticker}
              noteGroup={noteGroup}
              noteGroupKey={noteGroupKey}
              noteKey={noteKey}
            />
          ))
        ))
      }

    </CollapseSection>
  )
}

export default Notes
