import { map } from 'lodash'
import { FC, useState } from 'react'
import { HiOutlineBuildingLibrary } from 'react-icons/hi2'
import uuid from 'react-uuid'
import { TastyAccountBalances } from '../../types/tasty'
import { toLocalDecimals } from '../../utilities/general'
import CollapseSection from '../wrappers/CollapseSection'

interface Props {
  balances: TastyAccountBalances | undefined
}

const BAL_KEYS: {[key: string]: string} = {
  accountNumber: 'Account Number',
  cashAvailableToWithdraw: 'Cash to withdraw',
  cashBalance: 'Cash Balance',
  derivativeBuyingPower: 'Derivative BP',
  equityBuyingPower: 'Equity BP',
  netLiquidatingValue: 'Net Liq',
  usedDerivativeBuyingPower: 'Used derivative BP',
}

const PortfolioBalances: FC<Props> = ({ balances }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const collapseTitle = () => {
    return (
      <div className='flex flex-row items-center'>
        <HiOutlineBuildingLibrary
          className={'action-icons-md mr-3 text-orange'}
        />
        <div>
          Portfolio Balances
        </div>
      </div>
    )
  }

  return (
    <CollapseSection
      title={ collapseTitle() }
      isExpanded={ isExpanded }
      setIsExpanded={ setIsExpanded }
    >
      <div className='flex flex-row pos-font'>
        <table className=''>
          <thead></thead>
            <tbody>
              {
                map(balances, (val, key) => (
                  <tr key={uuid()}>
                    <td>
                      {BAL_KEYS[key]}
                    </td>
                    <td className='pl-3'>
                      {toLocalDecimals(val, 0)}
                    </td>
                  </tr>
                ))
              }
          </tbody>
        </table>
        {/* <div className='flex flex-row flex-wrap'>
          {
            Object.entries(balances).map(([key, val], i) => (
              <Fragment key={uuid()}>
                <div className={`text-right ${i === 0 ? 'pl-3' : 'pl-8'}`}>
                  {BAL_KEYS[key]}
                </div>
                <div className='text-left pl-2'>
                  {toLocalDecimals(val, 0)}
                </div>
              </Fragment>
            ))
          }
        </div> */}
      </div>
    </CollapseSection>
  )
}

export default PortfolioBalances
