import { FC, useEffect, useState } from 'react'
import uuid from 'react-uuid'
import { RooStratSettings, TickerGroup } from '../../types/rooStrat'
import { isEmpty } from 'lodash'
import GroupTableHead from './GroupTableHead'
import RowSpacer from '../tables/RowSpacer'
import PositionRows from './PositionRows'
import NoteNew from './NoteNew'
import NoteRow from './NoteRow'

interface Props {
  group: TickerGroup
  groupKey: number
  settings: RooStratSettings
}

const PortfolioGroup: FC<Props> = ({ group, groupKey, settings }) => {
  const [showNewPosForm, setShowNewPosForm] = useState(false)

  const noteGroups = group.noteGroups || []
  const spreads = group.spreads || []

  const isNoteGroups = !isEmpty(noteGroups)
  const isSpreads = !isEmpty(spreads)

  const toggleShowNewPosForm = () => {
    setShowNewPosForm(s => !s)
  }

  useEffect(() => {
    if (isEmpty(group.spreads) && isEmpty(group.butterflies)) setShowNewPosForm(true)
  }, [group])

  return (
    <div className='mb-8 border'>

      <table className='max-w-5xl pos-font'>
        <GroupTableHead
          toggleShowNewPosForm={toggleShowNewPosForm}
          group={group}
          groupKey={groupKey}
          settings={settings}
        />

        <tbody>
          {
            showNewPosForm && (
              <>
                <RowSpacer heightPx={15} />
                <PositionRows
                  spreads={spreads}
                  ticker={group.ticker}
                  groupKey={groupKey}
                  isNew={true}
                  toggleShowNewPosForm={toggleShowNewPosForm}
                  settings={settings}
                />
              </>
            )
          }

          {
            isSpreads &&
            Object.keys(spreads).map((spreadKey: any) => (
              <PositionRows
                key={uuid()}
                pos={spreads[spreadKey]}
                ticker={group.ticker}
                groupKey={groupKey}
                spreadKey={spreadKey}
                settings={settings}
              />
            ))
          }

          <RowSpacer heightPx={20} />

          <NoteNew
            noteGroups={noteGroups}
            groupKey={groupKey}
          />

          {
            isNoteGroups &&
            Object.keys(noteGroups).map((noteGroupKey: any) => (
              !isEmpty(noteGroups[noteGroupKey].notes) &&
              Object.keys(noteGroups[noteGroupKey].notes).map((noteKey: any, i: number) => (
                <NoteRow
                  key={uuid()}
                  noteGroup={noteGroups[noteGroupKey]}
                  groupKey={groupKey}
                  noteGroupKey={noteGroupKey}
                  noteKey={noteKey}
                  noteIndex={i}
                />
              ))
            ))
          }

          <RowSpacer heightPx={15} />
        </tbody>

      </table>

    </div>
  )
}

export default PortfolioGroup
