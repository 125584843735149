import { filter, findIndex, map, toNumber } from 'lodash'
import { BaseSyntheticEvent, FC, useState } from 'react'
import { Tier } from '../../types/rooStrat'

interface Props {
  isNew: boolean
  editPos: boolean
  posTiers: Tier[]
  tierKey: any
  savePosition: any
  updatedTiers: Tier[]
  setUpdatedTiers: any
}

const TierRow: FC<Props> = ({
    isNew,
    editPos,
    posTiers,
    tierKey,
    savePosition,
    setUpdatedTiers,
    updatedTiers
  }) => {

  const thisTier = updatedTiers[tierKey]
  const [tierDelta, setTierDelta] = useState(thisTier ? thisTier.delta : toNumber(''))
  const [tierDte, setTierDte]     = useState(thisTier ? thisTier.dte   : toNumber(''))

  const tierNum = thisTier ? thisTier.tierNumber : tierKey

  const getLineHeight = () => {
    const numTiers = posTiers.length
    if (numTiers <=  4) return 'tiers-4-lh'
    if (numTiers === 5) return 'tiers-5-lh'
    if (numTiers === 6) return 'tiers-6-lh'
    if (numTiers >=  7) return 'tiers-7-lh'
    return ''
  }

  const onChangeDelta = (e: BaseSyntheticEvent | any) => {
    const value = e.target.value
    setTierDelta(value)

    const newTierValues: Tier = {
      delta: value,
      dte: tierDte,
      tierNumber: tierNum
    }

    updateTierValues(newTierValues)
  }

  const onChangeDte = (e: BaseSyntheticEvent | any) => {
    const value = e.target.value
    setTierDte(value)

    const newTierValues: Tier = {
      delta: tierDelta,
      dte: value,
      tierNumber: tierNum
    }

    updateTierValues(newTierValues)
  }

  const updateTierValues = (newTierValues: Tier) => {
    const indexToUpdate = findIndex(updatedTiers, { tierNumber: newTierValues.tierNumber })
    const updatedPosTiers = [...updatedTiers]

    if (indexToUpdate !== -1) {
      updatedPosTiers[indexToUpdate] = newTierValues
    } else if (isNew) {
      updatedPosTiers.push(newTierValues)
    }

    // Remove any tiers with negative delta or dte values and renumber each tierNumber.
    const posTiers = filter(updatedPosTiers, ({ delta, dte }) => delta >= 0 && dte >= 0)
    const renumTiers = map(posTiers, (object, index) => ({ ...object, tierNumber: index + 1 }))

    setUpdatedTiers(renumTiers)
  }

  const onKeyDownSave = (e: BaseSyntheticEvent | any) => {
    if (e.key === 'Enter') {
      savePosition()
    }
  }

  return (
    <div className={`flex justify-center ${getLineHeight()}`}>

      { (editPos || tierNum <= posTiers.length) &&
        <div className='minw1-5 self-center pl-1'>
          {tierNum}
        </div>
      }

      <div className='minw1-5 pl-1'>
        { editPos ? (
          <input
            id='editTierDelta'
            type='number'
            className='input-base w-10 dark:bg-medium-grey text-center'
            value={tierDelta || ''}
            onChange={onChangeDelta}
            onKeyDown={onKeyDownSave}
          />
          ) : ( (tierDelta && tierDelta > 0) ? tierDelta : '' )
        }
      </div>

      <div className='minw1-5 pl-2'>
        { editPos ? (
          <input
            id='editTierDte'
            type='number'
            className='input-base w-10 dark:bg-medium-grey text-center'
            value={tierDte || ''}
            onChange={onChangeDte}
            onKeyDown={onKeyDownSave}
          />
          ) : ( (tierDte && tierDte > 0) ? tierDte : '' )
        }
      </div>

    </div>
  )
}

export default TierRow
