import { TickerGroup } from '../../types/rooStrat'
import { forEach, round, toString } from 'lodash'
import { updateToFirebase } from './firebase'
import { handleError } from '../../utilities/error-handling'

const API_BASE_URL = toString(process.env.REACT_APP_MARKETDATA_API_BASE_URL)
const TOKEN = process.env.REACT_APP_MARKETDATA_TOKEN

export const updateAllOptionsQuotes = async (tickerGroups: TickerGroup[] | undefined): Promise<void> => {
  if (!tickerGroups) { return }

  const updates: {[key: number | string]: number | string} = {}

  for (const groupIndex in tickerGroups) {
    const spreads = tickerGroups[groupIndex].spreads
    let newUnderlyingPrice = -1

    if (spreads) {
      try {
        for (const spreadIndex in spreads) {
          const spread = spreads[spreadIndex]
          const shortLegOccSymbol = spread.shortLeg.occSymbol || ''
          const longLegOccSymbol = spread.longLeg.occSymbol || ''

          try {
            const [shortLegOptionQuote, longLegOptionQuote] = await Promise.all([
              getOptionQuote({marketDataOccSymbol: shortLegOccSymbol}),
              getOptionQuote({marketDataOccSymbol: longLegOccSymbol}),
            ])

            const updatedShortLegSpread: any = {
              ...spread.shortLeg,
              dte: shortLegOptionQuote.dte[0],
              mark: shortLegOptionQuote.mid[0],
              delta: shortLegOptionQuote.delta[0],
              theta: shortLegOptionQuote.theta[0],
              strike: shortLegOptionQuote.strike[0],
              side: shortLegOptionQuote.side[0],
            }

            const updatedLongLegSpread: any = {
              ...spread.longLeg,
              dte: longLegOptionQuote.dte[0],
              mark: longLegOptionQuote.mid[0],
              delta: longLegOptionQuote.delta[0],
              theta: longLegOptionQuote.theta[0],
              strike: longLegOptionQuote.strike[0],
              side: longLegOptionQuote.side[0],
            }

            const sLegPath = `tickerGroups/${groupIndex}/spreads/${spreadIndex}/shortLeg`
            const lLegPath = `tickerGroups/${groupIndex}/spreads/${spreadIndex}/longLeg`

            forEach(updatedShortLegSpread, (val: string | number, key: string | number) => {
              updates[sLegPath + '/' + key] = val
            })
            forEach(updatedLongLegSpread, (val: string | number, key: string | number) => {
              updates[lLegPath + '/' + key] = val
            })

            if (newUnderlyingPrice !== shortLegOptionQuote.underlyingPrice[0]) {
              newUnderlyingPrice = shortLegOptionQuote.underlyingPrice[0]
              const pricePath = `tickerGroups/${groupIndex}/underlyingPrice`
              updates[pricePath] = newUnderlyingPrice
            }

          } catch (e) {
            handleError({ msg: 'Updating option quotes failed (1).', e })
          }
        }
      } catch (e) {
        handleError({ msg: 'Updating option quotes failed (2).', e })
      }
    }
  }

  await Promise.all(Object.values(updates))
  updates['dataLastUpdated'] = round(Date.now()/1000)
  await updateToFirebase({ refPath: '/rooStratPortfolio/', updates })
}


export const getOptionQuote = async ({marketDataOccSymbol}: {marketDataOccSymbol: string}) => {
  // const respOptionsArr = [
  //   'format=json',
  //   'dateformat=unix',
  //   'columns=ask,dte,delta,mark,openInterest,theta,strike,side,underlyingPrice',
  // ]
  // const responseOptions = respOptionsArr.join('&')
  const responseOptions = 'format=json&dateformat=unix'
  const url = `${API_BASE_URL}/options/quotes/${marketDataOccSymbol}/?${responseOptions}`

  try {
    const controller = new AbortController()
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        Authorization: `Token ${TOKEN}`
      },
      signal: controller.signal
    })
    if (!response.ok) {
      console.log('response: ', response)
      handleError({ msg: `Failed to fetch option quote for ${marketDataOccSymbol}. Error: !response.ok ... ${response}` })
    }
    const data = await response.json()
    return data

  } catch (e) {
    handleError({ msg: `Failed to fetch option quote for ${marketDataOccSymbol}.`, e })
  }
}
