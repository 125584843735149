import { cloneDeep, forEach, groupBy, isEmpty } from 'lodash'
import { FC, useEffect, useState } from 'react'
import uuid from 'react-uuid'
import CollapseSection from '../wrappers/CollapseSection'
import { TastyTickerGroup, TastyTickerGroups } from '../../types/tasty'
import { groupByPositionTypes, getValuesSpreads } from '../../utilities/tasty'
import TickerGroup from './TickerGroup'
import Spinner from '../pages-components/Spinner'
import PortfolioSummary from './PortfolioSummary'
import axios from 'axios'
import toast from 'react-hot-toast'
import { handleError } from '../../utilities/error-handling'
import { getUnixTime } from 'date-fns'
import { getUserId } from '../../services/api/firebase'
import { updateTastySpreadQuotesGreeks } from '../../store/userSlice'
import { useAppDispatch } from '../../store/hooks'
import UpdateOptionsQuotes from './UpdateOptionsQuotes'
import { HiOutlineArrowTrendingUp } from 'react-icons/hi2'

interface Props {
  tastyPositions: []
}

const SET_SPREAD_QUOTES_URL = process.env.REACT_APP_SET_SPREAD_QUOTES_URL as string
const QUOTE_UPDATE_TIMEOUT_SEC = 10 // Prevent too frequent update calls from MarketData.

const Positions: FC<Props> = ({ tastyPositions }) => {
  // TODO Save to FB userSettings.
  const [isExpanded, setIsExpanded] = useState(true)
  const [tickerGroups, setTickerGroups] = useState<TastyTickerGroups>({})
  const [quotesLastUpdated, setQuotesLastUpdated] = useState(0)

  const dispatch = useAppDispatch()

  const isPositions = isEmpty(tastyPositions) ? false : true
  const isTickerGroups = isEmpty(tickerGroups) ? false : true

  const parsePositions = () => {
    // Iterate through tastyPositions to build TickerGroups.
    const tickerGroupsBuild: {[key: string]: TastyTickerGroup} = {}
    const tastyPositionsByTicker: {[key: string]: any} = cloneDeep( groupBy(tastyPositions, 'underlying-symbol') )

    forEach(tastyPositionsByTicker, (positions, ticker: string) => {
      const positionTypes = groupByPositionTypes(positions)
      const spreads = getValuesSpreads(positionTypes.spreads)
      const ticketGroup: TastyTickerGroup = {
        ticker: ticker,
        spreads: spreads,
        // butterflies: butterflies,
      }
      tickerGroupsBuild[ticker] = ticketGroup
    })

    setTickerGroups(tickerGroupsBuild)
    // getSetMarketData(tickerGroupsBuild)
  }

  const getSetMarketData = (tickerGroupsBuild: TastyTickerGroups) => {
    const nowUnix = getUnixTime(new Date())

    if (nowUnix - QUOTE_UPDATE_TIMEOUT_SEC > quotesLastUpdated) {
      const toastId = toast.loading('Updating quotes and greeks.')
      const userId = getUserId()
      const data = { tickerGroups: tickerGroupsBuild, userId: userId }

      axios.post(SET_SPREAD_QUOTES_URL, data)
      .then(response => {
        dispatch( updateTastySpreadQuotesGreeks(response.data) )
        setQuotesLastUpdated(nowUnix)
        toast.success('Quotes and greeks updated.')
      })
      .catch(e => {
        handleError({ msg: 'Quotes and greeks update error.', e })
      })
      .finally(() => {
        toast.dismiss(toastId)
      })
    }
  }

  const collapseTitle = () => {
    return (
      <div className='flex flex-row items-center'>
        <HiOutlineArrowTrendingUp
          className={'action-icons-md mr-3 text-orange'}
        />
        <div>
          Positions
        </div>
      </div>
    )
  }

  const collapseTitleExtra = () => {
    return (
      <div className='pl-3'>
        <UpdateOptionsQuotes
          tickerGroups={tickerGroups}
          quotesLastUpdated={quotesLastUpdated}
          getSetMarketData={getSetMarketData}
        />
      </div>
    )
  }

  useEffect(() => {
    parsePositions()
  }, [tastyPositions])

  return (
    <CollapseSection
      title={ collapseTitle() }
      titleExtra={ collapseTitleExtra() }
      isExpanded={ isExpanded }
      setIsExpanded={ setIsExpanded }
    >

      {
        !isPositions &&
        <div className='mt-1 ml-2'>No Tasty positions ... time to get cookin!</div>
      }
      {
        !isTickerGroups &&
        <div className='flex flex-row justify-center items-center pt-6'>
          <Spinner fill='#445' size='3rem' />
        </div>

      }
      {
        isTickerGroups &&
        <>
          <PortfolioSummary tickerGroups={tickerGroups} />
          {
            Object.values(tickerGroups).map((group: TastyTickerGroup) => (
              <TickerGroup
                key={uuid()}
                tickerGroup={group}
              />
            ))
          }
        </>
      }

    </CollapseSection>
  )
}

export default Positions
