import { FC, useEffect } from 'react'
import { getAccountBalances, getAccounts, getAccountStreamer, getOrders, getPositions } from '../../services/api/tasty'
import { handleError } from '../../utilities/error-handling'
import { updateTastyAccountBalances, updateTastyLogin, updateTastyOrders, updateTastyPositions } from '../../store/userSlice'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { get, isEmpty, map } from 'lodash'
import { toast } from 'react-hot-toast'
import { Disposer } from '@tastytrade/api'
import { TastyAccountBalances } from '../../types/tasty'
import { mockPositions } from './mock-data/positions'
import { mockOrders } from './mock-data/orders'

const MOCKING = (process.env.REACT_APP_MOCKING && process.env.REACT_APP_MOCKING === 'true') ? true : false

const AccountStreaming: FC = () => {
  const tastyLogin = useAppSelector(state => state.userStore.user.tasty.login)

  const dispatch = useAppDispatch()

  const accountStreamer = getAccountStreamer()
  let messageObsDisposer: Disposer
  let streamerStateObsDisposer: Disposer

  const setupAccountStreaming = async () => {
    console.log('---->> setupAccountStreaming() <<----')
    try {
      const accountNumbers = await getAccountNumbersNow()
      await getAccountStreamerGoing(accountNumbers)
      await getTastyPositionsNow(accountNumbers)
      getTastyOrdersNow(accountNumbers)
      getAccountBalancesNow(accountNumbers)
      toast.success('Tasty accounts streaming.')
    } catch (e) {
      handleError({ msg: 'Could not setup Tasty account streaming.', e})
    }
  }

  const getAccountStreamerGoing = async (accountNumbers: string[]) => {
    try {
      if ( tastyLogin?.isLoggedIn && !isEmpty(accountNumbers) ) {
        await accountStreamer.start()
        await accountStreamer.subscribeToAccounts(accountNumbers)
        messageObsDisposer = accountStreamer.addMessageObserver(handleStreamerMessage)
        streamerStateObsDisposer = accountStreamer.addStreamerStateObserver(handleStreamerStateChange)
        toast.success('Tasty account now streaming.')
      }
    } catch (e) {
      handleError({ msg: 'Could not get Tasty account streaming.', e})
      cleanup() }
  }

  const getAccountNumbersNow = async (): Promise<string[]> => {
    try {
      const getAccountsResponse = await getAccounts()
      console.log('-->> getAccountsResponse: ', getAccountsResponse)
      if (getAccountsResponse && getAccountsResponse.length > 0) {
        const acctNums = map(getAccountsResponse, account => get(account, 'account.account-number'))
        dispatch( updateTastyLogin({ accountNumbers: acctNums }) )
        toast.success('Got Tasty account numbers.')
        return acctNums
      }
    } catch (e) {
      dispatch( updateTastyLogin({ accountNumbers: [] }) )
      handleError({ msg: 'Could not get Tasty account numberss.', e})
      return []
    }
    return []
  }

  const getTastyPositionsNow = async (accountNumbers: string[]) => {
    try {
      if (accountStreamer.isOpen && tastyLogin && !isEmpty(accountNumbers)) {
        let pos = await getPositions(accountNumbers[0])
        console.log('-->> pos: ', pos)
        dispatch( updateTastyPositions(pos) )
        toast.success('Got Tasty positions ... yummy!')
      }
    } catch (e) {
      handleError({ msg: 'Could not get Tasty positions.', e})
    }
  }

  const getTastyOrdersNow = async (accountNumbers: string[]) => {
    try {
      if (accountStreamer.isOpen && !isEmpty(accountNumbers)) {
        const orders = await getOrders(accountNumbers[0])
        console.log('-->> orders: ', orders)
        dispatch( updateTastyOrders(orders) )
        toast.success('Got Tasty orders ... yummy!')
      }
    } catch (e) {
      handleError({ msg: 'Could not get Tasty orders.', e})
    }
  }

  const getAccountBalancesNow = async (accountNumbers: string[]) => {
    try {
      if (accountStreamer.isOpen && !isEmpty(accountNumbers)) {
        const accBal = await getAccountBalances(accountNumbers[0])
        console.log('-->> accBal: ', accBal)
        dispatch( updateTastyAccountBalances(
          {
            accountNumber            : accBal['account-number'],
            netLiquidatingValue      : accBal['net-liquidating-value'],
            cashBalance              : accBal['cash-balance'],
            cashAvailableToWithdraw  : accBal['cash-available-to-withdraw'],
            equityBuyingPower        : accBal['equity-buying-power'],
            derivativeBuyingPower    : accBal['derivative-buying-power'],
            usedDerivativeBuyingPower: accBal['used-derivative-buying-power'],
          } as TastyAccountBalances
        ))
        toast.success('Got Tasty account balances.')
      }
    } catch (e) {
      handleError({ msg: 'Could not get Tasty account balances.', e})
    }
  }

  const handleStreamerMessage = (message: any) => {
    console.log('----->> streamer message received: ', message)
    if (message.action !== 'heartbeat') {
      console.log('-->> message.action: ', message.action)
    }
  }

  const handleStreamerStateChange = (streamerState: any) => {
    console.log('---------->>> streamer STATE CHANGED <<<----------: ', streamerState)
  }

  const cleanup = () => {
    console.log('-->> Cleaning up ...')
    accountStreamer.stop()
    messageObsDisposer && messageObsDisposer()
    streamerStateObsDisposer && streamerStateObsDisposer()
  }

  useEffect(() => {
    if (tastyLogin && tastyLogin.isLoggedIn) {
      if (MOCKING) {
        dispatch( updateTastyPositions(mockPositions) )
        dispatch( updateTastyOrders(mockOrders) )
        return
      }
      setupAccountStreaming()
    } else {
      cleanup()
    }
  }, [tastyLogin?.isLoggedIn])

  useEffect(() => {
    console.log('\n<-- Setup and Teardown useEffect[] -->')
    return () => {
      cleanup()
    }
  }, [])

  return null
}

export default AccountStreaming
