import { format } from 'date-fns'
import { IJournal, JournalEntry } from '../types'

export class JournalClass implements IJournal {
  date: string
  entries: JournalEntry[]
  id: string

  constructor() {
    this.date = format(new Date(), 'MM-dd-yy')
    this.entries = []
    this.id = ''
  }

  defaultJournal(date:string) {
    return {
      date: date,
      entries: [
        {
          title: 'Economic News',
          notes: [],
          type: 'premarket',
          id: ''
        },
        {
          title: 'Notes',
          notes: [],
          type: 'premarket',
          id: ''
        },
        {
          title: 'Notes',
          notes: [],
          type: 'postmarket',
          id: ''
        },
        // {
        //   title: 'Day\'s Focus Mantra',
        //   notes: [],
        //   type: 'premarket',
        //   id: ''
        // },
        // // {
        // //   title: 'Major World Events',
        // //   notes: [],
        // //   type: 'premarket',
        // //   id: ''
        // // },
        // {
        //   title: 'Economic News Releases',
        //   notes: [],
        //   type: 'premarket',
        //   id: ''
        // },
        // {
        //   title: 'Ticker "3 Month Trends"',
        //   notes: [],
        //   type: 'premarket',
        //   id: ''
        // },
        // {
        //   title: 'Which positions need special attention today?',
        //   notes: [],
        //   type: 'premarket',
        //   id: ''
        // },
        // {
        //   title: 'My goals for today are...',
        //   notes: [],
        //   type: 'premarket',
        //   id: ''
        // },


        // {
        //   title: 'Focus, Concentration & Feelings',
        //   notes: [],
        //   type: 'postmarket',
        //   id: ''
        // },
        // {
        //   title: 'Self-Analysis',
        //   notes: [],
        //   type: 'postmarket',
        //   id: ''
        // },
        // {
        //   title: 'What did I learn today?',
        //   notes: [],
        //   type: 'postmarket',
        //   id: ''
        // },
        // // {
        // //   title: 'Which positions need special attention tomorrow?',
        // //   notes: [],
        // //   type: 'postmarket',
        // //   id: ''
        // // },
        // // {
        // //   title: 'My goal for tomorrow is...',
        // //   notes: [],
        // //   type: 'postmarket',
        // //   id: ''
        // // },
      ]
    }
  }
}