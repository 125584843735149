import { Spread, Butterfly, Leg, TastyPositionTypes } from './../types/tasty'
import { capitalize, ceil, filter, find, floor, forEach, groupBy, isEmpty, keys, sumBy, toNumber, toString, values } from 'lodash'
import { getUnixTime } from 'date-fns'
import { parseTastyOcc } from './occSymbol'

export const filterAndGroupOptions = (
  {
    tickerPrice,
    entireOptionChain,
    MAX_DTE_DAYS,
  }: {
    tickerPrice: number,
    entireOptionChain: never[],
    MAX_DTE_DAYS: number,
  }
  ) => {
    const strikeRange = tickerPrice < 50 ? 0.26 : 0.15
    const strikeLow = floor(tickerPrice * (1 - strikeRange))
    const strikeHigh = ceil(tickerPrice * (1 + strikeRange))

    const dteOptionChain = filter(entireOptionChain, o => {
      return o['days-to-expiration'] < MAX_DTE_DAYS &&
             o['strike-price'] > strikeLow &&
             o['strike-price'] < strikeHigh
    } )

    return groupBy(dteOptionChain, 'expiration-date')
}

export const groupByPositionTypes = (positions: any): TastyPositionTypes => {
  const finalAnswer
  : { spreads: Spread[][], butterflies: Butterfly[][], other: any[][] }
  = { spreads: [], butterflies: [], other: [] }

  if (isEmpty(positions)) return finalAnswer

  const TOL_SECS = 1
  const byCreatedAt: {[key: string]: any[]} = {}
  const spreadsContainer: any[] = []
  const butterfliesContainer: any[] = []
  const othersContainer: any[] = []

  // Group positions by 'created-at' time +/- TOL_SECS
  for (const position of positions) {
    const createTimeNum = getUnixTime(new Date(position['created-at']))
    const startTime = createTimeNum - TOL_SECS
    const endTime = createTimeNum + TOL_SECS
    const createTimeStr = toString(createTimeNum)

    const existingKey = find(keys(byCreatedAt), key => {
      const keyNum = toNumber(key)
      return startTime <= keyNum && keyNum <= endTime
    })

    if (!existingKey) {
      byCreatedAt[createTimeStr] = [position]
    } else {
      byCreatedAt[existingKey].push(position)
    }

  }
  // console.log('byCreatedAt final: ', byCreatedAt)

  // Group into spreads, butterflies and other
  forEach(byCreatedAt, (positions) => {
    if (positions.length === 2) {
      spreadsContainer.push(positions)
    } else
    if (positions.length === 4) {
      butterfliesContainer.push(positions)
    } else {
      othersContainer.push(positions)
    }
  })

  // Set 'position-id' for each spreadLegs pair.
  forEach(spreadsContainer, (spreadLegs: any) => {
    const legs = getShortLongLegs(spreadLegs)
    const shortLeg = legs.shortLeg
    const longLeg = legs.longLeg
    const expiryDate = shortLeg['expires-at']
    const shortStrike = shortLeg['symbol'].substring(13)
    const longStrike = longLeg['symbol'].substring(13)
    shortLeg['position-id'] = `${expiryDate}${shortStrike}${longStrike}`
    longLeg['position-id'] = `${expiryDate}${shortStrike}${longStrike}`
    spreadLegs['position-id'] = `${expiryDate}${shortStrike}${longStrike}`
  })
  // console.log('spreadsContainer: ', spreadsContainer)

  // Group speads by 'position-id'
  const x = values(groupBy(spreadsContainer, (s: any) => s['position-id']))
  // console.log('x: ', x)
  finalAnswer.spreads = x

  // console.log('finalAnswer: ', finalAnswer)
  return finalAnswer

}

export const getValuesSpreads = (spreads: any): Spread[][] => {
  const finalAnswer: Spread[][] = []
  if (isEmpty(spreads)) return finalAnswer

  for (const spreadArr of spreads) {
    const spreadArrContainer = []
    for (const vertical of spreadArr) {
      const spreadObj = getSpreadValues(vertical)
      spreadArrContainer.push(spreadObj)
    }
    finalAnswer.push(spreadArrContainer)
  }

  return finalAnswer
}

const getSpreadValues = (vertical: Spread[]): Spread => {
  if (isEmpty(vertical)) return { shortLeg: {}, longLeg: {} }
  const legs = getShortLongLegs(vertical)
  const sLegVals = getLegValues(legs.shortLeg)
  const lLegVals = getLegValues(legs.longLeg)
  const ticker = sLegVals.occTasty?.split(' ')[0]
  const isHedge = sLegVals.price && lLegVals.price && sLegVals.price + lLegVals.price < 0 ? true : false
  const sLegSide = capitalize(sLegVals.side)
  const isBullish = (isHedge && sLegSide === 'Put') || (!isHedge && sLegSide === 'Call') ? false : true
  const bullOrBear = isBullish ? 'Bull' : 'Bear'
  // const description = `${sLegVals.occExpDate} (${sLegVals.dte}) ${sLegVals.strike} ${bullOrBear} ${sLegSide} ${isHedge ? 'Hedge' : ''}`
  const description = `${sLegVals.occExpDate} ${sLegVals.strike} ${bullOrBear} ${sLegSide}`
  const fbId = `${ticker}${sLegVals.occExpDate}${sLegVals.strike}${bullOrBear}${sLegSide}${isHedge ? 'Hedge' : ''}`
  const fbIdNoSpaces = fbId.replace(' ', '').replace('.', '')

  return {
    description: description,
    fbSpreadQuotesGreeksId: fbIdNoSpaces,
    isHedge: isHedge,
    isBullish: isBullish,
    shortLeg: sLegVals,
    longLeg: lLegVals,
  } as Spread
}

const getLegValues = (leg: {[key: string]: any}): Leg => {
  const finalLeg: Leg = {}
  if (isEmpty(leg)) return finalLeg

  const occ = parseTastyOcc({ tastyOccSymbol: leg['symbol'] })
  const isShort = leg['quantity-direction'] === 'Short' ? true : false
  const qty = isShort ? -leg['quantity'] : leg['quantity']
  const price = isShort ? leg['average-open-price'] : -leg['average-open-price']

  finalLeg.occMarketData = occ.occMarketData
  finalLeg.occTasty = leg['symbol']
  finalLeg.occDisplay = occ.occDisplay
  finalLeg.occExpDate = occ.occExpDate
  finalLeg.contracts = qty
  finalLeg.price = toNumber(price)
  finalLeg.dte = occ.dte
  finalLeg.strike = occ.strike
  finalLeg.side = occ.side

  return finalLeg
}

const getShortLongLegs = (legs: {[key: string]: any}[]):
  { shortLeg: {[key: string]: any}, longLeg: {[key: string]: any} } => {
  const finalAnswer = { shortLeg: {}, longLeg: {} }
  if (isEmpty(legs)) return finalAnswer
  if (legs[0]['quantity-direction'] === 'Short') {
    finalAnswer.shortLeg = legs[0]
    finalAnswer.longLeg = legs[1]
  } else {
    finalAnswer.shortLeg = legs[1]
    finalAnswer.longLeg = legs[0]
  }
  return finalAnswer
}

export const compressSpreads = (spreads: Spread[]) => {
  const totContracts = sumBy(spreads, 'shortLeg.contracts')
  const aveShortLegPrice = sumBy(spreads, (spread: Spread) => toNumber(spread.shortLeg.price) * (toNumber(spread.shortLeg.contracts) / totContracts) )
  const aveLongLegPrice = sumBy(spreads, (spread: Spread) => toNumber(spread.longLeg.price) * (toNumber(spread.longLeg.contracts) / -totContracts) )

  return [{
    description: spreads[0].description,
    fbSpreadQuotesGreeksId: spreads[0].fbSpreadQuotesGreeksId,
    isHedge: false,
    isBullish: spreads[0].isBullish,
    shortLeg: {
      contracts: totContracts,
      price: aveShortLegPrice,
      dte: spreads[0].shortLeg.dte,
      strike: spreads[0].shortLeg.strike,
      side: spreads[0].shortLeg.side,
      mark: spreads[0].shortLeg.mark,
      delta: spreads[0].shortLeg.delta,
      theta: spreads[0].shortLeg.theta,
    },
    longLeg: {
      contracts: totContracts,
      price: aveLongLegPrice,
      strike: spreads[0].longLeg.strike,
      side: spreads[0].longLeg.side,
      mark: spreads[0].longLeg.mark,
      delta: spreads[0].longLeg.delta,
      theta: spreads[0].longLeg.theta,
    }
  }]
}
