import { BaseSyntheticEvent, FC, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { HiAdjustmentsHorizontal } from 'react-icons/hi2'
import { setToFirebase, updateToFirebase } from '../../services/api/firebase'
import { RooStratSettings } from '../../types/rooStrat'
import { toLocalDecimals } from '../../utilities/general'

interface Props {
  settings: RooStratSettings
}

const PortfolioSettings: FC<Props> = ({ settings }) => {
  const [showSettings, setShowSettings] = useState(false)
  const [accEq, setAccEq] = useState<number>(0)
  const [maxPosSz, setMaxPosSz] = useState<number>(0)
  const [deltaBalHigh, setDeltaBalHigh] = useState<number>(0)
  const [deltaBalLow, setDeltaBalLow] = useState<number>(0)
  const [thetaBalHigh, setThetaBalHigh] = useState<number>(0)
  const [thetaBalLow, setThetaBalLow] = useState<number>(0)

  const [showAccEqInput, setShowAccEqInput] = useState(false)
  const [showMaxPosSzInput, setShowMaxPosSzInput] = useState(false)
  const [showDeltaBalLowInput, setShowDeltaBalLowInput] = useState(false)
  const [showDeltaBalHighInput, setShowDeltaBalHighInput] = useState(false)
  const [showThetaBalLowInput, setShowThetaBalLowInput] = useState(false)
  const [showThetaBalHighInput, setShowThetaBalHighInput] = useState(false)


  const onChangeAccEq = (e: BaseSyntheticEvent) => { setAccEq(e.target.value) }
  const onChangeMaxPosSz = (e: BaseSyntheticEvent) => { setMaxPosSz(e.target.value) }
  const onChangeDeltaBalLow = (e: BaseSyntheticEvent) => { setDeltaBalLow(e.target.value) }
  const onChangeDeltaBalHigh = (e: BaseSyntheticEvent) => { setDeltaBalHigh(e.target.value) }
  const onChangeThetaBalLow = (e: BaseSyntheticEvent) => { setThetaBalLow(e.target.value) }
  const onChangeThetaBalHigh = (e: BaseSyntheticEvent) => { setThetaBalHigh(e.target.value) }

  const toggleShowAccEqInput = async ({forceSave = false}) => {
    const newShow = showAccEqInput ? false : true
    setShowAccEqInput(newShow)
    if (forceSave && newShow === false && accEq !== settings.accountEquity) {
      try {
        await setToFirebase({ refPath: 'rooStratPortfolio/settings/accountEquity', value: accEq })
      } catch (e) {
        console.error(`PortfolioSummary.tsx - setShowAccEqInput() ${e}`)
        toast.error('NOT saved.')
      }
    }
  }

  const toggleShowMaxPosSzInput = async ({forceSave = false}) => {
    const newShow = showMaxPosSzInput ? false : true
    setShowMaxPosSzInput(newShow)
    if (forceSave && newShow === false && maxPosSz !== settings.maxPositionSize) {
      try {
        await setToFirebase({ refPath: 'rooStratPortfolio/settings/maxPositionSize', value: maxPosSz })
      } catch (e) {
        console.error(`PortfolioSummary.tsx - toggleShowMaxPosSzInput() ${e}`)
        toast.error('NOT saved.')
      }
    }
  }

  const toggleShowDeltaBalHighInput = async ({forceSave = false}) => {
    const newShow = showDeltaBalHighInput ? false : true
    setShowDeltaBalHighInput(newShow)
    if (forceSave && newShow === false && deltaBalHigh !== settings.deltaBalanceHigh) {
      try {
        await setToFirebase({ refPath: 'rooStratPortfolio/settings/deltaBalanceHigh', value: deltaBalHigh })
      } catch (e) {
        console.error(`PortfolioSummary.tsx - toggleShowDeltaBalHighInput() ${e}`)
        toast.error('NOT saved.')
      }
    }
  }

  const toggleShowDeltaBalLowInput = async ({forceSave = false}) => {
    const newShow = showDeltaBalLowInput ? false : true
    setShowDeltaBalLowInput(newShow)
    if (forceSave && newShow === false && deltaBalLow !== settings.deltaBalanceLow) {
      try {
        await setToFirebase({ refPath: 'rooStratPortfolio/settings/deltaBalanceLow', value: deltaBalLow })
      } catch (e) {
        console.error(`PortfolioSummary.tsx - toggleShowDeltaBalLowInput() ${e}`)
        toast.error('NOT saved.')
      }
    }
  }

  const toggleShowThetaBalHighInput = async ({forceSave = false}) => {
    const newShow = showThetaBalHighInput ? false : true
    setShowThetaBalHighInput(newShow)
    if (forceSave && newShow === false && thetaBalHigh !== settings.thetaBalanceHigh) {
      try {
        await setToFirebase({ refPath: 'rooStratPortfolio/settings/thetaBalanceHigh', value: thetaBalHigh })
      } catch (e) {
        console.error(`PortfolioSummary.tsx - toggleShowThetaBalHighInput() ${e}`)
        toast.error('NOT saved.')
      }
    }
  }

  const toggleShowThetaBalLowInput = async ({forceSave = false}) => {
    const newShow = showThetaBalLowInput ? false : true
    setShowThetaBalLowInput(newShow)
    if (forceSave && newShow === false && thetaBalLow !== settings.thetaBalanceLow) {
      try {
        await setToFirebase({ refPath: 'rooStratPortfolio/settings/thetaBalanceLow', value: thetaBalLow })
      } catch (e) {
        console.error(`PortfolioSummary.tsx - toggleShowThetaBalLowInput() ${e}`)
        toast.error('NOT saved.')
      }
    }
  }

  const onKeyPressSaveToFb = async (e: BaseSyntheticEvent | any) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      if (e.key === 'Enter' || e.key === 'Tab') {
        const updates: {[key: number | string]: number | string} = {}
        updates['accountEquity'] = accEq
        updates['maxPositionSize'] = maxPosSz
        updates['deltaBalanceHigh'] = deltaBalHigh
        updates['deltaBalanceLow'] = deltaBalLow
        updates['thetaBalanceHigh'] = thetaBalHigh
        updates['thetaBalanceLow'] = thetaBalLow
        try {
          await updateToFirebase({ refPath: 'rooStratPortfolio/settings', updates})
          if (showAccEqInput) setShowAccEqInput(false)
          if (showMaxPosSzInput) setShowMaxPosSzInput(false)
          if (showDeltaBalHighInput) setShowDeltaBalHighInput(false)
          if (showDeltaBalLowInput) setShowDeltaBalLowInput(false)
          if (showThetaBalHighInput) setShowThetaBalHighInput(false)
          if (showThetaBalLowInput) setShowThetaBalLowInput(false)
        } catch (e) {
          console.error(`PortfolioSummary.tsx - onKeyPressSaveToFb() ${e}`)
          toast.error('NOT saved.')
        }
      }
    }
  }

  useEffect(() => {
    if (settings) {
      setAccEq(settings.accountEquity)
      setMaxPosSz(settings.maxPositionSize)
      setDeltaBalHigh(settings.deltaBalanceHigh)
      setDeltaBalLow(settings.deltaBalanceLow)
      setThetaBalHigh(settings.thetaBalanceHigh)
      setThetaBalLow(settings.thetaBalanceLow)
    }
  }, [settings])

  return (
    <div className='flex items-center pos-font pl-4'>

      <div>
        <HiAdjustmentsHorizontal
          className={`action-icons-md mr-3 mt2px ${showSettings ? 'text-success' : 'text-orange'}`}
          onClick={() => setShowSettings(!showSettings)}
        />
      </div>

      { showSettings &&
        <>
          <div className='text-right cursor-pointer'
            onClick={() => toggleShowAccEqInput({forceSave: true})}>
              Acc Equity
          </div>
          <div className='text-left pl-2 cursor-pointer' onClick={() => setShowAccEqInput(true)}>
            {showAccEqInput ? (
              <input
                id='accEqInput'
                type='number'
                className='input-wide'
                value={accEq}
                onChange={onChangeAccEq}
                onKeyDown={onKeyPressSaveToFb}
                autoFocus
              />
              ) : ( toLocalDecimals(accEq, 0) )
            }
          </div>

          <div className='pl-8 cursor-pointer'
            onClick={() => toggleShowMaxPosSzInput({forceSave: true})}>
              Max pos sz
          </div>
          <div className='pl-2 cursor-pointer' onClick={() => setShowMaxPosSzInput(true)}>
            {showMaxPosSzInput ? (
              <input
                id='maxPosSzInput'
                type='number'
                className='input-base'
                value={maxPosSz}
                onChange={onChangeMaxPosSz}
                onKeyDown={onKeyPressSaveToFb}
                autoFocus
              />
              ) : ( maxPosSz )
            }%
          </div>

          <div className='flex-col'>
            <div className='flex'>
              <div
                className='pl-8 cursor-pointer'
                onClick={() => {
                  toggleShowDeltaBalHighInput({forceSave: true})
                  toggleShowDeltaBalLowInput({forceSave: true})}}>
                  ∆ Bal Range
              </div>
              <div className='text-left pl-2 cursor-pointer whitespace-nowrap'>
                <span onClick={() => setShowDeltaBalLowInput(true)}>
                  {showDeltaBalLowInput ? (
                    <input
                      id='deltaBalLowInput'
                      type='number'
                      className='input-wide'
                      value={deltaBalLow}
                      onClick={() => setShowDeltaBalLowInput(true)}
                      onChange={onChangeDeltaBalLow}
                      onKeyDown={onKeyPressSaveToFb}
                    />
                    ) : `${deltaBalLow > 0 ? '+' : ''}${toLocalDecimals(deltaBalLow, 2)}`
                  }%
                </span>
                <span className='cursor-default whitespace-nowrap px-1'> to </span>
                <span onClick={() => setShowDeltaBalHighInput(true)}>
                  {showDeltaBalHighInput ? (
                    <input
                      id='deltaBalHighInput'
                      type='number'
                      className='input-wide'
                      value={deltaBalHigh}
                      onChange={onChangeDeltaBalHigh}
                      onKeyDown={onKeyPressSaveToFb}
                      autoFocus
                    />
                    ) : `${deltaBalHigh > 0 ? '+' : ''}${toLocalDecimals(deltaBalHigh, 2)}`
                  }%
                </span>
              </div>
            </div>
            <div className='flex'>
              <div
                className='pl-8 cursor-pointer'
                onClick={() => {
                  toggleShowThetaBalHighInput({forceSave: true})
                  toggleShowThetaBalLowInput({forceSave: true})}}>
                  θ Bal Range
              </div>
              <div className='text-left pl-2 cursor-pointer whitespace-nowrap'>
                <span onClick={() => setShowThetaBalLowInput(true)}>
                  {showThetaBalLowInput ? (
                    <input
                      id='thetaBalLowInput'
                      type='number'
                      className='input-wide'
                      value={thetaBalLow}
                      onClick={() => setShowThetaBalLowInput(true)}
                      onChange={onChangeThetaBalLow}
                      onKeyDown={onKeyPressSaveToFb}
                    />
                    ) : `${thetaBalLow > 0 ? '+' : ''}${toLocalDecimals(thetaBalLow, 2)}`
                  }%
                </span>
                <span className='cursor-default whitespace-nowrap px-1'> to </span>
                <span onClick={() => setShowThetaBalHighInput(true)}>
                  {showThetaBalHighInput ? (
                    <input
                      id='thetaBalHighInput'
                      type='number'
                      className='input-wide'
                      value={thetaBalHigh}
                      onChange={onChangeThetaBalHigh}
                      onKeyDown={onKeyPressSaveToFb}
                      autoFocus
                    />
                    ) : `${thetaBalHigh > 0 ? '+' : ''}${toLocalDecimals(thetaBalHigh, 2)}`
                  }%
                </span>
              </div>
            </div>
          </div>
        </>
      }

    </div>
  )
}

export default PortfolioSettings
