import { FC } from 'react'
import { HiArrowPath } from 'react-icons/hi2'
import { updateAllOptionsQuotes } from '../../services/api/market-data-option-quotes'
import { TickerGroup } from '../../types/rooStrat'
import { format, fromUnixTime } from 'date-fns'
import toast from 'react-hot-toast'

interface Props {
  tickerGroups: TickerGroup[]
  lastUpdated: number | undefined
}

const UpdateOptionsData: FC<Props> = ({ tickerGroups, lastUpdated }) => {
  const updateTime = lastUpdated ? format(fromUnixTime(lastUpdated), 'MM/dd HH:mm') : ''

  const updateQuotes = async () => {
    const toastId = toast.loading('Updating all options quotes ...')
    try {
      await updateAllOptionsQuotes(tickerGroups)
      toast.success('All options quotes updated.')
    } catch (e) {
      console.error(`Tracker.tsx - updateQuotes() - ${e}`)
    } finally {
      toast.dismiss(toastId)
    }
}

  return (
    <div className='flex items-center cursor-pointer' onClick={updateQuotes}>

      <HiArrowPath
        title='Update options data.'
        className='action-icons-md text-orange'
      />

      <div className='pl-2 cursor-pointer pos-font'>
        { updateTime }
      </div>

    </div>
  )
}

export default UpdateOptionsData
