import { FC } from 'react'
import Body from './Body'
import Footer from './Footer'
import Header from './Header'
import { Toaster } from 'react-hot-toast'
import AccountStreaming from '../components/tasty/AccountStreaming'
import TenYearRateManager from '../components/tasty/TenYearRateManager'
import DataStreaming from '../components/tasty/DataStreaming'
import { useAppSelector } from '../store/hooks'

const Landing: FC = () => {

  const tastyLogin = useAppSelector(state => state.userStore.user.tasty.login)

  return (
    <>
      <Toaster
        position='top-right'
        toastOptions={{
          className: 'dark:bg-dark-grey dark:text-white',
          duration: 5000,
        }}
        containerStyle={{
          top: 60,
        }}
      />

      {
        tastyLogin && tastyLogin.isLoggedIn &&
        <>
          <AccountStreaming />
          <DataStreaming />
          <TenYearRateManager />
        </>
      }

      <Header />
      <Body />
      <Footer />
    </>
  )
}

export default Landing
