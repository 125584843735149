import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { onAuthStateChanged, User as fbUser } from 'firebase/auth'
import { get, ref } from 'firebase/database'
import { auth } from '../firebaseAssets'
import { newUserFbData } from '../defaultData'
import { db } from '../firebaseAssets'
import Spinner from '../components/pages-components/Spinner'
import LoginElipsis from '../components/auth-login/LoginElipsis'
import { User } from '../types'
import { setToFirebase } from '../services/api/firebase'
import toast from 'react-hot-toast'
// import Logo from '../components/icons-logo/Logo'
// import LoginEmail from '../components/auth-login/LoginEmail'
// import LoginSocial from '../components/auth-login/LoginSocial'
// import Privacy from '../components/subpages/Privacy'

const Home: FC = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)

  const processNewUser = async (fbUser: fbUser) => {
    const userRef = ref(db, `users/${fbUser.uid}`)

    try {
      const snapshot = await get(userRef)
      const userData: User | null = snapshot.val()

      if (userData === null || !userData.settings) {
        await setToFirebase({ refPath: 'settings', value: newUserFbData.settings })
        toast.success('New user settings set.')
      }

      if (userData === null || !userData.rooStratPortfolio) {
        await setToFirebase({ refPath: 'rooStratPortfolio', value: newUserFbData.rooStratPortfolio })
        toast.success('New user Roo Strat data set.')
      }
    } catch (error) {
      console.log('Home.tsx - processNewUser() - error: ', error)
    }
  }
  // const processNewUser = (fbUser: fbUser) => {
  //   const userRef = ref(db, `users/${fbUser.uid}`)
  //   get(userRef)
  //     .then(snapshot => {
  //       const userData: User | null = snapshot.val()
  //       if (userData === null || !userData.settings) {
  //         setToFirebase({ refPath: 'settings', value: newUserFbData.settings })
  //       }
  //       if (userData === null || !userData.rooStratPortfolio) {
  //         setToFirebase({ refPath: 'rooStratPortfolio', value: newUserFbData.rooStratPortfolio })
  //       }
  //     })
  //     .catch(e => {
  //       console.log('Home.tsx - processNewUser() - error: ', e)
  //     })
  // }

  useEffect(() => {
    const unsubOnAuth = onAuthStateChanged(auth, fbUser => {
      if (fbUser) {
        processNewUser(fbUser)
        setIsLoading(false)
        navigate('/')
      } else {
        setIsLoading(false)
      }
    })

    return () => {
      unsubOnAuth()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return (
      <div className='flex flex-row justify-center items-center pt-20'>
        <Spinner size='4rem' fill='#fb923c' />
      </div>
    )
  }

  return (
    <>
      <p className='text-2xl text-center mt-10'>Under construction<LoginElipsis /></p>

      {/* <div className='h-20 flex justify-center items-center border-b border-light-grey bg-header dark:bg-dark-header'>
        <p className='text-5xl font-light'>Todo4Life</p>
      </div>
      <div className='p-4 flex flex-col items-center'>
        <div className='pt-2'>
          <Logo size='80px' />
        </div>

        <div className='w-9/12 mt-8 mb-4 border-t border-light-grey' />

        <p className='pt-4 text-xl text-center'>
          A web app designed to do one thing and do it exceptionally well.
        </p>
        <p className='pt-4 text-3xl text-center'>Organize searchable notes</p>
        <p className='pt-4 text-xl text-center'>
          Todo4Life is dedicated to remain simple with no bloat no mess no cost.
        </p>

        <div className='w-9/12 mt-10 mb-6 border-t border-light-grey' />
        <LoginSocial />

        <div className='w-5/12 mt-6 mb-6 border-t border-light-grey' />
        <LoginEmail />

        <div className='w-9/12 mt-10 mb-6 border-t border-light-grey' />
        <Privacy />

        <div className='w-9/12 h-1 mt-10 mb-4 border-b border-t border-light-grey' />
      </div> */}

    </>
  )
}

export default Home
