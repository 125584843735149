import { format, subDays } from 'date-fns'
import { cloneDeep } from 'lodash'
import { FC, useState } from 'react'
import toast from 'react-hot-toast'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { setToFirebase, getFromFirebase } from '../../services/api/firebase'
import { useAppSelector } from '../../store/hooks'
import { JournalClass } from '../../Classes/JournalClass'
import { auth, db } from '../../firebaseAssets'
import { onValue, ref } from 'firebase/database'
import { IJournal, JournalEntry } from '../../types'
import PreAndPostMarket from './PreAndPostMarket'
import { HiOutlineDocumentDuplicate } from 'react-icons/hi2'
import uuid from 'react-uuid'

const Journal: FC = () => {
  const [journalDate, setJournalDate] = useState<any>(new Date())
  const [preCollapsed, setPreCollapsed] = useState(false)
  const [postCollapsed, setPostCollapsed] = useState(false)
  const journal = useAppSelector(state => state.userStore.user.journal) || new JournalClass().defaultJournal(format(new Date(), 'MM-dd-yy'))
  const [selectedJournal, setSelectedJournal] = useState<IJournal>(journal)

  const premarketEntries = selectedJournal.entries.reduce((acc,curr) => {
    if (curr.type === 'premarket') {
      acc.push(curr)
    }
    return acc
  },[] as JournalEntry[])

  const postmarketEntries = selectedJournal.entries.reduce((acc,curr) => {
    if (curr.type === 'postmarket' && curr.title === 'Notes') {
      acc.push(curr)
    }
    return acc
  },[] as JournalEntry[])

  const setCollapsed = (preOrPost:string) => {
    preOrPost === 'pre'? setPreCollapsed(prev => !prev) : setPostCollapsed(prev => !prev)
  }

  const changeDate = (d:any) => {
    setJournalDate(d)
    const user = auth.currentUser
    if (user) {
      const userRef = ref(db, `users/${user.uid}`)
      onValue(userRef, snapshot => {
        const userData = snapshot.val()
        if (userData) {
          if (userData.journal) {
            // Get the selected dates journal or create a new one
            const selectedJournal = userData.journal[format(d, 'MM-dd-yy')] || new JournalClass().defaultJournal(format(d, 'MM-dd-yy'))
            setSelectedJournal(selectedJournal)
          }
        }
      })
    }
  }

  const onSaveEdit = async (id:string, note:string, idx:number) => {
    if (selectedJournal && selectedJournal.entries) {
      try {
        const clonedJournalEntries = cloneDeep(selectedJournal['entries']) || []
        const updatedJournalEntries = clonedJournalEntries.find(entry => id === entry.id)
        if (updatedJournalEntries) updatedJournalEntries.notes[idx] = note

        const path = `journal/${format(journalDate, 'MM-dd-yy')}`
        const updatedJournal =  {
          date: selectedJournal.date,
          entries: clonedJournalEntries,
        }
        setSelectedJournal(updatedJournal)

        await setToFirebase({ refPath: path, value: updatedJournal })
        toast.success('Journal entry saved.')

      } catch (e) {
        console.error(`Journal.tsx - onSaveEdit() ${e}`)
        toast.error('Journal entry NOT saved.')
      }
    }
  }

  const onDelete = async (id:string, idx:number) => {
    if (selectedJournal && selectedJournal.entries) {
      try {
        const clonedJournalEntries = cloneDeep(selectedJournal['entries']) || []
        const updatedJournalEntries = clonedJournalEntries.find(entry => id === entry.id)
        if (updatedJournalEntries) {
          updatedJournalEntries.notes.splice(idx,1)
        }

        const path = `journal/${format(journalDate, 'MM-dd-yy')}`
        const updatedJournal =  {
          date: selectedJournal.date,
          entries: clonedJournalEntries,
        }
        setSelectedJournal(updatedJournal)

        await setToFirebase({ refPath: path, value: updatedJournal })
        toast.success('Journal entry saved.')

      } catch (e) {
        console.error(`Journal.tsx - onDelete() ${e}`)
        toast.error('Journal entry NOT deleted.')
      }
    }
  }

  const updateSelectedJournalBeforeSaving = (type:string, title:string, note:string, id:string) => {
    if (selectedJournal && selectedJournal.entries) {
      const existingJournal = selectedJournal.date === format(journalDate, 'MM-dd-yy')
      const clonedJournalEntries = cloneDeep(selectedJournal['entries']) || []

      const updatedJournalEntries = clonedJournalEntries.find(entry => {
        // if new entry, id will be '' so return matching title/type
        if(entry.id === '' && title === entry.title && type === entry.type) {
          return entry
        } else {
          return id === entry.id  && type === entry.type
        }
      })

      if (existingJournal && updatedJournalEntries && updatedJournalEntries.notes && Array.isArray(updatedJournalEntries.notes) && updatedJournalEntries.notes.length > 0) {
        // notes array is not empty
        if (updatedJournalEntries.id === '' || !updatedJournalEntries.id) {
          // if id is default empty string, save the new one
          updatedJournalEntries.id = id
        }
        updatedJournalEntries?.notes.push(note)
      } else if (existingJournal && updatedJournalEntries) {
        // found the entry but the notes array doesn't exist
        updatedJournalEntries.id = id
        updatedJournalEntries.notes = []
        updatedJournalEntries.notes.push(note)
      }

      const updatedJournal =  {
        date: selectedJournal.date,
        entries: clonedJournalEntries,
      }

      setSelectedJournal(updatedJournal)
      return updatedJournal
    }
  }


  const onSave = async (type:string, title:string, note:string, id:string) => {
    try {
      const updatedJournal = updateSelectedJournalBeforeSaving(type, title, note, id)
      const path = `journal/${format(journalDate, 'MM-dd-yy')}`
      await setToFirebase({ refPath: path, value: updatedJournal })
      toast.success('Journal entry saved.')
    } catch (e) {
      console.error(`Journal.tsx - onSave() ${e}`)
      toast.error('Journal entry NOT saved.')
    }
  }

  const copyPrevDay = async () => {
    const prevDay = subDays(journalDate, 1)
    try {
      const path = `journal/${format(prevDay, 'MM-dd-yy')}`
      // Get prev journal from FB
      const prevDayJournal = await Promise.resolve(getFromFirebase({ refPath: path, isRootPath: false }))
      if (prevDayJournal !== null) {
        // give entries new UUIDs
        prevDayJournal.entries.map((entry:JournalEntry, idx:number) => {
          prevDayJournal.entries[idx].id = uuid()
          // dont copy econ news
          if (entry.title === 'Economic News') entry.notes = []
          return null
        })
        prevDayJournal.date = format(new Date(), 'MM-dd-yy')
        setSelectedJournal(prevDayJournal)

        const path = `journal/${format(journalDate, 'MM-dd-yy')}`
        await setToFirebase({ refPath: path, value: prevDayJournal })
        toast.success('Previous Day Copied')

      } else {
        const newJournal = new JournalClass().defaultJournal(format(prevDay, 'MM-dd-yy'))
        setSelectedJournal(newJournal)
      }

    } catch (e) {
      console.error(`Journal.tsx - copyPrevDay() ${e}`)
      toast.error('Unsuccessful attempt to copy previous day.')
    }
  }

  return (
    <div className='flex flex-col items-center'>
      <div className='pl-2 pr-2 pb-2 -mt-2 ml-auto mr-auto w-full max-w-5xl'>
        <div className='w-full flex items-center pl-2 pr-2 text-menu bg-transparent'>
          <DatePicker
            selected={journalDate}
            onChange={(date) => changeDate(date)}
            dateFormat='MM/dd  EEEE'
            className='bg-[transparent] text-white border-0'
            // wrapperClassName='bg-black'
          />
          <HiOutlineDocumentDuplicate
            title='Copy Previous Days Notes'
            className='action-icons-md text-orange'
            onClick={() => copyPrevDay()}
            />
        </div>

        <PreAndPostMarket
          preOrPost='premarket'
          entries={premarketEntries}
          isCollapsed={preCollapsed}
          setCollapsed={() => setCollapsed('pre')}
          onSave={(type:string, title:string, note: string, id:string) => onSave(type, title, note, id)}
          onSaveEdit={(id:string, note: string, idx:number) => onSaveEdit(id, note, idx)}
          onDelete={(id:string, idx:number)=>onDelete(id, idx)}
        />

        <PreAndPostMarket
          preOrPost='postmarket'
          entries={postmarketEntries}
          isCollapsed={postCollapsed}
          setCollapsed={() => setCollapsed('post')}
          onSave={(type:string, title:string, note: string, id:string) => onSave(type, title, note, id)}
          onSaveEdit={(id:string, note: string, idx:number) => onSaveEdit(id, note, idx)}
          onDelete={(id:string, idx:number)=>onDelete(id, idx)}
        />

      </div>
    </div>
  )
}

export default Journal
