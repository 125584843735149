import { BaseSyntheticEvent, FC, useState } from 'react'
import { HiMiniNoSymbol, HiOutlineCheckCircle, HiOutlineTrash } from 'react-icons/hi2'
import TextareaAutosize from 'react-textarea-autosize'
import { setJournalInputFocus } from '../../store/appSlice'
import store from '../../store'

interface Props {
  id: string
  note: string
  idx: number
  onSaveEdit: (id:string, note:string, idx:number) => void
  onCancelEdit: () => void
  onDelete: (id:string, idx:number) => void
}

const EditEntry: FC<Props> = ({ id, note, idx, onSaveEdit, onCancelEdit, onDelete }) => {
  const [newEntryNote, setNewEntryNote] = useState(note)
  const [editing, setEditing] = useState(true)
  // const [haltBlur, setHaltBlur] = useState(false)

  const onChangeEdit = (e: BaseSyntheticEvent) => {
    if(e.target.value === '\n') {
      // handle odd case where hitting enter key to save
      // causes a delayed "onChange" that sets this to "\n" after saving
      setNewEntryNote(prev => {
        if (prev === '') {
          return ''
        } else {
          return prev + e.target.value
        }
      })
    } else {
      setNewEntryNote(e.target.value)
    }
  }

  const onCancel = () => {
    // console.log('****** click cancel')
    // setHaltBlur(true)
    setEditing(false)
    setNewEntryNote(note)
    onCancelEdit()
    // setHaltBlur(false)
  }

  const onKeyDownEvent = (e: BaseSyntheticEvent | any) => {
    // setHaltBlur(true)
    if (e.shiftKey && e.key === 'Enter') {
      // shift+enter already creates newline
    } else if (!e.shiftKey && e.key === 'Enter') {
      onSaveEdit(id, newEntryNote, idx)
      setEditing(false)
    } else if (e.key === 'Escape') {
      onCancel()
    }
    // setHaltBlur(false)
  }

  const onClickSaveEdit = (id:string, note:string, idx:number) => {
    // console.log('****** click save')
    // setHaltBlur(true)
    onSaveEdit(id, newEntryNote, idx)
    setEditing(false)
    // setHaltBlur(false)
  }

  const onClickDelete = (id:string, idx:number) => {
    // console.log('****** click delete')
    // setHaltBlur(true)
    onDelete(id, idx)
    setEditing(false)
    setNewEntryNote('')
    store.dispatch(setJournalInputFocus(''))
    // setHaltBlur(false)
  }

  // const onBlur = (e:BaseSyntheticEvent, id:string, newEntryNote:string, idx:number) => {
    // tough to get right with timing of setHaltBlur when clicking icons
    // console.log('haltBlur 1', haltBlur)

    // setTimeout(() => {
    //   if (haltBlur) {
    //     console.log('halt the blur event 2', haltBlur)
    //     return
    //   } else {
    //     console.log('dont halt the blur event 3', haltBlur)
    //     // onSaveEdit(id, newEntryNote, idx)
    //   }
    // }, 1000)
  // }

  return (
    <div className='ml-1 mr-1 flex flex-inline items-justify'>
      <div className='w-full flex flex-inline'>
        <TextareaAutosize
          id={id}
          className='journal-input-full-wide border dark:border-0 leading-normal'
          value={newEntryNote}
          onChange={(e:BaseSyntheticEvent) => onChangeEdit(e)}
          onKeyDown={(e:BaseSyntheticEvent) => onKeyDownEvent(e)}
          // onBlur={(e:BaseSyntheticEvent) => onBlur(e, id, newEntryNote, idx)}
          // onFocus={()=> setFocus(id)}
          autoFocus
          minRows={3}
        />

        {editing && (
          <div className='flex flex-col items-justify'>
            <HiOutlineCheckCircle
              onClick={() => onClickSaveEdit(id, note, idx)}
              className='action-icons check-circle-sz text-success mb-2'
            />
            <HiMiniNoSymbol
              onClick={() => onCancel()}
              className='action-icons text-warn mb-2'
            />
            <HiOutlineTrash
              onClick={() => onClickDelete(id, idx)}
              className='action-icons text-warn'
            />
          </div>
        )}

      </div>
    </div>
  )
}

export default EditEntry