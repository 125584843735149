import { FC, useEffect } from 'react'
import { connectTastyDataStreamer } from '../../services/api/tasty'
import { handleError } from '../../utilities/error-handling'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { difference, groupBy, keys } from 'lodash'
import { toast } from 'react-hot-toast'
import { Disposer, MarketDataStreamer, MarketDataSubscriptionType } from '@tastytrade/api'
import { setTickerPrices } from '../../store/appSlice'

const MOCKING = (process.env.REACT_APP_MOCKING && process.env.REACT_APP_MOCKING === 'true') ? true : false
const THROTTLE_DELAY_SEC = 5

const DataStreaming: FC = () => {
  const dispatch = useAppDispatch()

  const tastyLogin = useAppSelector(state => state.userStore.user.tasty.login)
  const tastyPositions = useAppSelector(state => state.userStore.user.tasty.positions)

  const tastyDataStreamer = new MarketDataStreamer()
  let streamerSubscribedTickers: string[] = []
  let lastThrottleTimes: {[key: string]: any} = {}
  let tastyDataObsDisposer: Disposer
  let tastyErrorObsDisposer: Disposer

  const getDataStreamingGoing = async () => {
    console.log('--> getDataStreamingGoing()')
    try {
      await connectTastyDataStreamer(tastyDataStreamer)
      tastyDataObsDisposer = tastyDataStreamer.addDataListener(handleMarketDataReceived)
      tastyErrorObsDisposer = tastyDataStreamer.addErrorListener(handleMarketErrorReceived)
      toast.success('Tasty data streaming.')
    } catch (e) {
      handleError({ msg: 'Could not setup Tasty data streaming.', e})
    }
    finally {
      setTimeout(() => {
        console.log('-->> Disconnecting tastyDataStreamer ...')
        tastyDataStreamer.disconnect()
      }, 30000)
    }
  }

  const handleMarketDataReceived = (data: any) => {
    console.log('----->> handleMarketDataReceived()')
    // console.log('data: ', data)
    const now = new Date().getTime()
    data.data.forEach((data: any) => {
      const ticker = data.eventSymbol
      const lastTime = lastThrottleTimes.ticker ? lastThrottleTimes.ticker : 0
      if ( now - lastTime > THROTTLE_DELAY_SEC * 1000 ) {
        const mark = (data.askPrice + data.bidPrice) / 2
        console.log('-->> ticker: mark: ', ticker, ': ', mark)
        lastThrottleTimes[ticker] = now
        dispatch( setTickerPrices({ [ticker]: mark }) )
      }
    })
  }

  const handleMarketErrorReceived = (data: any) => {
    console.log('----->> handleMarketERRORReceived()  ERROR ERROR')
    console.log('data: ', data)
    handleError({ msg: 'Data streamer error received.', e: data })
  }

  const manageDataStreamingSubscriptions = async () => {
    console.log('-->> manageDataStreamingSubscriptions()')
    const tastyPositionsTickers = keys(groupBy(tastyPositions, 'underlying-symbol'))
    const removedTickers = difference(streamerSubscribedTickers, tastyPositionsTickers)
    const newTickers = difference(tastyPositionsTickers, streamerSubscribedTickers)
    newTickers.forEach(newTicker => {
      tastyDataStreamer.addSubscription(newTicker, { subscriptionTypes: [MarketDataSubscriptionType.Quote], channelId: 1 })
    })
    removedTickers.forEach(removedTicker => {
      tastyDataStreamer.removeSubscription(removedTicker, { subscriptionTypes: [MarketDataSubscriptionType.Quote], channelId: 1 })
    })
    streamerSubscribedTickers = tastyPositionsTickers
    console.log('   streamerSubscribedTickers: ', streamerSubscribedTickers)
  }

  const cleanup = () => {
    console.log('-->> Cleaning up ...')
    tastyDataStreamer.disconnect()
    tastyDataObsDisposer && tastyDataObsDisposer()
    tastyErrorObsDisposer && tastyErrorObsDisposer()
  }


  useEffect(() => {
    if (tastyLogin && tastyLogin.isLoggedIn) {
      if (MOCKING) {
        getDataStreamingGoing()
        return
      }
      getDataStreamingGoing()
    } else {
      cleanup()
    }
  }, [tastyLogin?.isLoggedIn])

  useEffect(() => {
    console.log('\n<-- tastyPositions :: manageDataStreamingSubscriptions useEffect[] -->')
    manageDataStreamingSubscriptions()
  }, [tastyPositions])

  useEffect(() => {
    console.log('\n<-- Setup and Teardown useEffect[] -->')
    return () => {
      cleanup()
    }
  }, [])


  return null
}

export default DataStreaming
