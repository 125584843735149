export const mockPositions: any = [
  {
      "account-number": "5WY24398",
      "symbol": "SPY   240301P00472500",
      "instrument-type": "Equity Option",
      "underlying-symbol": "SPY",
      "quantity": 1,
      "quantity-direction": "Long",
      "close-price": "0.0",
      "average-open-price": "1.93",
      "average-yearly-market-close-price": "1.93",
      "average-daily-market-close-price": "1.93",
      "multiplier": 100,
      "cost-effect": "Credit",
      "is-suppressed": false,
      "is-frozen": false,
      "restricted-quantity": 0,
      "expires-at": "2024-03-01T21:15:00.000+00:00",
      "realized-day-gain": "0.0",
      "realized-day-gain-effect": "None",
      "realized-day-gain-date": "2024-01-24",
      "realized-today": "0.0",
      "realized-today-effect": "None",
      "realized-today-date": "2024-01-24",
      "created-at": "2024-01-24T15:31:51.441+00:00",
      "updated-at": "2024-01-24T15:31:52.460+00:00"
  },
  {
      "account-number": "5WY24398",
      "symbol": "SPY   240301P00477500",
      "instrument-type": "Equity Option",
      "underlying-symbol": "SPY",
      "quantity": 1,
      "quantity-direction": "Short",
      "close-price": "0.0",
      "average-open-price": "2.66",
      "average-yearly-market-close-price": "2.66",
      "average-daily-market-close-price": "2.66",
      "multiplier": 100,
      "cost-effect": "Debit",
      "is-suppressed": false,
      "is-frozen": false,
      "restricted-quantity": 0,
      "expires-at": "2024-03-01T21:15:00.000+00:00",
      "realized-day-gain": "0.0",
      "realized-day-gain-effect": "None",
      "realized-day-gain-date": "2024-01-24",
      "realized-today": "0.0",
      "realized-today-effect": "None",
      "realized-today-date": "2024-01-24",
      "created-at": "2024-01-24T15:31:51.379+00:00",
      "updated-at": "2024-01-24T15:31:52.425+00:00"
  },
  {
      "account-number": "5WY24398",
      "symbol": "SPY   240301P00472500",
      "instrument-type": "Equity Option",
      "underlying-symbol": "SPY",
      "quantity": 3,
      "quantity-direction": "Long",
      "close-price": "0.0",
      "average-open-price": "1.93",
      "average-yearly-market-close-price": "1.93",
      "average-daily-market-close-price": "1.93",
      "multiplier": 100,
      "cost-effect": "Credit",
      "is-suppressed": false,
      "is-frozen": false,
      "restricted-quantity": 0,
      "expires-at": "2024-03-01T21:15:00.000+00:00",
      "realized-day-gain": "0.0",
      "realized-day-gain-effect": "None",
      "realized-day-gain-date": "2024-01-24",
      "realized-today": "0.0",
      "realized-today-effect": "None",
      "realized-today-date": "2024-01-24",
      "created-at": "2024-01-25T15:31:51.441+00:00",
      "updated-at": "2024-01-24T15:31:52.460+00:00"
  },
  {
      "account-number": "5WY24398",
      "symbol": "SPY   240301P00477500",
      "instrument-type": "Equity Option",
      "underlying-symbol": "SPY",
      "quantity": 3,
      "quantity-direction": "Short",
      "close-price": "0.0",
      "average-open-price": "2.66",
      "average-yearly-market-close-price": "2.66",
      "average-daily-market-close-price": "2.66",
      "multiplier": 100,
      "cost-effect": "Debit",
      "is-suppressed": false,
      "is-frozen": false,
      "restricted-quantity": 0,
      "expires-at": "2024-03-01T21:15:00.000+00:00",
      "realized-day-gain": "0.0",
      "realized-day-gain-effect": "None",
      "realized-day-gain-date": "2024-01-24",
      "realized-today": "0.0",
      "realized-today-effect": "None",
      "realized-today-date": "2024-01-24",
      "created-at": "2024-01-25T15:31:51.379+00:00",
      "updated-at": "2024-01-24T15:31:52.425+00:00"
  },
  {
      "account-number": "5WY24398",
      "symbol": "SPY   240301P00478000",
      "instrument-type": "Equity Option",
      "underlying-symbol": "SPY",
      "quantity": 1,
      "quantity-direction": "Short",
      "close-price": "0.0",
      "average-open-price": "2.7",
      "average-yearly-market-close-price": "2.7",
      "average-daily-market-close-price": "2.7",
      "multiplier": 100,
      "cost-effect": "Debit",
      "is-suppressed": false,
      "is-frozen": false,
      "restricted-quantity": 0,
      "expires-at": "2024-03-01T21:15:00.000+00:00",
      "realized-day-gain": "0.0",
      "realized-day-gain-effect": "None",
      "realized-day-gain-date": "2024-01-24",
      "realized-today": "0.0",
      "realized-today-effect": "None",
      "realized-today-date": "2024-01-24",
      "created-at": "2024-01-24T16:25:22.011+00:00",
      "updated-at": "2024-01-24T16:25:23.032+00:00"
  },
  {
      "account-number": "5WY24398",
      "symbol": "SPY   240301P00473000",
      "instrument-type": "Equity Option",
      "underlying-symbol": "SPY",
      "quantity": 1,
      "quantity-direction": "Long",
      "close-price": "0.0",
      "average-open-price": "1.97",
      "average-yearly-market-close-price": "1.97",
      "average-daily-market-close-price": "1.97",
      "multiplier": 100,
      "cost-effect": "Credit",
      "is-suppressed": false,
      "is-frozen": false,
      "restricted-quantity": 0,
      "expires-at": "2024-03-01T21:15:00.000+00:00",
      "realized-day-gain": "0.0",
      "realized-day-gain-effect": "None",
      "realized-day-gain-date": "2024-01-24",
      "realized-today": "0.0",
      "realized-today-effect": "None",
      "realized-today-date": "2024-01-24",
      "created-at": "2024-01-24T16:25:21.957+00:00",
      "updated-at": "2024-01-24T16:25:22.982+00:00"
  },
  {
      "account-number": "5WY24398",
      "symbol": "SPY   240301C00496000",
      "instrument-type": "Equity Option",
      "underlying-symbol": "SPY",
      "quantity": 1,
      "quantity-direction": "Short",
      "close-price": "3.04",
      "average-open-price": "2.765",
      "average-yearly-market-close-price": "2.765",
      "average-daily-market-close-price": "3.04",
      "multiplier": 100,
      "cost-effect": "Debit",
      "is-suppressed": false,
      "is-frozen": false,
      "restricted-quantity": 0,
      "expires-at": "2024-03-01T21:15:00.000+00:00",
      "realized-day-gain": "0.0",
      "realized-day-gain-effect": "None",
      "realized-day-gain-date": "2024-01-23",
      "realized-today": "0.0",
      "realized-today-effect": "None",
      "realized-today-date": "2024-01-23",
      "created-at": "2024-01-22T20:15:01.462+00:00",
      "updated-at": "2024-01-23T16:39:15.871+00:00"
  },
  {
      "account-number": "5WY24398",
      "symbol": "SPY   240301C00500000",
      "instrument-type": "Equity Option",
      "underlying-symbol": "SPY",
      "quantity": 1,
      "quantity-direction": "Long",
      "close-price": "1.95",
      "average-open-price": "1.78",
      "average-yearly-market-close-price": "1.78",
      "average-daily-market-close-price": "1.95",
      "multiplier": 100,
      "cost-effect": "Credit",
      "is-suppressed": false,
      "is-frozen": false,
      "restricted-quantity": 0,
      "expires-at": "2024-03-01T21:15:00.000+00:00",
      "realized-day-gain": "0.0",
      "realized-day-gain-effect": "None",
      "realized-day-gain-date": "2024-01-23",
      "realized-today": "0.0",
      "realized-today-effect": "None",
      "realized-today-date": "2024-01-23",
      "created-at": "2024-01-22T20:15:01.533+00:00",
      "updated-at": "2024-01-23T16:39:15.931+00:00"
  },
  {
      "account-number": "5WY24398",
      "symbol": "MSFT  240301C00420000",
      "instrument-type": "Equity Option",
      "underlying-symbol": "MSFT",
      "quantity": 1,
      "quantity-direction": "Short",
      "close-price": "5.12",
      "average-open-price": "4.73",
      "average-yearly-market-close-price": "4.73",
      "average-daily-market-close-price": "5.12",
      "multiplier": 100,
      "cost-effect": "Debit",
      "is-suppressed": false,
      "is-frozen": false,
      "restricted-quantity": 0,
      "expires-at": "2024-03-01T21:00:00.000+00:00",
      "realized-day-gain": "0.0",
      "realized-day-gain-effect": "None",
      "realized-day-gain-date": "2024-01-22",
      "realized-today": "0.0",
      "realized-today-effect": "None",
      "realized-today-date": "2024-01-22",
      "created-at": "2024-01-22T20:38:32.539+00:00",
      "updated-at": "2024-01-22T20:38:33.559+00:00"
  },
  {
      "account-number": "5WY24398",
      "symbol": "MSFT  240301C00425000",
      "instrument-type": "Equity Option",
      "underlying-symbol": "MSFT",
      "quantity": 1,
      "quantity-direction": "Long",
      "close-price": "3.92",
      "average-open-price": "3.67",
      "average-yearly-market-close-price": "3.67",
      "average-daily-market-close-price": "3.92",
      "multiplier": 100,
      "cost-effect": "Credit",
      "is-suppressed": false,
      "is-frozen": false,
      "restricted-quantity": 0,
      "expires-at": "2024-03-01T21:00:00.000+00:00",
      "realized-day-gain": "0.0",
      "realized-day-gain-effect": "None",
      "realized-day-gain-date": "2024-01-22",
      "realized-today": "0.0",
      "realized-today-effect": "None",
      "realized-today-date": "2024-01-22",
      "created-at": "2024-01-22T20:38:32.601+00:00",
      "updated-at": "2024-01-22T20:38:33.619+00:00"
  },
  {
    "account-number": "5WY24398",
    "symbol": "MSFT  240301C00420000",
    "instrument-type": "Equity Option",
    "underlying-symbol": "MSFT",
    "quantity": 3,
    "quantity-direction": "Short",
    "close-price": "5.12",
    "average-open-price": "4.03",
    "average-yearly-market-close-price": "4.73",
    "average-daily-market-close-price": "5.12",
    "multiplier": 100,
    "cost-effect": "Debit",
    "is-suppressed": false,
    "is-frozen": false,
    "restricted-quantity": 0,
    "expires-at": "2024-03-01T21:00:00.000+00:00",
    "realized-day-gain": "0.0",
    "realized-day-gain-effect": "None",
    "realized-day-gain-date": "2024-01-22",
    "realized-today": "0.0",
    "realized-today-effect": "None",
    "realized-today-date": "2024-01-22",
    "created-at": "2024-01-22T22:38:32.539+00:00",
    "updated-at": "2024-01-22T20:38:33.559+00:00"
  },
  {
    "account-number": "5WY24398",
    "symbol": "MSFT  240301C00425000",
    "instrument-type": "Equity Option",
    "underlying-symbol": "MSFT",
    "quantity": 3,
    "quantity-direction": "Long",
    "close-price": "3.92",
    "average-open-price": "2.80",
    "average-yearly-market-close-price": "3.67",
    "average-daily-market-close-price": "3.92",
    "multiplier": 100,
    "cost-effect": "Credit",
    "is-suppressed": false,
    "is-frozen": false,
    "restricted-quantity": 0,
    "expires-at": "2024-03-01T21:00:00.000+00:00",
    "realized-day-gain": "0.0",
    "realized-day-gain-effect": "None",
    "realized-day-gain-date": "2024-01-22",
    "realized-today": "0.0",
    "realized-today-effect": "None",
    "realized-today-date": "2024-01-22",
    "created-at": "2024-01-22T22:38:32.601+00:00",
    "updated-at": "2024-01-22T20:38:33.619+00:00"
  },
  {
      "account-number": "5WY24398",
      "symbol": "SPY   240301C00505000",
      "instrument-type": "Equity Option",
      "underlying-symbol": "SPY",
      "quantity": 1,
      "quantity-direction": "Short",
      "close-price": "1.06",
      "average-open-price": "1.06",
      "average-yearly-market-close-price": "1.06",
      "average-daily-market-close-price": "1.06",
      "multiplier": 100,
      "cost-effect": "Debit",
      "is-suppressed": false,
      "is-frozen": false,
      "restricted-quantity": 0,
      "expires-at": "2024-03-01T21:15:00.000+00:00",
      "realized-day-gain": "0.0",
      "realized-day-gain-effect": "None",
      "realized-day-gain-date": "2024-01-23",
      "realized-today": "0.0",
      "realized-today-effect": "None",
      "realized-today-date": "2024-01-23",
      "created-at": "2024-01-23T20:27:16.019+00:00",
      "updated-at": "2024-01-23T20:27:17.041+00:00"
  },
  {
      "account-number": "5WY24398",
      "symbol": "SPY   240301C00499000",
      "instrument-type": "Equity Option",
      "underlying-symbol": "SPY",
      "quantity": 1,
      "quantity-direction": "Long",
      "close-price": "2.19",
      "average-open-price": "2.16",
      "average-yearly-market-close-price": "2.16",
      "average-daily-market-close-price": "2.19",
      "multiplier": 100,
      "cost-effect": "Credit",
      "is-suppressed": false,
      "is-frozen": false,
      "restricted-quantity": 0,
      "expires-at": "2024-03-01T21:15:00.000+00:00",
      "realized-day-gain": "0.0",
      "realized-day-gain-effect": "None",
      "realized-day-gain-date": "2024-01-23",
      "realized-today": "0.0",
      "realized-today-effect": "None",
      "realized-today-date": "2024-01-23",
      "created-at": "2024-01-23T20:27:15.963+00:00",
      "updated-at": "2024-01-23T20:27:16.981+00:00"
  }
]
