import { BaseSyntheticEvent, FC, useState } from 'react'
import { format, getUnixTime, parseISO } from 'date-fns'
import { cloneDeep, filter, isEmpty, isObject, map, max, toNumber, toString } from 'lodash'
import { HiOutlineCheckCircle } from 'react-icons/hi2'
import { getExpDates, getOiData } from '../../services/api/market-data-options-oi'
import { getStockQuote } from '../../services/api/market-data-stocks'
import { OiDataByDate } from '../../types'
import OiGraph from './OiGraph'
import uuid from 'react-uuid'
import { toLocalDecimals } from '../../utilities/general'
import { handleError } from '../../utilities/error-handling'
import toast from 'react-hot-toast'
import { getFromFirebase, setToFirebase } from '../../services/api/firebase'

interface DataValidation {
  isValid: boolean,
  dataByDate?: OiDataByDate,
  errMsg?: string
}

const OI_EXP_DATES_PATH = 'hxData/oi/expiration-dates'
const OI_DATA_PATH = 'hxData/oi/data'
const STOCK_QUOTES_PATH = 'hxData/stocks/quotes'

// const MAX_DTE_DAYS              = 35
const MAX_DTE_DAYS              = 2
const UPDATE_STOCK_QUOTE_MIN    = 10
const UPDATE_EXP_DATES_HOURS    = 24
const UPDATE_OPTION_CHAIN_HOURS = 1

const MAX_DTE                     = MAX_DTE_DAYS * 24 * 60 * 60
const UPDATE_STOCK_QUOTE_TIMEOUT  = UPDATE_STOCK_QUOTE_MIN * 60
const UPDATE_EXP_DATES_TIMEOUT    = UPDATE_EXP_DATES_HOURS * 60 * 60
const UPDATE_OPTION_CHAIN_TIMEOUT = UPDATE_OPTION_CHAIN_HOURS * 60 * 60


const Oi: FC = () => {
  const [ticker, setTicker] = useState('')
  const [tickerQuote, setTickerQuote] = useState(-1)
  const [updateFormatted, setUpdateFormatted] = useState('')
  const [isError, setIsError] = useState('')
  const [maxOiValue, setMaxOiValue] = useState(-1)
  const [numExpDates, setNumExpDates] = useState(-1)
  const [dataValidation, setDataValidation] = useState<DataValidation>( { isValid: false } )

  const onChangeTicker = (e: BaseSyntheticEvent) => {
    const newTicker = toString(e.target.value.trim().toUpperCase())
    setTicker(newTicker)
  }

  const onKeyPressTicker = (e: BaseSyntheticEvent | any) => {
    if (e.key === 'Enter') processOiData()
  }

  const processOiData = async () => {
    const toastId = toast.loading('Processing request...')

    setDataValidation({ isValid: false, errMsg: undefined })
    setIsError('')

    const nowDate = new Date()
    const nowDateFormatted = format(nowDate, 'MMM d, HHmm')
    setUpdateFormatted(nowDateFormatted)
    const nowUnix = getUnixTime(nowDate)

    const stockQuotesPath = `${STOCK_QUOTES_PATH}/${ticker}`
    const oiDataPath = `${OI_DATA_PATH}/${ticker}`
    const oiExpDatesPath = `${OI_EXP_DATES_PATH}/${ticker}`

    var currentMaxOiValue = 0
    var stockMid = -1
    var expirations: string[] = []
    const updatedStockQuoteTime = nowUnix - UPDATE_STOCK_QUOTE_TIMEOUT
    const updatedExpDatesTime = nowUnix - UPDATE_EXP_DATES_TIMEOUT
    const updatedOiDataTime = nowUnix - UPDATE_OPTION_CHAIN_TIMEOUT
    const maxDteUnix = nowUnix + MAX_DTE

    try {
      // Get info from Fb
      const [fbStockQuote, fbExpDates, fbOiDataByDate] = await Promise.all([
        getFromFirebase({ refPath: stockQuotesPath, isRootPath: true }),
        getFromFirebase({ refPath: oiExpDatesPath, isRootPath: true }),
        getFromFirebase({ refPath: oiDataPath, isRootPath: true })
      ])


      // Process stock quote
      if (fbStockQuote !== null && fbStockQuote.updated[0] < updatedStockQuoteTime) {
        stockMid = fbStockQuote.mid[0] || fbStockQuote.last[0]
      } else {
        const stockQuote = await getStockQuote(ticker)
        stockMid = stockQuote.mid[0] || stockQuote.last[0]
        if (stockMid > 0) {
          setToFirebase({ refPath: stockQuotesPath, value: stockQuote, isRootPath: true })
        }
      }
      setTickerQuote( toNumber(stockMid) )

      // Process exp dates
      var expDatesData: { s: string, updated: number, expirations: [] }
      if (fbExpDates !== null && fbExpDates.updated > updatedExpDatesTime) {
        expDatesData = fbExpDates
      } else {
        const expDates = await getExpDates(ticker)
        expDatesData = cloneDeep(expDates)
      }
      if (expDatesData.expirations.length > 0) {
        expirations = filter(
          expDatesData.expirations,
          (expDate) => {
            const expDateUnix = getUnixTime(parseISO(expDate))
            // return expDateUnix <= maxDteUnix && expDateUnix >= nowUnix
            return expDateUnix <= maxDteUnix && expDateUnix >= (nowUnix - (24 * 60 * 60)) // Adjust nowUnix to grab 0 dte chains also.
          }
        )
        setToFirebase( { refPath: oiExpDatesPath, value: expDatesData, isRootPath: true } )
      }
      setNumExpDates( expirations.length )

      // Process oi data
      var finalDataByDate: OiDataByDate = {}
      if (fbOiDataByDate !== null && fbOiDataByDate.updated > updatedOiDataTime) {
        finalDataByDate = fbOiDataByDate
        setMaxOiValue( toNumber(finalDataByDate.maxOiValue) )
      } else {
        for (const i in expirations) {
          const expDate = expirations[i]

          if (!ticker || stockMid < 1 || !expDate) {
            handleError({ msg: `Cannot get oi data. Error: ticker: ${ticker} or stockMid: ${stockMid} or expDate: ${expDate}` })
          } else {
            const oiMarketData = await getOiData(ticker, stockMid, expDate)

            if (oiMarketData.isValid) {
              finalDataByDate[expDate] = {
                updated: nowUnix,
                date: expDate,
                data: oiMarketData.value
              }

              const newMaxOiValue = toNumber( max(oiMarketData.value.openInterest) )
              if (newMaxOiValue > currentMaxOiValue) {
                currentMaxOiValue = newMaxOiValue
                finalDataByDate.maxOiValue = newMaxOiValue
                setMaxOiValue( newMaxOiValue )
              }
            } else {
              setDataValidation( { isValid: false, errMsg: dataValidation.errMsg } )
            }
          }
        }
        if (!isEmpty(finalDataByDate)) {
          finalDataByDate.updated = nowUnix
          setToFirebase( { refPath: oiDataPath, value: finalDataByDate, isRootPath: true } )
        }
      }
      setDataValidation( { isValid: true, dataByDate: finalDataByDate, errMsg: '' } )

    } catch (e) {
      handleError({ msg: 'Failed to process IO data: stock quote.', e })
      setIsError('Failed to process IO data: stock quote.')

    } finally {
      toast.dismiss(toastId)
    }
  }

  return (
    <div className='flex flex-col items-center mb-8'>

      <div className='flex flex-row items-center w-full max-content-width'>
        <input
          id='editOiTicker'
          type='text'
          className='input-base ml-10'
          placeholder='Ticker'
          value={ticker}
          onChange={(e: BaseSyntheticEvent) => onChangeTicker(e)}
          onKeyDown={(e: BaseSyntheticEvent) => onKeyPressTicker(e)}
          autoFocus
        />
        {
          ticker &&
          <HiOutlineCheckCircle
            className={'action-icons check-circle-sz ml-2 text-success'}
            onClick={() => processOiData()}
          />
        }
        {
          ticker.length > 0 && isError &&
          <div className='text-danger'>
            {isError}
          </div>
        }
        {
          tickerQuote > 0 &&
          <div className='flex flex-row'>
            <div className='pl-3'>
              {toLocalDecimals(tickerQuote, 2)}
            </div>
            <div className='pl-3'>
              {updateFormatted}
            </div>
          </div>
        }
      </div>

      <div className='flex flex-row w-full max-content-width mt-6'>
        {
          dataValidation.isValid &&
          map(dataValidation.dataByDate, (dataByDate: OiDataByDate) => (
            ( isObject(dataByDate) && maxOiValue > 0 &&
              <div key={uuid()}>
                <OiGraph dataByDate={dataByDate} maxOiValue={maxOiValue} numExpDates={numExpDates} />
              </div>
            )
          ))
        }
      </div>

    </div>
  )
}

export default Oi
